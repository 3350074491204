import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as IconClose } from "../../assets/images/close-icon.svg"
import { NavLink } from "react-router-dom";

export const Activation = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.users.currentUser);
    const [localData, setLocalData] = useState(null);
    const [canSave, setCanSave] = useState(false);
    
    // const setDefaults = () => {
    //     setLocalData(currentUser);
    // }

    useEffect(()=>{
        setLocalData({
            ...currentUser,
            password: ""
        });
    }, [currentUser]);

    return(
        <div className="settings-sidebar">
            <div className="settings-sidebar-header">
                Activation
                <NavLink to="/dashboard" className="close-btn">
                    <IconClose />
                </NavLink>
            </div>
            <div className="settings-form-group">
                <div className="settings-form-group-header bt-0">Delete Profile</div>
                <div className="settings-form-input-wrpr">
                    <div className="input-wrapper mb-16">
                        {/* <label className="input-label">First Name</label> */}
                        <div className="relative">
                            <input type="text" className="input" value={ localData?.username } />
                            <div className="input-success-check">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.96484 12.55L4.90479 9.49005L3.89502 10.5099L6.94482 13.56L7.96484 14.58L8.98486 13.56L16.105 6.44L15.085 5.41998L7.96484 12.55Z" fill="#00E0D1"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="settings-form-group">
                <div className="settings-form-group-header">I wish to deactivate my account</div>
                <div className="settings-form-input-wrpr">
                    <ul className="filter_checkboxes_list">
                        {
                            [
                                "Hard to use",
                                "This isn't the information I’m looking for",
                                "I no longer need this information",
                                "Other (Please Specify)",
                            ].map((item, index)=>(
                                <li key={index}>
                                    <label>
                                        <input
                                            type="radio" name="radio-1" id=""
                                            // value={item.id} 
                                            // checked={localAppliedFilters.includes(item.id)}
                                            // onChange={(e) => {
                                            //     changeSelectedFilter(item.id);
                                            // }}
                                        />
                                        <span className="checkbox_circle middle"></span>
                                        { item }
                                    </label>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div className="settings-form-input-wrpr">
                    <div className="input-wrapper mb-16">
                        <textarea name="" id="" cols="30" rows="10" className="input" placeholder="Please tell us why"></textarea>
                    </div>
                </div>
            </div>

            <div className="settings-sidebar-btns-group">
                <a href="#" className="btn green-btn">Save Changes</a>
                <a href="#" className="btn grey-btn">Discard</a>
            </div>
        </div>
    )
}
import React from 'react'

const ButtonComponent = ({ text, onClick, disabled, className, type }) => {
    return (
        <button
            className={`btn btn-${type ? type : "secondary"} ${className ? className : ""}`}
            disabled={disabled || false}
            onClick={onClick}>
            {text}
        </button>
    )
}

export default ButtonComponent
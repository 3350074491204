import { useState, useEffect } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {ReactComponent as StoreSmIcon} from "../../../assets/images/store-sm-icon.svg";
import {ReactComponent as CanSmIcon} from "../../../assets/images/can-sm-icon.svg";

import {SidebarFilters} from "./SidebarFilters";
import SearchInput from "../../UI/SearchInput";
import { Pagination } from "../../UI/Pagination";

import {
    applyFilters,
    setStoreList,
    _setCurrentStore,
    setMapStores,
    changeProductsList,
    setListLoader,
    openModalAction,
    toggleMobileContentOpen,
    addActiveProduct,
    removeActiveProduct,
} from "../../../redux/dashboard/dashboard-slice";

import { useNavigate } from 'react-router';

import API from "../../../api";
import { useDebounce } from "../../../helpers/useDebounce";

import Loader from "../../UI/Loader";
import { useRef } from "react";

export const Sidebar = ({ 
        currentStore, setCurrentStore, 
        product, setProduct, 
        activeProducts, setActiveProducts, 
        setCanToggleMobileContentOpen, fullscreen = false,
        storeServerPagination, setStoreServerPagination,
    }) => {

        console.log("activeProducts", activeProducts)

    let { storeId, id } = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const appliedFilters = useSelector((state) => state.dashboard.appliedFilters);
    

    const productsList = useSelector((state) => state.dashboard.productsList);
    const [localProductsList, setLocalProductsList] = useState([]);

    const listLoader = useSelector((state) => state.dashboard.listLoader);
    const allAppliedFilters = [
        ...appliedFilters.categories.map(item => {
            return {
                name: "categories",
                filter: item,
            }
        }),
        ...appliedFilters.cities.map(item => {
            return {
                name: "cities",
                filter: item,
            }
        }),
        ...appliedFilters.provinces.map(item => {
            return {
                name: "provinces",
                filter: item,
            }
        })
    ];
    let productScrollingPage = 1;
    let storeScrollingPage = 1;
    const [infiniteScrollLoading, setInfiniteScrollLoading] = useState(false);

    const productsBottomRef = useRef(null);
    const storesBottomRef = useRef(null);

    const mapStores = useSelector((state) => state.dashboard.mapStores);
    const [filtersSidebarActive, setFiltersSidebarActive] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [mainFilter, setMainFilter] = useState('All');
    console.log('mainFilter:', mainFilter);
    const [sidebarOpen, toggleSidebar] = useState(true);
    const mobileWidth = window.innerWidth < 992;
    const [updatedTimes, setUpdatedTimes] = useState(0);
    const isUpdated = useDebounce(updatedTimes, 1000);
    const [storesList, setStoresList] = useState([]);
    const [searchFilterCount, setSearchFilterCount] = useState(1);
    const accessLogin = localStorage.getItem("accessLogin") ? JSON.parse(localStorage.getItem("accessLogin")) : null;
    const [storesCurrentPage, setStoresCurrentPage] = useState(1);
    const [storesPaginationLoader, setStoresPaginationLoader] = useState(false);
    const [storesPaginationAllow, setStoresPaginationAllow] = useState(true);

    const [productsCurrentPage, setProductsCurrentPage] = useState(1);
    const [productsPerPage, setProductsPerPage] = useState(10);
    const [productPaginationLoader, setProductPaginationLoader] = useState(false);
    const [productsPaginationAllow, setProductsPaginationAllow] = useState(true);
    const [searchBasicStoresParams, setSearchBasicStoresParams] = useState({
        keyword: '',
        count: null,
        page: 1,
    })
    const [searchBasicProductsParams, setSearchBasicProductsParams] = useState({
        keyword: '',
        count: null,
        page: 1,
    })

    const setListLoaderChange = (value) => { dispatch(setListLoader(value)); }
    const setProductsList = (value) => {
        setLocalProductsList(value);
        dispatch(changeProductsList(value));
    };
    
    useEffect(() => {
        setProductsList(localProductsList)
    }, [localProductsList])

    const dashboardStartLink = () => {
        return fullscreen ? '/dashboard/fullscreen' : '/dashboard';
    }
    console.log("activeProduct", activeProducts)
    const selectProduct = (productId, product) => {
        console.log("products", product)
        setActiveProducts((prevState) => {
            if (!prevState.includes(productId)) {
                return [...prevState, productId];
            } else {
                return prevState.filter(item => item !== productId);
            }
        });    
        if (!activeProducts.includes(productId)) {
            dispatch(addActiveProduct(productId));
        } else {
            dispatch(removeActiveProduct(productId));
        }
        navigate(`${dashboardStartLink()}/products/${activeProducts[activeProducts.length - 1 ]}`);
        setProduct(product);

    }

    const clearFilters = () => {
        dispatch(applyFilters({
            categories: [],
            cities: [],
            provinces: [],
        }));
    }

    const actionChangeFilter = (item) => {
        setMainFilter(item);
        if(item === 'All'){
            navigate(`${dashboardStartLink()}`);
        }
    }
    const onStoreClick = (id)=>{
        dispatch(_setCurrentStore(id));
        dispatch(toggleMobileContentOpen(true));
    }

    const searchInputValue = (value) => {
        setSearchString(value);
        setUpdatedTimes((prevState) => prevState + 1);
    }

    const search = async(forcedValue = null, callbackMethod = false, forceSearch = false) => {
        setListLoaderChange(true);
        setStoresPaginationAllow(true);
        setProductsPaginationAllow(true);

        let localSearchString = searchString.length ? searchString : '';

        if(new URL(window.location.href).searchParams.get('searchString')){
            localSearchString = new URL(window.location.href).searchParams.get('searchString');
            if(!searchString.length){
                searchInputValue(new URL(window.location.href).searchParams.get('searchString'));
            }
        }

        if(new URL(window.location.href).searchParams.get('search') && (!!new URL(window.location.href).searchParams.get('forceSearch') || !!new URL(window.location.href).searchParams.get('fromSearch'))){
            localSearchString = new URL(window.location.href).searchParams.get('search');
        }
    
        let storesSearchResult = [];

        let setStoresSearchResultFunc = (res = []) => {
            if(res.length){
                if(location.pathname.includes('/stores') && !res.filter(item => item.producerID === storeId).length){
                    console.log('setFirstItem:', res.filter(item => item.producerID === storeId));
                    // navigate(`${dashboardStartLink()}/stores/${res[0].producerID}`);
                }

                if(res.length < 10){
                    setStoresPaginationAllow(false);
                }

                // setStoresList(res);
                dispatch(setStoreList(res));
                dispatch(setMapStores(res));
    
                if(new URL(window.location.href).searchParams.get('mobileSelectStoreItem')){
                    navigate(`${dashboardStartLink()}/stores/${new URL(window.location.href).searchParams.get('mobileSelectStoreItem')}`);
                }
            } else {
                // setStoresList([]);

                setStoresList([]);
                dispatch(setStoreList([]));
                dispatch(setMapStores([]));

                setStoresPaginationAllow(false);
            }
        }

        if((window?.location?.search.length && new URL(window.location.href).searchParams.get('searchByCity'))){
            setStoreServerPagination(false);
            storesSearchResult = await API.searchBasicStoresByCity(new URL(window.location.href).searchParams.get('searchByCity'));
            if (accessLogin && accessLogin.access) {
            setStoresSearchResultFunc(storesSearchResult);
            }
        } else {
            setStoreServerPagination(true);

            if((!!new URL(window.location.href).searchParams.get('forceSearch') === true || forceSearch) || (searchBasicStoresParams.keyword !== (localSearchString.length ? localSearchString : forcedValue || localSearchString) ||
                searchBasicStoresParams.count !== 10 ||
                searchBasicStoresParams.page !== (storeId ? storesCurrentPage : 1))
            ){
                setSearchBasicStoresParams({
                    keyword: (localSearchString.length ? localSearchString : forcedValue || localSearchString),
                    count: 10,
                    page: storeId ? storesCurrentPage : 1
                });

                setStoresCurrentPage(storeId ? storesCurrentPage : 1);

                const searchParams = new URLSearchParams(location.search);
                if (accessLogin && accessLogin.access) {
                searchParams.get('all') !== 'true' ? storesSearchResult = await API.searchBasicStores(localSearchString.length ? localSearchString : forcedValue || localSearchString, 10 , storeId ? storesCurrentPage : 1)
                    : storesSearchResult = await API.getAllLocationsBasic()
                    setStoresSearchResultFunc(storesSearchResult);
                    }
                } else {
                    storesSearchResult = storesList;
                    if (accessLogin && accessLogin.access) {
                    setStoresSearchResultFunc(storesList);
                    }
            }
        }

        let productsSearchResultFunc = (res) =>{
            if(res.length){
                setProductsList(res);
                if(res.length < 10){
                    setProductsPaginationAllow(false);
                }
    
                setTimeout(() => {
                    if(new URL(window.location.href).searchParams.get('selectFirst')){
                        navigate(`${dashboardStartLink()}/products/${res[0].productID}`);
                    }
    
                    if(callbackMethod){
                        setFirstItem('Products');
                    }
    
                    if(new URL(window.location.href).searchParams.get('mobileSelectProductItem')){
                        navigate(`${dashboardStartLink()}/products/${new URL(window.location.href).searchParams.get('mobileSelectProductItem')}`);
                    }
                }, 1000);
            } else {
                setProductsPaginationAllow(false);
                setProductsList([]);
            }
            // else {
            //     setProductsList([]);
            // }
        }

        if(
            ((!!new URL(window.location.href).searchParams.get('fromSearch') || forceSearch) || (!location.pathname.includes('/stores/') && !new URL(window.location.href).searchParams.get('search') && !new URL(window.location.href).searchParams.get('feetBounds')) &&
            (searchBasicProductsParams.keyword !== localSearchString.length ? localSearchString : forcedValue ? forcedValue : localSearchString ||
            searchBasicProductsParams.count !== 10 ||
            searchBasicProductsParams.page !== 1))
        ){
            setSearchBasicProductsParams({
                keyword: localSearchString.length ? localSearchString : forcedValue ? forcedValue : localSearchString,
                count: 10,
                page: 1,
            });

            let productsSearchResult = [];
            if (accessLogin && accessLogin.access) {
            productsSearchResult = await API.searchBasicProducts(localSearchString.length ? localSearchString : forcedValue ? forcedValue : localSearchString, 1000, 1);
            if (!productsSearchResult.length) {
                productsSearchResult = await API.searchBasicProducts('', 1000, 1);
                productsSearchResult = productsSearchResult.filter(product => 
                    product.brand.toLowerCase().includes(searchString.toLowerCase())
                );             
            }    
        }
            setProductsCurrentPage(1);

            productsSearchResultFunc(productsSearchResult);
        } else {
            productsSearchResultFunc(localProductsList);
        }

        setListLoaderChange(false);
    }

    const searchReset = () => {
        let plusUrl = '';
        if(mainFilter === "Stores"){
            plusUrl += '/stores'
        } else if(mainFilter === "Products"){
            plusUrl += '/products'
        }

        navigate(`${dashboardStartLink()}${plusUrl}`);
    }

    const getProductsFromPage = async(pageNumber) => {
        setProductsPaginationAllow(true);
        setProductPaginationLoader(true);
        let localSearchString = searchString.length ? searchString : '';

        const setProductsListFunc = (res) =>{
            if(res.length){
                setProductsList(res);
    
                setTimeout(() => {
                    if(new URL(window.location.href).searchParams.get('selectFirst')){
                        navigate(`${dashboardStartLink()}/products/${res[0].productID}`);
                    }
                }, 1000);
            } else {
                setProductsList([]);
            }
        }

        if(((!location.pathname.includes('/stores/') && !new URL(window.location.href).searchParams.get('search') && !new URL(window.location.href).searchParams.get('feetBounds')) && 
            ((searchBasicProductsParams.keyword !== localSearchString) ||
            (searchBasicProductsParams.count !== productsPerPage) ||
            (searchBasicProductsParams.page !== pageNumber)))
        ){
            setSearchBasicProductsParams({
                keyword: localSearchString,
                count: productsPerPage,
                page: pageNumber,
            });

            let productsSearchResult = await API.searchBasicProducts(localSearchString, productsPerPage, pageNumber);

            if(productsSearchResult.length < 10){
                setProductsPaginationAllow(false);
            }
            setProductsListFunc(productsSearchResult);
        } else {
            setProductsListFunc(localProductsList);
        }

        setProductPaginationLoader(false);
    }

    const getStoresFromPage = async(pageNumber) => {
        setStoresPaginationAllow(true);
        setStoresPaginationLoader(true);
        let localSearchString = searchString.length ? searchString : '';
        let storesSearchResult = [];

        if((searchBasicStoresParams.keyword !== localSearchString ||
            searchBasicStoresParams.page !== pageNumber)
        ){
            setSearchBasicStoresParams({
                keyword: localSearchString,
                count: 10,
                page: pageNumber
            });

            storesSearchResult = await API.searchBasicStores(localSearchString, 10, pageNumber);

            if(storesSearchResult.length < 10){
                setStoresPaginationAllow(false);
            }
        } else {
            storesSearchResult = storesList;
        }

        if(storesSearchResult.length){
            setStoresList(storesSearchResult);

            setTimeout(() => {
                if(new URL(window.location.href).searchParams.get('selectFirst')){
                    navigate(`${dashboardStartLink()}/stores/${storesSearchResult[0].producerID}`);
                }
            }, 1000);
        } else {
            setStoresList([]);
            // setStoresPaginationAllow(false);
        }

        setStoresPaginationLoader(false);
    }

    const setProductStoresPaginationPage = (type, productOrStores = 'product') => {
        if(productOrStores === 'product'){
            if(type === 'prev' && productsCurrentPage !== 1){
                setProductsCurrentPage(productsCurrentPage-1);
            }
            if(type === 'next'){
                setProductsCurrentPage(productsCurrentPage+1);
            }
        } else {
            if(type === 'prev' && storesCurrentPage !== 1){
                setStoresCurrentPage(storesCurrentPage-1);
            }
            if(type === 'next' && storesPaginationAllow){
                setStoresCurrentPage(storesCurrentPage+1);
            }
        }

    }

    const setStoresFromProducts = async() => {
        let localData = [];

        for (let index = 0; index < productsList.length; index++) {
            if(activeProducts.includes(productsList[index].productID) || !localData.length){
                setStoreServerPagination(false);
                let resultSearchStoresByProductName = await API.searchStoresByProductName(productsList[index]?.name, 1, 3000);

                for (let index2 = 0; index2 < resultSearchStoresByProductName.length; index2++) {
                    if(resultSearchStoresByProductName[index2]?.producer?.name && (localData.length === 0 || localData.filter(item => item.name === resultSearchStoresByProductName[index2].producer.name).length === 0)){
                        localData.push({
                            ...resultSearchStoresByProductName[index2].producer,
                            status: resultSearchStoresByProductName[index2].instockstatus,
                            brand: "",
                            weight: "",
                            price: +resultSearchStoresByProductName[index2].price,
                        })
                    }
                }

            }

        }

        dispatch(setMapStores(localData));
        return localData;
    }

    const getAllLocations = async() => {
        setListLoaderChange(true);
        setStoreServerPagination(false);
        let result = await API.getAllLocationsBasic();
        dispatch(setMapStores(result));
        if(storeId){
            if(result.find(item => item?.producerID === storeId || item?.p_id === storeId)){
                setCurrentStore(result.find(item => item?.producerID === storeId || item?.p_id === storeId));
                dispatch(_setCurrentStore(storeId));
            } else {
                setCurrentStore(result[0]);
                dispatch(_setCurrentStore(result[0]?.producerID || result[0]?.p_id));
                navigate(`${dashboardStartLink()}/stores/${result[0]?.producerID || result[0]?.p_id}?filter=all&search-count=${searchFilterCount}?feetBounds=true`);
            }
        }
        setListLoaderChange(false);
    }

    const setFirstItem = async (type = null, filterAll = true, feetBounds = false) => {
        if(type === "All" && accessLogin && accessLogin.access){
            search(null, false, true);
        }

        if(type === "Stores"){   
            if(storesList.length){
                setCurrentStore(storesList[0]);
                navigate(`${dashboardStartLink()}/stores/${storesList[0].producerID}`+ (filterAll ? `?filter=all&search-count=${searchFilterCount}` : feetBounds ? '?feetBounds=true' : ''));
                if(filterAll) {
                    getAllLocations();
                }
            }
        } else if(type === "Products"){
            if(productsList.length){
                setProduct(productsList[0]);
                navigate(`${dashboardStartLink()}/products/${productsList[0].productID}`+ (filterAll ? `?filter=all&search-count=${searchFilterCount}` : feetBounds ? '?feetBounds=true' : ''));

                if(productsList[0].productID && !activeProducts.includes(productsList[0].productID)){
                    setActiveProducts([productsList[0].productID]);
                }
            }

            if((!location.pathname.includes('/stores/') && !new URL(window.location.href).searchParams.get('search') && !new URL(window.location.href).searchParams.get('feetBounds')) && filterAll){
                setActiveProducts([]);

                setProductsPerPage(100);

                setProductsCurrentPage(1);

                let allBasicProducts =  await API.searchBasicProducts(searchString, 100, 1);
                setProductsList(allBasicProducts);
                setProduct(allBasicProducts[0]);
                navigate(`${dashboardStartLink()}/products/${allBasicProducts[0].productID}?filter=all&search-count=${searchFilterCount}`);
                if(!activeProducts.includes(allBasicProducts[0].productID)){
                    setActiveProducts([allBasicProducts[0].productID]);
                }
            }
        }
    }

    const clearSelectedProducts = () => {
        setActiveProducts([id]);
    }

    const storesProductsLength = () => {
        return {
            stores: (
                window?.location?.search.length && new URL(window.location.href).searchParams.get('filter') === "all" ? 
                [...appliedFilters.cities, ...appliedFilters.provinces].length ? 
                mapStores.filter(item => appliedFilters.cities.includes(item.city) || appliedFilters.provinces.includes(item.province)) :
                mapStores : [...appliedFilters.cities, ...appliedFilters.provinces].length ? 
                storesList.filter(item => appliedFilters.cities.includes(item.city) || appliedFilters.provinces.includes(item.province)) : storesList
            ).length,
            products: productsList.length,
        }
    }

    const openModal = () => {
        dispatch(openModalAction("UnlockPremiumFeatures"));
    }

    let storesCompiled = () => {
        return (
            window?.location?.search.length && new URL(window.location.href).searchParams.get('filter') === "all" ? 
            [...appliedFilters.cities, ...appliedFilters.provinces].length ? 
            mapStores.filter(item => appliedFilters.cities.includes(item.city) || appliedFilters.provinces.includes(item.province)) :
            mapStores : [...appliedFilters.cities, ...appliedFilters.provinces].length ? 
            storesList.filter(item => appliedFilters.cities.includes(item.city) || appliedFilters.provinces.includes(item.province)) : storesList
        )
    }

    let productsCompiled = () => {
        console.log("productsList" , productsList)
        console.log("localProductsList" , localProductsList)
        return (
            // product filter based on appliedfilters.categories
            window?.location?.search.length && new URL(window.location.href).searchParams.get('filter') === "all" ?
            [...appliedFilters.categories].length ? 
            productsList.filter(item => appliedFilters.categories.includes(item.category)) : productsList 
            : [...appliedFilters.categories].length ?
            localProductsList.filter(item => appliedFilters.categories.includes(item.category)) : localProductsList 
        )
    }

    const loadMoreProducts = async () => {
        productScrollingPage++;
        // let newPage = productsCurrentPage + 1;
        setInfiniteScrollLoading(true);
        // setProductsCurrentPage((prev) => prev + 1); 
        try {
            if (productScrollingPage !== 1 ) {
                const res = await API.searchBasicProducts(searchString , 30 , productScrollingPage);
                if (res) {
                    setLocalProductsList((prev) => [...prev, ...res]);
                    // setProductsList((prev) => [...prev, ...res]);

                }
            }

        } catch (error) {
            console.log(error);
        }
        setInfiniteScrollLoading(false);
    };
    const loadMoreStores = async () => {
        storeScrollingPage++;
        setInfiniteScrollLoading(true);

        try {
            if (storeScrollingPage !== 1 ) {
                const res = await API.searchBasicStores(searchString , 30 , storeScrollingPage);
                if (res ) {
                    setStoresList((prev) => [...prev, ...res]);

                }
            }

        } catch (error) {
            console.log(error);
        }
        setInfiniteScrollLoading(false);
    };

    useEffect(() => {
        if(mainFilter.includes("Products") && localProductsList.length === 0){
            // API.searchBasicProducts().then((res) => {
            //     console.log(res , "products");
            //     setLocalProductsList(res);
            // });\
            getProductsFromPage(productsCurrentPage);
        }
   },[mainFilter])

    useEffect(() => {
        if(location.pathname.includes('/stores/')){
            setMainFilter('Stores');
        }

        if(location.pathname.includes('/products/')){
            setMainFilter('Products')
        }

        document.querySelector('body').classList.remove('dashboard-sidebar-close');
        toggleSidebar(true);

        setSearchFilterCount((prev)=>{
            return prev+1
        });
    }, [location])

    useEffect(() => {
        if(location.pathname.includes('/stores/')){
            if(window?.location?.search.length && new URL(window.location.href).searchParams.get('filter') === "all"){
                if(!new URL(window.location.href).searchParams.get('dontReloadData') || !storesCompiled().length){
                    getAllLocations();
                }

                if(storesList.find(item => String(item?.producerID ? item.producerID : item.p_id) === String(storeId))){
                    setCurrentStore(storesList.find(item => String(item?.producerID ? item.producerID : item.p_id) === String(storeId)))
                }
            } else {
                if(storesList.find(item => String(item.producerID) === String(storeId))){
                    setCurrentStore(storesList.find(item => String(item?.producerID ? item.producerID : item.p_id) === String(storeId)))
                }

                dispatch(setMapStores(storesList));
            }
        }

        if(location.pathname.includes('/products/')){
            if((!window?.location?.search.length || new URL(window.location.href).searchParams.get('filter') !== "all")){
                const _curProduct = productsList.find(item=> String(item.productID) === String(id));

                if(productsList.find(item=> String(item.productID) === String(id)) && (!product?.productID || (product.productID !== _curProduct.productID))){
                    setProduct((prev)=>_curProduct);
                    if (accessLogin && accessLogin.access) {
                    setStoresFromProducts();
                    }
                }
            }
        }
    }, [location, storesList, productsList]);

    useEffect(() => {
        if(!currentStore && mapStores.length && window?.location?.search.length && new URL(window.location.href).searchParams.get('filter') === "all" && storeId){
            setCurrentStore(mapStores.find(item => String(item?.productID || item?.p_id) == String(storeId)));
        }
    }, [mapStores]);

    useEffect(() => {
        if(!sidebarOpen){
            document.querySelector('body').classList.add('dashboard-sidebar-close');
        } else {
            document.querySelector('body').classList.remove('dashboard-sidebar-close');
        }
    }, [sidebarOpen]);

    useEffect(() => {
        if(window?.location?.search.length && new URL(window.location.href).searchParams.get('search') && accessLogin && accessLogin.access){
            if(new URL(window.location.href).searchParams.get("searchString")){
                setSearchString(new URL(window.location.href).searchParams.get('searchString'));
                search(new URL(window.location.href).searchParams.get('searchString'));
            } else {
                setSearchString(new URL(window.location.href).searchParams.get('search'));
                search(new URL(window.location.href).searchParams.get('search'));
            }
        } else if(!(window?.location?.search.length && new URL(window.location.href).searchParams.get('filter') === "all") || new URL(window.location.href).searchParams.get('searchByCity')){
            if (accessLogin && accessLogin.access) {
            search();
            }
        }
    }, [
        new URL(window.location.href).searchParams.get('search'),
        new URL(window.location.href).searchParams.get('searchString'),
        new URL(window.location.href).searchParams.get('searchByCity')
    ]);

    useEffect(() => {
        if(isUpdated && accessLogin && accessLogin.access){
            if(
                new URL(window.location.href).searchParams.get('searchString') &&
                new URL(window.location.href).searchParams.get('searchString') !== searchString
            ){
                navigate(`${dashboardStartLink()}`);
            }
            if (accessLogin && accessLogin.access) {
            search(null, false, true);
            }
        }
    }, [isUpdated]);

    useEffect(() => {
        if(activeProducts.length === 0){
            if (accessLogin && accessLogin.access) {
            setStoresFromProducts();
            }
        }

        if(activeProducts.length === 1){
            if(location.pathname.includes('/products/') && !(!!new URL(window.location.href).searchParams.get('fromSearch'))){
                navigate(`${dashboardStartLink()}/products/${activeProducts[0]}${new URL(window.location.href).searchParams.get('filter') === 'all' ? `?filter=all&search-count=${searchFilterCount}` : ''}`);
            }
        }
    }, [activeProducts]);

    useEffect(()=>{
        getProductsFromPage(productsCurrentPage);
    },[productsCurrentPage])

    useEffect(()=>{
        getStoresFromPage(storesCurrentPage);
    },[storesCurrentPage])

    
    useEffect(() => {
        if (isUpdated && accessLogin && accessLogin.access) {
            search();
            setStoresCurrentPage(1);
            setProductsCurrentPage(1);
        }
    }, [isUpdated, searchString]);


    useEffect(() => {
        const productsObserver = new IntersectionObserver(
          (entries) => {
            if (entries[0].isIntersecting) {
              loadMoreProducts();
            }
          },
          {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
          }
        );
    
        const storesObserver = new IntersectionObserver(
          (entries) => {
            if (entries[0].isIntersecting) {
              loadMoreStores();
            }
          },
          {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
          }
        );
    
        if (productsBottomRef.current) {
          productsObserver.observe(productsBottomRef.current);
        }
    
        if (storesBottomRef.current) {
          storesObserver.observe(storesBottomRef.current);
        }
    
        return () => {
          if (productsBottomRef.current) {
            productsObserver.unobserve(productsBottomRef.current);
          }
          if (storesBottomRef.current) {
            storesObserver.unobserve(storesBottomRef.current);
          }
        };
      }, [productsBottomRef, storesBottomRef , mainFilter]);

    return(
        <>
            <aside className={`dashboard-sidebar ${sidebarOpen === false ? 'hidden' : ''}`}>
                { !mobileWidth ?
                <a href="/#" className="dashboard-header-toggle-sidebar" onClick={(e) => {
                    e.preventDefault();
                    toggleSidebar(!sidebarOpen);
                }}>
                    <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
                        transform: !sidebarOpen ? 'rotate(180deg)' : 'none'
                    }}>
                        <path d="M11.5613 0.465517C10.9764 -0.155172 10.0406 -0.155172 9.45575 0.465517L2.10561 8.26552L0 10.5L2.10561 12.7345L9.45575 20.5345C10.0406 21.1552 10.9764 21.1552 11.5613 20.5345C12.1462 19.9138 12.1462 18.9207 11.5613 18.3L4.21122 10.5L11.5613 2.7C12.1462 2.07931 12.1462 1.08621 11.5613 0.465517Z" fill="currentColor"/>
                    </svg>
                </a> : null }
                <div className="dashboard-sidebar-overflow">
                    { filtersSidebarActive ?
                        <SidebarFilters
                            mainFilter={ mainFilter }
                            setFiltersSidebarActive={ setFiltersSidebarActive }
                        /> : 
                        <>
                            <div className="sm-wrpr-box dashboard-sidebar-header">
                                <SearchInput value={searchString} onChange={searchInputValue} reset={searchReset} suggestion productsList={productsList}/>
                                
                                {
                                    mainFilter !== "All" ? 
                                    <a
                                        href="/neobi/#" className="d-flex align-items-center justify-content-c relative"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setFiltersSidebarActive(true);
                                        }}
                                    >
                                        <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.0032 24V16.4986H14.2691V19.2656H27.5999V21.265H14.2691V24H12.0032ZM0.407946 21.265V19.2656H9.73716V21.265H0.407946ZM7.47112 15.7309V12.9957H0.407946V10.9963H7.47112V8.19726H9.73716V15.7309H7.47112ZM12.0032 12.9957V10.9963H27.5999V12.9957H12.0032ZM18.2707 7.5016V0H20.5367V2.73522H27.5999V4.73455H20.5367V7.5016H18.2707ZM0.399902 4.73455V2.73522H15.9968V4.73455H0.399902Z" fill="currentColor"/>
                                        </svg>
                                        { allAppliedFilters.length ? 
                                            <div className="amount_circle_result small-circle">{ allAppliedFilters.length }</div> : null
                                        }
                                    </a> : null
                                }
                            </div>
                            <div className="sm-wrpr-box">
                                <ul className="filter-btns-group w-33">
                                {['All', 'Stores', 'Products'].map((item, index) => (
                                    <li key={index}>
                                        <a
                                            href="/neobi/#" className={mainFilter === item ? 'active' : ''}
                                            onClick={(e)=>{
                                                e.preventDefault();
                                                actionChangeFilter(item);
                                                // setFirstItem(item, false, true)
                                            }}
                                        >
                                            {item}
                                        </a>
                                    </li>
                                ))}
                                </ul>
                            </div>
                            <div className="sm-wrpr-box d-flex align-items-center justify-content-sb py-0 min-h-50">
                                { searchString.length ? <h4 className="filters-result-text flex-1">Results: <span>“{ searchString }”</span></h4> : <div></div> }
                                { mainFilter === "All" ?
                                    <a
                                        href="/neobi/#" className="d-flex align-items-center justify-content-c relative"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setFiltersSidebarActive(true);
                                        }}
                                    >
                                        <svg style={{ width: "calc(50px * var(--scale))", height: "calc(51px * var(--scale))" }} viewBox="0 0 50 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M23.0032 37.5V29.9986H25.2691V32.7656H38.5999V34.765H25.2691V37.5H23.0032ZM11.4079 34.765V32.7656H20.7372V34.765H11.4079ZM18.4711 29.2309V26.4957H11.4079V24.4963H18.4711V21.6973H20.7372V29.2309H18.4711ZM23.0032 26.4957V24.4963H38.5999V26.4957H23.0032ZM29.2707 21.0016V13.5H31.5367V16.2352H38.5999V18.2346H31.5367V21.0016H29.2707ZM11.3999 18.2346V16.2352H26.9968V18.2346H11.3999Z" fill="#BFD0D8"/>
                                        </svg>
                                        { allAppliedFilters.length ? 
                                            <div className="amount_circle_result small-circle">{ allAppliedFilters.length }</div> : null
                                        }
                                    </a>
                                    : null 
                                }
                            </div>
                            {
                                ['All', 'Stores'].includes(mainFilter) ? 
                                <div className="search-sidebar-result-box">
                                    <div className="search-sidebar-result-header">
                                        <h4 className="d-flex">Stores Results { mainFilter === "Stores" && searchString.length ? 
                                            <>
                                                <div className="amount_circle_result ml-10">{ storesProductsLength().stores }</div>
                                                <a 
                                                    className="d-flex align-items-center lock-item ml-10" href="/#" style={{ color: "#BFD0D8" }}
                                                    onClick={(e)=>{
                                                        e.preventDefault();
                                                        openModal();
                                                    }}
                                                >
                                                    <svg 
                                                        width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" 
                                                        style={{
                                                            width: "calc(20px * var(--scale))",
                                                            height: "calc(20px * var(--scale))",
                                                            marginRight: "calc(4px * var(--scale))",
                                                        }}
                                                    ><path d="M5.11678 17.8792C4.80011 17.8792 4.54181 17.7707 4.31681 17.5541C4.09181 17.3374 3.9917 17.0708 3.9917 16.7541V8.61243C3.9917 8.29576 4.10015 8.03746 4.31681 7.81246C4.53348 7.58746 4.80011 7.48735 5.11678 7.48735H6.43351V5.68762C6.43351 4.69596 6.78351 3.85406 7.47518 3.16239C8.16685 2.47073 9.00833 2.12073 10 2.12073C10.9917 2.12073 11.8336 2.47073 12.5252 3.16239C13.2169 3.85406 13.5669 4.69596 13.5669 5.68762V7.48735H14.8836C15.2003 7.48735 15.4586 7.59579 15.6836 7.81246C15.9086 8.02913 16.0083 8.29576 16.0083 8.61243V16.7541C16.0083 17.0708 15.9003 17.3291 15.6836 17.5541C15.4669 17.7791 15.2003 17.8792 14.8836 17.8792H5.12533H5.11678ZM5.11678 16.7541H14.8751V8.61243H5.11678V16.7541ZM10 14.1292C10.4 14.1292 10.7417 13.9875 11.025 13.7125C11.3083 13.4375 11.4502 13.1042 11.4502 12.7209C11.4502 12.3376 11.3083 12.0043 11.025 11.6959C10.7417 11.3876 10.4 11.2373 10 11.2373C9.6 11.2373 9.25835 11.3876 8.97502 11.6959C8.69168 12.0043 8.55021 12.3459 8.55021 12.7209C8.55021 13.0959 8.69168 13.4375 8.97502 13.7125C9.25835 13.9875 9.6 14.1292 10 14.1292ZM7.55859 7.48735H12.4418V5.68762C12.4418 5.01262 12.2003 4.43736 11.7253 3.96236C11.2503 3.48736 10.675 3.24581 10 3.24581C9.325 3.24581 8.75015 3.48736 8.27515 3.96236C7.80015 4.43736 7.55859 5.01262 7.55859 5.68762V7.48735Z" fill="currentColor"></path></svg> Clean data
                                                </a>
                                            </> : '' }
                                        </h4>
                                        <a href="/#" onClick={(e)=>{
                                            e.preventDefault();
                                            setFirstItem('Stores');
                                        }}>See All</a>
                                    </div>
                                    { listLoader || storesPaginationLoader ? 
                                        <div className="d-flex align-items-center justify-content-c min-h-50">
                                            <Loader />
                                        </div>
                                    : 
                                    <ul className="search-sidebar-result-list">
                                        { 
                                            // (
                                            //     storesCompiled().length < 100 ? 
                                            //     storesCompiled() : 
                                            //     storesCompiled().slice(+storesCurrentPage === 1 ? 0 : +storesCurrentPage*100-100, +storesCurrentPage*100)
                                            // )
                                            storesCompiled().map((item, index) => (
                                            <li key={index}>
                                                <NavLink onClick={(e)=>{
                                                    setCanToggleMobileContentOpen(true);
                                                    onStoreClick(item.producerID || item.p_id);
                                                    setCurrentStore(item);
                                                }} to={
                                                        window?.location?.search.length && new URL(window.location.href).searchParams.get('filter') === "all" ?
                                                        `${dashboardStartLink()}/stores/${item.producerID || item.p_id}?filter=all&search-count=${searchFilterCount}&feetBounds=false&dontReloadData=true` :
                                                        new URL(window.location.href).searchParams.get('searchByCity') ? `${dashboardStartLink()}/stores/${item.producerID || item.p_id}?searchByCity=${new URL(window.location.href).searchParams.get('searchByCity')}&feetBounds=false` :
                                                        `${dashboardStartLink()}/stores/${item.producerID || item.p_id}`
                                                    }
                                                >
                                                    <div className="icon-wrpr">
                                                        <StoreSmIcon />
                                                    </div>
                                                    <div className="text-content">
                                                        <h4>{ item.name }</h4>
                                                        <div className="location">{ item.province }, { item.city }</div>
                                                    </div>
                                                </NavLink>
                                            </li>

                                        ))}
                                        <li> { infiniteScrollLoading && <Loader/>}</li>
                                    </ul> }
                                        <div ref={storesBottomRef}>
                                        </div> 
                                </div> : null
                            }
                            {
                                ['All', 'Products'].includes(mainFilter) ? 
                                <div className="search-sidebar-result-box">
                                    <div className="search-sidebar-result-header">
                                        <h4 className="d-flex" style={{
                                            flexWrap: "wrap",
                                            flex: "1",
                                            gap: "10px"
                                        }}>Product Results { mainFilter === "Products" && searchString.length ?
                                            <>
                                                <div className="amount_circle_result">{ storesProductsLength().products }</div>
                                                <a 
                                                    className="d-flex align-items-center lock-item" href="/#" style={{ color: "#BFD0D8" }}
                                                    onClick={(e)=>{
                                                        e.preventDefault();
                                                        openModal();
                                                    }}
                                                >
                                                    <svg 
                                                        width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" 
                                                        style={{
                                                            width: "calc(20px * var(--scale))",
                                                            height: "calc(20px * var(--scale))",
                                                            marginRight: "calc(4px * var(--scale))",
                                                        }}
                                                    ><path d="M5.11678 17.8792C4.80011 17.8792 4.54181 17.7707 4.31681 17.5541C4.09181 17.3374 3.9917 17.0708 3.9917 16.7541V8.61243C3.9917 8.29576 4.10015 8.03746 4.31681 7.81246C4.53348 7.58746 4.80011 7.48735 5.11678 7.48735H6.43351V5.68762C6.43351 4.69596 6.78351 3.85406 7.47518 3.16239C8.16685 2.47073 9.00833 2.12073 10 2.12073C10.9917 2.12073 11.8336 2.47073 12.5252 3.16239C13.2169 3.85406 13.5669 4.69596 13.5669 5.68762V7.48735H14.8836C15.2003 7.48735 15.4586 7.59579 15.6836 7.81246C15.9086 8.02913 16.0083 8.29576 16.0083 8.61243V16.7541C16.0083 17.0708 15.9003 17.3291 15.6836 17.5541C15.4669 17.7791 15.2003 17.8792 14.8836 17.8792H5.12533H5.11678ZM5.11678 16.7541H14.8751V8.61243H5.11678V16.7541ZM10 14.1292C10.4 14.1292 10.7417 13.9875 11.025 13.7125C11.3083 13.4375 11.4502 13.1042 11.4502 12.7209C11.4502 12.3376 11.3083 12.0043 11.025 11.6959C10.7417 11.3876 10.4 11.2373 10 11.2373C9.6 11.2373 9.25835 11.3876 8.97502 11.6959C8.69168 12.0043 8.55021 12.3459 8.55021 12.7209C8.55021 13.0959 8.69168 13.4375 8.97502 13.7125C9.25835 13.9875 9.6 14.1292 10 14.1292ZM7.55859 7.48735H12.4418V5.68762C12.4418 5.01262 12.2003 4.43736 11.7253 3.96236C11.2503 3.48736 10.675 3.24581 10 3.24581C9.325 3.24581 8.75015 3.48736 8.27515 3.96236C7.80015 4.43736 7.55859 5.01262 7.55859 5.68762V7.48735Z" fill="currentColor"></path></svg> Clean data
                                                </a>
                                            </>
                                            : '' }
                                        </h4>
                                        {
                                            location.pathname.includes('/products/') ?
                                            <div className="d-flex align-items-center ml-10">
                                                { allAppliedFilters.length ? <>
                                                    <a
                                                        href="/#" className="mr-10"
                                                        onClick={(e)=>{
                                                            e.preventDefault();
                                                            clearFilters();
                                                        }}
                                                    >Clear all</a>
                                                    {/*  mr-20 */}
                                                    <div className="amount_circle_result">{ allAppliedFilters.length }</div>
                                                </> :
                                                
                                                <>
                                                    { activeProducts.length > 1 ? 
                                                    <a
                                                        href="/#" className="mr-15"
                                                        style={{ color: "#ffffff" }}
                                                        onClick={(e)=>{
                                                            e.preventDefault();
                                                            clearSelectedProducts();
                                                        }}
                                                    >Clear all</a> : null }
                                                    <a href="/#" onClick={(e)=>{
                                                        e.preventDefault();
                                                        setFirstItem('Products');
                                                    }}>See All</a>
                                                </>
                                                }
                                            </div> :
                                            <>
                                                { activeProducts.length > 1 && location.pathname.includes('/products/') ? 
                                                <a
                                                    href="/#" className="mr-15"
                                                    style={{ marginLeft: 'auto', color: "#ffffff" }}
                                                    onClick={(e)=>{
                                                        e.preventDefault();
                                                        clearSelectedProducts();
                                                    }}
                                                >Clear all</a> : null }
                                                <a href="/#" onClick={(e)=>{
                                                    e.preventDefault();
                                                    setFirstItem('Products');
                                                }}>See All</a>
                                            </>
                                        }
                                    </div>
                                    { listLoader || productPaginationLoader ? 
                                        <div className="d-flex align-items-center justify-content-c min-h-50">
                                            <Loader />
                                        </div>
                                    : <ul className="search-sidebar-result-list">
                                        { productsCompiled().map((item, index) => (
                                            <li key={index}>
                                                { location.pathname.includes('/products/') && !mobileWidth ? 
                                                    <a
                                                        href="/#"
                                                        className={`${ activeProducts.includes(item.productID)  ? 'active' : ''}`}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setCanToggleMobileContentOpen(true);
                                                            selectProduct(item.productID, item);
                                                        }}
                                                    >
                                                        <div>
                                                            <div className="checkbox_circle small"></div>
                                                        </div>
                                                        <div className="text-content">
                                                            <h4>{ item.name }</h4>
                                                            <div className="location">{item.brand}</div>
                                                        </div>
                                                    </a> :
                                                    <NavLink to={`${dashboardStartLink()}/products/${item.productID}`} onClick={()=>{
                                                        setCanToggleMobileContentOpen(true);
                                                        setActiveProducts([]);
                                                    }}>
                                                        <div className="icon-wrpr">
                                                            <CanSmIcon />
                                                        </div>
                                                        <div className="text-content">
                                                            <h4>{ item.name }</h4>
                                                            <div className="location">{item.brand}</div>
                                                        </div>
                                                    </NavLink>
                                                }
                                            </li>
                                        ))}
                                        <li> { infiniteScrollLoading && <Loader/>}</li>
                                    </ul> }
                                    <div ref={productsBottomRef}>

                                       

                                    {/* <button onClick={() => {
                                        setProductsCurrentPage(productsCurrentPage + 1);
                                        getProductsFromPage(productsCurrentPage + 1);
                                    }}>
                                        Load more products
                                    </button> */}
                                    </div>
                                </div> : null
                            }
                        </>
                    }
                </div>
            </aside>
        </>
    )

}        
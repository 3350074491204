import { configureStore } from '@reduxjs/toolkit';
import dashboardReducer from './dashboard/dashboard-slice'
import usersReducer from './users/users-slice'
import mapSlice from './map/map-slice';

// store
export const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    users: usersReducer,
    map: mapSlice,
  },
});




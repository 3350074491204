import { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { ReactComponent as IconLocation } from "../../assets/images/info-list-icon-location.svg";
import { ReactComponent as IconPhone } from "../../assets/images/info-list-icon-phone.svg";
import { ReactComponent as IconMail } from "../../assets/images/info-list-icon-mail.svg";
import { ReactComponent as LockIcon } from "../../assets/images/lock-icon.svg";

import SearchInput from "../../components/UI/SearchInput";
import Table from "../../components/dashboard/Table";
import Loader from "../../components/UI/Loader";
import { Pagination } from "../../components/UI/Pagination";
import ToggleInput from "../../components/UI/ToggleInput";
import { Map } from "../../components/Map/Map";
import { formatPrice, formatDate } from "../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useOutletContext } from "react-router-dom";
import API from "../../api";
import { openModalAction, toggleInStock, } from "../../redux/dashboard/dashboard-slice";

export const Stores = ({ fullscreen = false }) => {
    const dispatch = useDispatch();
    const location = useLocation();


    const [searchString, setSearchString] = useState('');
    const mobileWidth = window.innerWidth < 992;
    const [defaultProducts, setDefaultProducts] = useState([]);
    const [products, setProducts] = useState([]);
    const storesThead = [
        {
            title: 'Category',
            sort: true,
            sortable_field: 'category',
            sort_active: false,
            hide_on_mob: true,
        },
        
        {
            title: 'Product',
            sort: true,
            sortable_field: 'product',
            sort_active: false,
        },
        {
            title: 'Brand',
            sort: true,
            sortable_field: 'brand',
            sort_active: false,
            hide_on_mob: true,
        },
        {
            title: 'Status',
            sort: true,
            sortable_field: 'status',
            sort_active: false,
            hide_on_mob: true,
        },
        {
            title: 'Weight',
            sort: true,
            sortable_field: 'weight',
            sort_active: false,
            hide_on_mob: true,
        },
        {
            title: 'Price',
            sort: true,
            sortable_field: 'price',
            sort_active: false,
        },
        {
            title: 'Inventory',
            // hide_on_mob: true,
        },
        {
            title: '',
            export_csv: true,
            hide_on_mob: true,
        }
    ];
    const [headerBoxClose, setHeaderBoxClose] = useState(false);
    const [categoriesList, setCategoriesList] = useState([]);
    const [categoriesFilters, setCategoriesFilters] = useState(['All']);
    const [productsLoader, setProductsLoader] = useState(false);
    const { currentStore, setCurrentStore } = useOutletContext();

    console.log("currentStore", currentStore);
    const inStockFilter = useSelector((state) => state.dashboard.inStockFilter);
    const appliedFilters = useSelector((state) => state.dashboard.appliedFilters);


    const dashboardStartLink = () => {
        return fullscreen ? '/dashboard/fullscreen' : '/dashboard';
    }
    const [mapKey, setMapKey] = useState(1);

    let { storeId } = useParams();

    const [productsCurrentPage, setProductsCurrentPage] = useState(1);

    const setSort = (sortOptions) => {
        if(sortOptions.type === 'asc'){
            setProducts([...defaultProducts].sort((a,b) => (a[sortOptions.field] > b[sortOptions.field]) ? 1 : ((b[sortOptions.field] > a[sortOptions.field]) ? -1 : 0)));
        } else if(sortOptions.type === 'desc'){
            setProducts([...defaultProducts].sort((a,b) => (a[sortOptions.field] > b[sortOptions.field]) ? -1 : ((b[sortOptions.field] > a[sortOptions.field]) ? 1 : 0)));
        } else {
            setProducts(defaultProducts);
        }
    }

    const changeCategoriesFilter = (category) => {
        if(category === 'All'){
            setCategoriesFilters([category])
        } else {
            if(categoriesFilters.includes(category)){
                setCategoriesFilters((prevState) => {
                    return [...prevState].filter(item => item !== category && item !== 'All');
                })
            } else {
                setCategoriesFilters((prevState) => {
                    return [...prevState, category].filter(item => item !== 'All');
                })
            }
        }
    }

    const getStoreProducts = async() => {
        setProductsLoader(true);
        setProductsCurrentPage(1);
        let result = await API.singleStoreSelectGetProducts(location.pathname.split("/")[3], 1, 100000);
        
        let resultFormat = result.map((item, index) => {
            return {
                id: index,
                category: item.category,
                product: item.name,
                brand: item.brand,
                status: item.instockstatus,
                weight: item.weight,
                price: +item.price,
                inventory: true,
                productID: item.productID,
            }
        });

        console.log("resul" , resultFormat);

        setProducts(resultFormat);
        setDefaultProducts(resultFormat);

        setProductsLoader(false);
    }

    const openModal = () => {
        dispatch(openModalAction("UnlockPremiumFeatures"));
    }

    const setInStock = (value) =>{
        dispatch(toggleInStock(value));
    }

    useEffect(() => {
        getStoreProducts();
    }, [location]);

    useEffect(() => {
        if(!categoriesFilters.length){
            setCategoriesFilters(['All'])
        }
    }, [categoriesFilters]);

    useEffect(() => {
        setCategoriesList(Array.from(new Set(defaultProducts.map(item => item.category))));
    }, [defaultProducts]);

    useEffect(() => {
        setMapKey((prevState)=>{
            return prevState+1;
        })
    }, [fullscreen]);

    let filteredProducts = () => {
        console.log("producyts", products)
        return products.filter(itemFilt =>  
            itemFilt.id &&
            (
                itemFilt.product.toLowerCase().includes(searchString.toLowerCase()) ||
                itemFilt.category.toLowerCase().includes(searchString.toLowerCase())
            ) && (
                // appliedFilters.categories.length ? appliedFilters.categories.includes(itemFilt.category) : 
                (categoriesFilters[0] === 'All' || categoriesFilters.includes(itemFilt.category))
            ) && (
                inStockFilter ? itemFilt.status === "In Stock" : true
            )
        )
    }
    return(
        <div className={`dashboard-content flex-col align-items-start ${fullscreen ? 'map-fullscreen' : ''}`}>
            { currentStore ? 
            <div className="tph-cards-wrpr" style={{ display: 'grid', gridTemplateColumns: !fullscreen ?  '1fr 1fr' : "unset" }}>
                { !fullscreen ?
                <div className={`table-page-header-box table-page-header-box-wrpr ${headerBoxClose ? 'header-box-close' : ''} w-50`} style={{ width: '100%', gridArea: !fullscreen ? '1 / 1 / 2 / 2' : "auto" }}>
                    <div className="relative">
                        <h1>{ currentStore.name } <span>{ currentStore.province }, { currentStore.city }</span></h1>
                        <a
                            href="/neobi/#" className="table-page-header-box-toggler"
                            onClick={(e)=>{
                                e.preventDefault();
                                setHeaderBoxClose(!headerBoxClose);
                            }}
                        >
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.23002 12.4873C6.45002 13.2673 6.45002 14.5377 7.23002 15.3277L17.1602 25.2574L20 28.0977L22.8399 25.2574L32.7701 15.3277C33.5501 14.5477 33.5501 13.2773 32.7701 12.4873C31.9901 11.7073 30.7202 11.7073 29.9302 12.4873L20 22.4175L10.0699 12.4873C9.28986 11.7073 8.02002 11.7073 7.23002 12.4873Z" fill="#506D8B"/>
                            </svg>
                        </a>
                    </div>
                    <div className="table-page-header-box map-container w-50 p-0 show-in-mob">
                        <div className="map-box">
                            <Map currentStore={currentStore} setCurrentStore={setCurrentStore} fullscreen={fullscreen} loaderProcess={productsLoader} />
                        </div>
                    </div>
                    <ul className="info-list max-w-260 mb-20">
                        <li>
                            <div className="icon-wrpr">
                                <IconLocation />
                            </div>
                            { currentStore.address }{ currentStore.postalCode ? ', '+currentStore.postalCode : '' }{ currentStore.province ? ', '+currentStore.province : '' }{ currentStore.city ? ', '+currentStore.city : '' }
                        </li>
                        {currentStore.phone ? <li>
                            <div className="icon-wrpr">
                                <IconPhone />
                            </div>
                            <a href={`tel:${ currentStore.phone }`} className="color-grey">{ currentStore.phone }</a>
                        </li> : null }
                        {currentStore.email ? <li>
                            <div className="icon-wrpr">
                                <IconMail />
                            </div>
                            <a href={`mailto:${currentStore.email}`} className="green-link">{currentStore.email}</a>
                        </li> : null }
                    </ul>
                    <ul className="filter-btns-group items-min-w-140">
                        { currentStore.url ? <li><a href={currentStore.url} target="_blank">Website</a></li> : null }
                        { currentStore.phone ? <li><a href={`tel:${ currentStore.phone }`}>Call</a></li> : null }
                        { currentStore.directions ? <li><a href={`${ currentStore.directions }`} target="_blank">Directions</a></li> : null }
                    </ul>
                </div> : null }
                <div className="table-page-header-box map-container w-50 p-0 hide_on_mob" style={{ width: '100%' , gridArea: !fullscreen? '1 / 2 / 3 / 3' : "unset" }}>
                    <div className="map-box" key={mapKey}>
                        {/* <div className="unlock-map">    
                            <LockIcon />
                            Unlock  Map
                        </div>
                        <img src={require("../../assets/images/map-calgary.jpg")} alt="" /> */}
                        { currentStore?.name !== null ? 
                        <>
                            <NavLink
                                to={location.pathname.includes('/fullscreen') || mobileWidth ? (storeId ? `/dashboard/stores/${storeId}` : '/dashboard') : (storeId ? `/dashboard/fullscreen/stores/${storeId}` : '/dashboard/fullscreen')}
                                className="fullscreen-change-btn" style={{ right: fullscreen ? '34px' : '20px' }}
                            >
                                <svg height="20" style={{ enableBackground: "new 0 0 32 32" }} version="1.1" viewBox="0 0 32 32" width="20" xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <polygon points="29.414,26.586 22.828,20 20,22.828 26.586,29.414 24,32 32,32 32,24" fill="currentColor"/>
                                        <polygon points="2.586,5.414 9.172,12 12,9.172 5.414,2.586 8,0 0,0 0,8" fill="currentColor"/>
                                        <polygon points="26.586,2.586 20,9.172 22.828,12 29.414,5.414 32,8 32,0 24,0" fill="currentColor"/>
                                        <polygon points="12,22.828 9.172,20 2.586,26.586 0,24 0,32 8,32 5.414,29.414" fill="currentColor"/>
                                    </g>
                                </svg>
                            </NavLink>
                            <Map 
                                currentStore={currentStore}
                                points={[currentStore]}
                                setCurrentStore={setCurrentStore}
                                fullscreen={fullscreen}
                                loaderProcess={productsLoader}
                            />
                        </> : null }
                    </div>
                </div>
                { !fullscreen ? <>
                    <div className="show-in-mob w-100" style={{
                        padding: "10px 11px",
                        background: "#081921",
                        marginTop: "2px",
                        marginBottom: "3px"
                    }}>
                        <SearchInput
                            value={searchString}
                            onChange={setSearchString}
                            placeholder="Search products at this location"
                        />
                    </div>
                    <div className="table-page-header-box w-25 card-product-info-wrpr" style={{ width: '50%', gridColumn: '1 / 2', gridArea: '2 / 1 / 3 / 2' }}>
                        <div className="card-product-info">
                            <div className="card-product-info-header">
                                { productsLoader ? <Loader /> :
                                <h3>{ Array.from(new Set(products.map(item => item.category))).length }</h3> }
                            </div>
                            <div className="card-product-info-body">
                                <h4>Products Categories</h4>
                                <p>The number of all (or selected) product categories at this locations</p>
                            </div>
                        </div>
                    </div>
                    <div className="table-page-header-box w-25 card-product-info-wrpr" style={{ width: '50%', gridArea: '2 / 1 / 3 / 2', placeSelf: 'end' }}>
                        <div className="card-product-info">
                            <div className="card-product-info-header">
                                { productsLoader ? <Loader /> :
                                <h3>{products.length}</h3> }
                            </div>
                            <div className="card-product-info-body">
                                <h4>Products Available</h4>
                                <p>The number of all (or selected) unique products at this locations</p>
                            </div>
                        </div>
                    </div> 

                </> : null }
            </div> : <Map  setCurrentStore={setCurrentStore} fullscreen={fullscreen} loaderProcess={productsLoader} /> }
            { !fullscreen ?
            <div className="table-page-tablebox">
                <div className="table-page-tablebox-header">
                    <h3 className="table-title">In Stock Products</h3>
                    
                    <div className="d-flex align-items-center justify-content-end flex-1 stores-page-dateinstock-wrpr">
                        { currentStore?.updated ? <div className="update-date">Last Update: <span>{ formatDate(currentStore.updated) }</span></div> : null }
                        <div className="vertical-margin-line"></div>
                        <ToggleInput
                            label="In Stock"
                            checked={inStockFilter}
                            onChange={setInStock}
                        />
                        <div className="vertical-margin-line"></div>
                        <SearchInput value={searchString} onChange={setSearchString} placeholder="Search products at this location" />
                    </div>
                </div>
                <ul className="filter-btns-group items-min-w-140 mb-20">
                    {
                        [
                            'All',
                            ...categoriesList
                        ].map((item, index) => <li key={index}>
                            <a
                                href="/neobi/#"
                                className={categoriesFilters.includes(item) ? 'active' : ''}
                                onClick={(e) => {
                                    e.preventDefault();
                                    changeCategoriesFilter(item);
                                }}
                            >{ item }</a>
                        </li>)
                    }
                </ul>

                <Table
                    thead={ storesThead }
                    setSort={ setSort }
                >
                    {
                        productsLoader ? 
                        <tr>
                            <td colSpan={mobileWidth ? '3' : '7'}>
                                <div className="d-flex align-items-center justify-content-c min-h-50">
                                    <Loader />
                                </div>
                            </td>
                        </tr> :
                        (
                            filteredProducts().length < 100 ? 
                            filteredProducts() : 
                            filteredProducts().slice(+productsCurrentPage === 1 ? 0 : +productsCurrentPage*100-100, +productsCurrentPage*100)
                        ).map((item, index) => <tr key={index}>
                            <td className="hide_on_mob">{ item.category }</td>
                            <td style={{ paddingLeft: mobileWidth ? '0px' : 'calc(36px * var(--scale))', color: "#00E0D1" }}>
                                <NavLink to={`${dashboardStartLink()}/products/${item.productID}?search=${item.product}&searchString=${item.product}&feetBounds=false&selectFirst=true`} className="text-underline">
                                    { item.product }
                                </NavLink>
                            </td>
                            <td className="hide_on_mob">{ item.brand }</td>
                            <td className="hide_on_mob">{ item.status }</td>
                            <td className="hide_on_mob">{ item.weight || '-' }</td>
                            <td>{ formatPrice(item.price) }</td>
                            <td className="pl-0" style={{ paddingRight: mobileWidth ? '0px' : 'calc(24px * var(--scale))' }}>
                                {
                                    // NavLink to="/"
                                    <a href="/#"
                                        className="d-flex align-items-center lock-item" style={{ justifyContent: mobileWidth ? 'flex-end' : 'initial' }}
                                        onClick={(e)=>{
                                            e.preventDefault();
                                            openModal();
                                        }}
                                    >
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
                                            width: "calc(20px * var(--scale))",
                                            height: "calc(20px * var(--scale))",
                                            marginRight: "calc(4px * var(--scale))",
                                        }}>
                                            <path d="M5.11678 17.8792C4.80011 17.8792 4.54181 17.7707 4.31681 17.5541C4.09181 17.3374 3.9917 17.0708 3.9917 16.7541V8.61243C3.9917 8.29576 4.10015 8.03746 4.31681 7.81246C4.53348 7.58746 4.80011 7.48735 5.11678 7.48735H6.43351V5.68762C6.43351 4.69596 6.78351 3.85406 7.47518 3.16239C8.16685 2.47073 9.00833 2.12073 10 2.12073C10.9917 2.12073 11.8336 2.47073 12.5252 3.16239C13.2169 3.85406 13.5669 4.69596 13.5669 5.68762V7.48735H14.8836C15.2003 7.48735 15.4586 7.59579 15.6836 7.81246C15.9086 8.02913 16.0083 8.29576 16.0083 8.61243V16.7541C16.0083 17.0708 15.9003 17.3291 15.6836 17.5541C15.4669 17.7791 15.2003 17.8792 14.8836 17.8792H5.12533H5.11678ZM5.11678 16.7541H14.8751V8.61243H5.11678V16.7541ZM10 14.1292C10.4 14.1292 10.7417 13.9875 11.025 13.7125C11.3083 13.4375 11.4502 13.1042 11.4502 12.7209C11.4502 12.3376 11.3083 12.0043 11.025 11.6959C10.7417 11.3876 10.4 11.2373 10 11.2373C9.6 11.2373 9.25835 11.3876 8.97502 11.6959C8.69168 12.0043 8.55021 12.3459 8.55021 12.7209C8.55021 13.0959 8.69168 13.4375 8.97502 13.7125C9.25835 13.9875 9.6 14.1292 10 14.1292ZM7.55859 7.48735H12.4418V5.68762C12.4418 5.01262 12.2003 4.43736 11.7253 3.96236C11.2503 3.48736 10.675 3.24581 10 3.24581C9.325 3.24581 8.75015 3.48736 8.27515 3.96236C7.80015 4.43736 7.55859 5.01262 7.55859 5.68762V7.48735Z" fill="#324457"/>
                                        </svg> {mobileWidth ? '' : 'Unlock'}
                                    </a>
                                }
                            </td>
                            <td className="hide_on_mob"></td>
                        </tr>)
                    }
                </Table>
                { filteredProducts().length && filteredProducts().length > 100 && !productsLoader ? 
                    <Pagination 
                        change={(value)=>{
                            setProductsCurrentPage(value);
                        }}
                        max={Math.ceil(filteredProducts().length/100)}
                    /> 
                : null }
            </div> : null }
        </div>
    )

}
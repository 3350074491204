import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { openModalAction } from "../../redux/dashboard/dashboard-slice";

const Table = ({ thead, children, setSort }) => {
    const dispatch = useDispatch();
    const [localThead, setLocalThead] = useState([]);

    const setSortField = (fieldName) => {
        setLocalThead((prevState) => {
            let copiedHead = [...prevState];
            let fieldItem = copiedHead.find(itemF => itemF.sortable_field === fieldName);
            if(fieldItem.sort_active === false){
                fieldItem.sort_active = 'asc';
            } else if(fieldItem.sort_active === 'asc'){
                fieldItem.sort_active = 'desc'
            } else {
                fieldItem.sort_active = false;
            }

            setSort({
                field: fieldName,
                type: fieldItem.sort_active
            });

            return copiedHead;
        })
    }

    const openModal = () => {
        dispatch(openModalAction("UnlockPremiumFeatures"));
    }

    useEffect(() => {
        setLocalThead(thead);
    }, []);

    return (
        <div className="table-wrpr">
            <table className="table">
                <thead>
                    <tr>
                        {
                            localThead.map((item, index) =>
                            <th key={index} className={item.hide_on_mob ? 'hide_on_mob' : ''}>
                                {
                                    item.sort ? <div className="th-inner-wrpr">
                                    <a href="/neobi/#" className={`table-sort-btn ${item.sort_active === 'asc' ? 'asc' : item.sort_active === 'desc' ? 'desc' : ''}`} onClick={(e) => {
                                        e.preventDefault();
                                        setSortField(item.sortable_field);
                                    }}>
                                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.290039 8.1748L4 13.9448L7.70996 8.1748H5.23999V1.29443C5.23999 0.614434 4.69 0.0546875 4 0.0546875C3.32 0.0546875 2.76001 0.604434 2.76001 1.29443V8.1748H0.290039Z" fill="currentColor"/>
                                        </svg>
                                    </a>
                                    { item.title }
                                </div> : null
                                // item.export_csv ?
                                //     <div style={{
                                //         textAlign:"right"
                                //     }}>
                                //         <a href="#/" className="btn green-btn export-to-csv-btn" onClick={(e)=>{
                                //             e.preventDefault();
                                //             openModal();
                                //         }}>
                                //             Export to CSV
                                //             <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                //                 <path d="M1 14.8901C0.72 14.8901 0.480039 14.7901 0.290039 14.6001C0.100039 14.4101 0 14.1701 0 13.8901V6.61011C0 6.33011 0.100039 6.09009 0.290039 5.90009C0.480039 5.71009 0.72 5.61011 1 5.61011H2.17004V4.00006C2.17004 3.12006 2.47998 2.36011 3.09998 1.74011C3.71998 1.12011 4.46999 0.810059 5.35999 0.810059C6.24999 0.810059 7 1.12011 7.62 1.74011C8.24 2.36011 8.55005 3.11006 8.55005 4.00006V5.61011H9.71997C9.99997 5.61011 10.2399 5.71009 10.4299 5.90009C10.6199 6.09009 10.72 6.33011 10.72 6.61011V13.8901C10.72 14.1701 10.6199 14.4101 10.4299 14.6001C10.2399 14.7901 9.99997 14.8901 9.71997 14.8901H1ZM1 13.8801H9.71997V6.6001H1V13.8801ZM5.35999 11.5301C5.71999 11.5301 6.02002 11.4101 6.27002 11.1601C6.52002 10.9101 6.65002 10.6201 6.65002 10.2701C6.65002 9.92008 6.52002 9.63011 6.27002 9.36011C6.02002 9.09011 5.70999 8.95007 5.35999 8.95007C5.00999 8.95007 4.69995 9.09011 4.44995 9.36011C4.19995 9.63011 4.06995 9.94008 4.06995 10.2701C4.06995 10.6001 4.19995 10.9101 4.44995 11.1601C4.69995 11.4101 5.00999 11.5301 5.35999 11.5301ZM3.17993 5.59009H7.54004V3.9801C7.54004 3.3801 7.33002 2.86006 6.90002 2.44006C6.47002 2.01006 5.95999 1.80011 5.35999 1.80011C4.75999 1.80011 4.23995 2.01006 3.81995 2.44006C3.38995 2.87006 3.17993 3.3801 3.17993 3.9801V5.59009Z" fill="#324457"/>
                                //             </svg>
                                //         </a>
                                //     </div> :
                                //     item.title
                                }
                            </th>)
                        }
                    </tr>
                </thead>
                <tbody>
                    { children }
                </tbody>
            </table>
        </div>
    )
}

export default Table;
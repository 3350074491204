import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AuthLayoutComponent from '../../components/StartPagesComponents/AuthLayoutComponent';
import InputComponent from '../../components/StartPagesComponents/InputComponent';
import SpacerСomponent from '../../components/StartPagesComponents/SpacerComponent';
import ButtonComponent from '../../components/StartPagesComponents/ButtonComponent';
import SelectBoxComponent from '../../components/StartPagesComponents/SelectBoxComponent';

import { ReactComponent as GoogleIco } from "../../assets/images/google-ico.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/arrow-right.svg";

import API from "../../api";

import { useDispatch } from "react-redux";
import { setCurrentUser } from "../../redux/users/users-slice";

import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useGoogleLogin } from '@react-oauth/google';

const Signup = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let [searchParams] = useSearchParams();

    const options = [
        { value: 'Cannabis Consumer', label: 'Cannabis Consumer' },
        { value: 'Retail Dispensary', label: 'Retail Dispensary' },
        { value: 'Licensed Producer', label: 'Licensed Producer' },
        { value: 'Data Lover', label: 'Data Lover' },
    ]

    const [signUpData, setSignUpData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        role: '',
    });
    const [registrationStep, setRegistrationStep] = useState(1);
    const [googleLogined, setGoogleLogined] = useState(false);

    const signUp = async() => {
        if(!signUpData.email.length){
            NotificationManager.error('"Email" is required field');
            return;
        }

        if(!signUpData.email.trim().match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/g)){
            NotificationManager.error('"Email" is not valid');
            return;
        }

        if(signUpData.password !== signUpData.confirmPassword){
            NotificationManager.error('"Password" is not equal "Confirm Password"');
            return;
        }

        if(!signUpData.role.length){
            NotificationManager.error('"I am a..." is required field');
            return;
        }

        let result = null;
        if(googleLogined){
            result = await API.registerUserSkipVerification(signUpData.firstName, signUpData.lastName, signUpData.email.trim(), signUpData.password, signUpData.role);
        } else {
            result = await API.signUp(signUpData.firstName, signUpData.lastName, signUpData.email.trim(), signUpData.password, signUpData.role);
        }

        if(result === "true"){
            localStorage.setItem("accessLogin", JSON.stringify({ access: result, email: signUpData.email }));

            let userData = await API.getUser(signUpData.email);
            dispatch(setCurrentUser(userData));
            setRegistrationStep(2);
            if(googleLogined){
                navigate("/dashboard", { replace: true });
            }
            return false;
        } if(!result?.isSuccessStatusCode && result?.reasonPhrase){
            NotificationManager.error(`Error: ${result.reasonPhrase}`);
            return;
        } else {
            NotificationManager.error(`Error: ${result}`);
            return;
        }
    }

    const googleLogin = useGoogleLogin({
        onSuccess: async(tokenResponse) => {
            let userInfoData = await fetch(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`, {
                method: 'GET',
                redirect: 'follow'
            }).then(response => response.json())
                .then(result => {
                    return result
                }).catch(error => console.log('error', error));
            
            let sighnedData = {};
            if(userInfoData.email){
                sighnedData.email = userInfoData.email;
            } else {
                return;
            }

            if(userInfoData.given_name){
                sighnedData.firstName = userInfoData.given_name;
            }
            if(userInfoData.family_name){
                sighnedData.lastName = userInfoData.family_name;
            }

            setSignUpData((prevState)=>{
                return { ...prevState, ...sighnedData }
            });

            setGoogleLogined(true);
        },
        onError: tokenResponse => console.log("onError:", tokenResponse),
    });

    useEffect(()=>{
        if(searchParams.get('googleLogined')){
            let sighnedData = {};
            if(searchParams.get('firstName')){
                sighnedData.firstName = searchParams.get('firstName');
            }
            if(searchParams.get('lastName')){
                sighnedData.lastName = searchParams.get('lastName');
            }
            if(searchParams.get('email')){
                sighnedData.email = searchParams.get('email');
            }

            setSignUpData((prevState)=>{
                return { ...prevState, ...sighnedData }
            });

            setGoogleLogined(true);
        }

    },[])

    return (
        <AuthLayoutComponent topLink={{text: "Sign In", to: "/sign-in"}}>
            <h2 className='heading mb-10'>Create your free account</h2>
            <p className='subheading'>to continue to Neobi Dashboard</p>
            <SpacerСomponent />
            { registrationStep === 1 && !googleLogined ?
            <>
                <InputComponent
                    label="First Name" type="text" className="mb-16"
                    value={ signUpData.firstName } onChange={(e) => {
                        setSignUpData((prevState)=>{
                            return { ...prevState, firstName: e.target.value }
                        })
                    }}
                />
                <InputComponent
                    label="Last Name" type="text" className="mb-16"
                    value={ signUpData.lastName } onChange={(e) => {
                        setSignUpData((prevState)=>{
                            return { ...prevState, lastName: e.target.value }
                        })
                    }}
                />
                <InputComponent
                    name="new-Email"
                    label="Email" type="text" className="mb-16"
                    value={ signUpData.email } onChange={(e) => {
                        setSignUpData((prevState)=>{
                            return { ...prevState, email: e.target.value }
                        })
                    }}
                />
                <InputComponent
                    name="new-password"
                    label="Password" type="password" className="mb-16"
                    value={ signUpData.password } onChange={(e) => {
                        setSignUpData((prevState)=>{
                            return { ...prevState, password: e.target.value }
                        })
                    }} 
                />
                <InputComponent
                    label="Confirm Password" type="password" className="mb-16"
                    value={ signUpData.confirmPassword } onChange={(e) => {
                        setSignUpData((prevState)=>{
                            return { ...prevState, confirmPassword: e.target.value }
                        })
                    }} 
                />
                <SelectBoxComponent options={options} label="I am a..." onChange={(e) => {
                        setSignUpData((prevState)=>{
                            return { ...prevState, role: e.value }
                        })
                    }}  />
                <SpacerСomponent name="or" />
                <button className='google-signin_btn mb-30' onClick={(e)=>{
                    e.preventDefault();
                    googleLogin();
                }}>
                    <span className='center-flex'><GoogleIco style={{width: '67%', height: '67%' }}/></span>
                    <span>Sign in with Google</span>
                    <span className='center-flex'><ArrowRight style={{width: '67%', height: '67%' }}/></span>
                </button>
                <p className='agreement-paragpaph mb-30'>
                    By continuing, you agree to<br />
                    Neobi’s <span><a href="https://neobistorage.blob.core.windows.net/pubdash/Terms%20of%20Use.pdf" target="_blank" className='link'>User Agreement</a></span> and <span><a href="https://neobistorage.blob.core.windows.net/pubdash/Terms%20of%20Use.pdf" target="_blank" className='link'>Privacy Policy</a></span>.
                </p>
                <ButtonComponent className="mb-30" text={"Continue"} onClick={signUp}/>
            </> : 
            !googleLogined ? <>
                <svg fill="#00E0D1" height="80" width="80" version="1.1" id="Layer_1" viewBox="0 0 64 64" enable-background="new 0 0 64 64">
                    <path id="Mail" d="M58.0034485,8H5.9965506c-3.3136795,0-5.9999995,2.6862001-5.9999995,6v36c0,3.3137016,2.6863203,6,5.9999995,6  h52.006897c3.3137016,0,6-2.6862984,6-6V14C64.0034485,10.6862001,61.3171501,8,58.0034485,8z M62.0034485,49.1108017  L43.084549,30.1919994l18.9188995-12.0555992V49.1108017z M5.9965506,10h52.006897c2.2056007,0,4,1.7943001,4,4v1.7664003  L34.4677505,33.3134003c-1.4902,0.9492989-3.3935013,0.9199982-4.8495998-0.0703011L1.9965508,14.4694996V14  C1.9965508,11.7943001,3.7910507,10,5.9965506,10z M1.9965508,16.8852005L21.182251,29.9251003L1.9965508,49.1108017V16.8852005z   M58.0034485,54H5.9965506c-1.6473999,0-3.0638998-1.0021019-3.6760998-2.4278984l20.5199013-20.5200024l5.6547985,3.843401  c1.0859013,0.7383003,2.3418007,1.1083984,3.5995998,1.1083984c1.1953011,0,2.3925018-0.3339996,3.4463005-1.0048981  l5.8423996-3.7230015l20.2961006,20.2961025C61.0673485,52.9978981,59.6508713,54,58.0034485,54z" />
                </svg>


                <h4 style={{ textAlign: "center", marginTop: "15px" }}>Check your email and click the validation link</h4>
            </> : <>
                { !signUpData.firstName.length ? <InputComponent
                    label="First Name" type="text" className="mb-16"
                    value={ signUpData.firstName } onChange={(e) => {
                        setSignUpData((prevState)=>{
                            return { ...prevState, firstName: e.target.value }
                        })
                    }}
                /> : null }
                { !signUpData.lastName.length ? <InputComponent
                    label="Last Name" type="text" className="mb-16"
                    value={ signUpData.lastName } onChange={(e) => {
                        setSignUpData((prevState)=>{
                            return { ...prevState, lastName: e.target.value }
                        })
                    }}
                /> : null }
                { !signUpData.email.length ? <InputComponent
                    name="new-Email"
                    label="Email" type="text" className="mb-16"
                    value={ signUpData.email } onChange={(e) => {
                        setSignUpData((prevState)=>{
                            return { ...prevState, email: e.target.value }
                        })
                    }}
                /> : null }
                <InputComponent
                    name="new-password"
                    label="Password" type="password" className="mb-16"
                    value={ signUpData.password } onChange={(e) => {
                        setSignUpData((prevState)=>{
                            return { ...prevState, password: e.target.value }
                        })
                    }} 
                />
                <InputComponent
                    label="Confirm Password" type="password" className="mb-16"
                    value={ signUpData.confirmPassword } onChange={(e) => {
                        setSignUpData((prevState)=>{
                            return { ...prevState, confirmPassword: e.target.value }
                        })
                    }} 
                />
                <SelectBoxComponent options={options} label="I am a..."
                    onChange={(e) => {
                        setSignUpData((prevState)=>{
                            return { ...prevState, role: e.value }
                        })
                    }}  
                />
                <ButtonComponent className="mt-20 mb-30" text={"Sign Up"} onClick={signUp}/>
            </> }

            <NotificationContainer/>
        </AuthLayoutComponent>
    )
}

export default Signup
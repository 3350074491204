import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	appliedFilters: {
		categories: [],
		cities: [],
		provinces: [],
	},
	modalOpened: null,
	mobileContentOpen: false,
	storeList: [],
	currentStore: null,
	currentProduct: null,
	mapStores: [],
	inStockFilter: false,
	productsList: [],
	listLoader: false,
	activeProducts:[]
}

export const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		applyFilters: (state, action) => {
			state.appliedFilters = action.payload;
		},
		addAppliedFilter: (state, action) => {
			state.appliedFilters[action.payload.name].push(action.payload.filter);
		},
		removeAppliedFilter: (state, action) => {
			state.appliedFilters[action.payload.name] = state.appliedFilters[action.payload.name].filter(itemF => itemF !== action.payload.filter);
		},
		openModalAction: (state, action) => {
			state.modalOpened = action.payload;
		},
		closeModalAction: (state, action) => {
			state.modalOpened = null;
		},
		toggleMobileContentOpen: (state, action) => {
			state.mobileContentOpen = action.payload;
		},
		setStoreList: (state, action) =>{
			state.storeList = action.payload;
		},
		_setCurrentStore: (state, action) => {
			state.currentStore = action.payload;
		},
		setCurrentProduct: (state, action) =>{
			state.currentProduct = action.payload;
		},
		setMapStores: (state, action) => {
			state.mapStores = action.payload;
		},
		toggleInStock: (state, action) => {
			state.inStockFilter = action.payload;
		},
		changeProductsList: (state, action) => {
			state.productsList = action.payload;
		},
		setListLoader: (state, action) => {
			state.listLoader = action.payload;
		},
		addActiveProduct: (state, action) => {
			if (!state.activeProducts.includes(action.payload)) {
				state.activeProducts.push(action.payload);
			}
		},
		removeActiveProduct: (state, action) => {
			state.activeProducts = state.activeProducts.filter(product => product !== action.payload);
		}
	},
})

export const {
	applyFilters, addAppliedFilter, removeAppliedFilter,
	openModalAction, closeModalAction, toggleMobileContentOpen,
	setStoreList, _setCurrentStore, setCurrentProduct,
	setMapStores, toggleInStock,
	changeProductsList, setListLoader,addActiveProduct, removeActiveProduct
} = dashboardSlice.actions

export default dashboardSlice.reducer
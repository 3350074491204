import React, { useEffect } from "react";
import {RouterProvider, useParams} from "react-router-dom";
import {router} from "./routes/routes.js";

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;

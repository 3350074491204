import React, {useState} from 'react'
import {ReactComponent as HideInputIco}  from "../../assets/images/hide-input-ico.svg"

const InputComponent = ({ label, value, onChange, type, className, name = '', subType = null }) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = () => {
        setShowPassword(prev => !prev);
    };
    return (
        <div className={`input-wrapper ${className}`}>
            { label ? <label className='input-label'>{label}</label> : null }
            <div className={(type === "password" || subType === 'password') ? "password-wrapper" : ""}>
                {subType === 'password' && value.length ? <input
                    id={name}
                    type={showPassword ? "text" : "password"}
                    value={value}
                    onChange={onChange}
                    name={name}
                    className="input"
                    autoComplete={name}
                /> :
                <input
                    id={name}
                    type={type === "password" && showPassword ? "text" : type}
                    value={value}
                    onChange={onChange}
                    name={name}
                    className="input"
                    autoComplete={name}
                />}
                {(type === "password" || subType === 'password') && (
                    <button className="toggle-password" onClick={handleTogglePassword}>
                        {showPassword ? <HideInputIco/> : <HideInputIco/>}
                    </button>
                )}
            </div>
        </div>
    );
};

export default InputComponent
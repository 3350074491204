import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchInput from "../../components/UI/SearchInput";
import Table from "../../components/dashboard/Table";
import { Map } from "../../components/Map/Map";
import { formatPrice } from "../../helpers";
import { ReactComponent as LockIcon } from "../../assets/images/lock-icon.svg";
import API from "../../api";
import { setMapStores, openModalAction, changeProductsList, toggleMobileContentOpen, toggleInStock, applyFilters } from "../../redux/dashboard/dashboard-slice";
import { useOutletContext, NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import Loader from "../../components/UI/Loader";
import { Pagination } from "../../components/UI/Pagination";
import ToggleInput from "../../components/UI/ToggleInput";

export const Products = ({ fullscreen = false }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const [searchString, setSearchString] = useState('');
    const inStockFilter = useSelector((state) => state.dashboard.inStockFilter);
    const { product, setProduct, activeProducts, setCurrentStore } = useOutletContext();

    const [SKUId, setSKUId] = useState({});
    const [stores, setStores] = useState([]);
    const [defaultStores, setDefaultStores] = useState([]);
    const [filteredStores, setFilteredStores] = useState([]);
    const [lastValidFilteredStores, setLastValidFilteredStores] = useState([]);
    const [provincesList, setProvincesList] = useState([]);
    const appliedFilters = useSelector((state) => state.dashboard.appliedFilters);
    const [cityList, setCityList] = useState([]);
    const [storeNames, setStoreNames] = useState([]);
    const [productsCurrentPage, setProductsCurrentPage] = useState(1);
    const [storesFromSidebar, setStoresFromSidebar] = useState([]);
    const [totalStores, setTotalStores] = useState(0);
    const [totalStoresDetails, setTotalStoresDetails] = useState([]);
    const accessLogin = localStorage.getItem("accessLogin") ? JSON.parse(localStorage.getItem("accessLogin")) : null;
    const storesThead = [
        {
            title: 'Province',
            sort: true,
            sortable_field: 'province',
            sort_active: false,
            hide_on_mob: true,
        },
        {
            title: 'City',
            sort: true,
            sortable_field: 'city',
            sort_active: false,
            hide_on_mob: true,
        },
        {
            title: 'Store Name',
            sort: true,
            sortable_field: 'name',
            sort_active: false,
        },
        {
            title: 'Brand',
            sort: true,
            sortable_field: 'brand',
            sort_active: false,
        },
        {
            title: 'SKUs',
            sort: true,
            hide_on_mob: true,
            sortable_field: 'sku',
            sort_active: false,
        },
        {
            title: 'Status',
            sort: true,
            sortable_field: 'status',
            sort_active: false,
        },
        {
            title: 'Price',
            sort: true,
            sortable_field: 'price',
            sort_active: false,
        }
    ];
    const [headerBoxClose, setHeaderBoxClose] = useState(false);
    const [categoriesFilters, setCategoriesFilters] = useState(['All']);
    const mobileWidth = window.innerWidth < 992;
    const mapStores = useSelector((state) => state.dashboard.mapStores);
    const productsList = useSelector((state) => state.dashboard.productsList);
    const [cardsData, setCardsData] = useState({
        "updatedAt": "",
        "producer": {
            "countOfProvince": null,
            "countOfCity": null,
            "countOfStore": null,
            "totalPages": 0
        },
        "totalInventory": 0
    });

    const dashboardStartLink = () => {
        return fullscreen ? '/dashboard/fullscreen' : '/dashboard';
    }
    const [mapKey, setMapKey] = useState(1);

    const setProductsList = (value) => { dispatch(changeProductsList(value)); };
    
    const getProductFromProducerID = async(producerID) => {
        let result = await API.singleStoreSelectGetProducts(producerID);
        setProductsList(result);

        setTimeout(() => {
            navigate(`${dashboardStartLink()}/products/${result[0].productID}`);
        }, 1000);
    }

    const setSort = (sortOptions) => {
        if (sortOptions.type === 'asc') {
            setStores([...defaultStores].sort((a, b) => (a[sortOptions.field] > b[sortOptions.field]) ? 1 : ((b[sortOptions.field] > a[sortOptions.field]) ? -1 : 0)));
        } else if (sortOptions.type === 'desc') {
            setStores([...defaultStores].sort((a, b) => (a[sortOptions.field] > b[sortOptions.field]) ? -1 : ((b[sortOptions.field] > a[sortOptions.field]) ? 1 : 0)));
        } else {
            setStores(defaultStores);
        }
    }

    const changeCategoriesFilter = (category) => {
        setCategoriesFilters(prevState => {
            let newCategoriesFilters;
            if (category === 'All') {
                newCategoriesFilters = [category];
            } else {
                if (prevState.includes(category)) {
                    newCategoriesFilters = prevState.filter(item => item !== category && item !== 'All');
                } else {
                    newCategoriesFilters = [...prevState, category].filter(item => item !== 'All');
                }
            }
            dispatch(applyFilters({ ...appliedFilters, provinces: newCategoriesFilters.includes('All') ? [] : newCategoriesFilters }));
            return newCategoriesFilters;
        });
    }
    const searchStoresFromActiveProducts = async(productForceName = false) => {
        let localData = [];
        let productObjs = {};
        let searchStoresByProductNameGetted = [];
        for (let index = 0; index < activeProducts.length; index++) {
            const brandToFilter = productsList.filter(store => store.productID === activeProducts[index]);
            let searchedName = '';
            let _productStore = null;
            if(productsList.find(item => (item.productID === activeProducts[-1]).name)) {
                searchedName = productsList.find(item => (item.productID === activeProducts[-1]))?.name
            } else {
                _productStore = await API.getStoreByProductId(activeProducts[index]);
                searchedName = productForceName ? productForceName : _productStore[0]?.producer?.name;
                if (!mobileWidth && productsList.find(item => (item.productID === activeProducts[index]))) {
                    searchedName = productsList.find(item => (item.productID === activeProducts[index])).name;
                }
            }
            if (!searchStoresByProductNameGetted.includes(searchedName)) {
                searchStoresByProductNameGetted.push(searchedName);
                const storesByProductNameResult = await API.searchStoresByProductName(searchedName, 1, 5000);
                console.log("storesByProductNameResult", storesByProductNameResult);
                const filteredStores = storesByProductNameResult.filter(store => store.brand === brandToFilter[0].brand);
                storesByProductNameResult.forEach((item) => {
                    const producerID = item.producer.producerID;
                    const productName = item.name;

                    if (productObjs.hasOwnProperty(producerID)) {
                        productObjs[producerID].push(productName);
                    } else {
                        productObjs[producerID] = [productName];
                    }
                });

                for (let index = 0; index < filteredStores?.length; index++) {
                    if (localData.filter(item => item.name === filteredStores[index]?.producer.name).length === 0) {
                        localData.push({
                            ...filteredStores[index]?.producer,
                            status: filteredStores[index]?.instockstatus,
                            brand: filteredStores[index]?.brand,
                            weight: filteredStores[index]?.weight,
                            price: filteredStores[index]?.price,
                        })
                    }
                }
            }
        }
            setSKUId(productObjs);
        setStoresFromSidebar(localData);
    }
    const totalStoreCount = async () => {
        try {
            const res = await API.searchBasicStores('', 100000, 1);
            setTotalStoresDetails(res)
            const totalCount = res.length
            setTotalStores(totalCount)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        totalStoreCount()
    }, [])

    const searchProductList = async () => {
        if (mobileWidth && (product?.productID !== id || !product) && (window?.location?.search.length && new URL(window.location.href).searchParams.get('search'))) {
            let productsSearchResult = await API.searchBasicProducts(new URL(window.location.href).searchParams.get('search'), 100, 1);
            if (productsSearchResult.length) {
                setProductsList(productsSearchResult);
                if (productsSearchResult.find(item => (item.productID === id))) {
                    setProduct(productsSearchResult.find(item => (item.productID === id)));
                    getCardData();
                    searchStoresFromActiveProducts(productsSearchResult.find(item => (item.productID === id)).name);
                }
            }
        }
    }

    const getCardData = async (filteredStores = stores) => {
        try {
            const provinces = new Set();
            const cities = new Set();
            const storenames = new Set();

            filteredStores.forEach((store) => {
                store.province && provinces.add(store.province);
                store.city && cities.add(store.city);
                store.name && storenames.add(store.name);
            });
            setProvincesList(Array.from(provinces));
            setCityList(Array.from(cities));
            setStoreNames(Array.from(storenames));
            setCardsData(prev => {
                return {
                    ...prev,
                    producer: {
                        ...prev.producer,
                        countOfProvince: provinces.size,
                        countOfCity: cities.size,
                        countOfStore: storenames.size,
                    }
                }
            });
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    const filterStores = (stores, filters) => {
        const filtered = stores.filter(store => {
            const matchesCities = !filters?.cities?.length || filters?.cities?.includes(store?.city);
            const matchesProvinces = !filters?.provinces?.length || filters?.provinces?.includes(store?.province);
            return matchesCities && matchesProvinces;
        });
        if (filtered.length === 0) {
            return lastValidFilteredStores;
        }

        return filtered;
    };

    useEffect(() => {
        const filtered = filterStores(stores, appliedFilters);
        if (filtered.length !== 0) {
            setLastValidFilteredStores(filtered);
        }
        setFilteredStores(filtered);
        getCardData(filtered);
    }, [appliedFilters, stores]);
    useEffect(() => {
        let matchesCities = 0
        let matchesProvinces = 0
        const filteredTotalStores = totalStoresDetails?.filter(store => {
            matchesCities = !appliedFilters?.cities?.length || appliedFilters?.cities?.includes(store?.city);
            matchesProvinces = !appliedFilters?.provinces?.length || appliedFilters?.provinces?.includes(store?.province);
            return matchesCities && matchesProvinces;
        });
        setTotalStores(filteredTotalStores.length);
    }, [appliedFilters, totalStoresDetails]);

    useEffect(() => {
        if (accessLogin && accessLogin.access) {
            getCardData();
        }
    }, [activeProducts]);

    const openModal = () => {
        dispatch(openModalAction("UnlockPremiumFeatures"));
    }

    const searchBasicStoresByCity = async (city) => {
        let result = await API.searchBasicStoresByCity(city);
        navigate(`${dashboardStartLink()}/stores/${result[0].producerID}?searchByCity=${city}`);
    }

    const setInStock = (value) => {
        dispatch(toggleInStock(value));
    }

    const filteredProducts = () => {
        return stores.filter(itemFilt =>
            (
                itemFilt.province.toLowerCase().includes(searchString.toLowerCase()) ||
                itemFilt.city.toLowerCase().includes(searchString.toLowerCase()) ||
                itemFilt.store_name.toLowerCase().includes(searchString.toLowerCase())
            ) && (
                categoriesFilters[0] === 'All' || categoriesFilters.includes(itemFilt.province)
            ) && (
                inStockFilter ? itemFilt.status === "In Stock" : true
            )
        )
    }

    useEffect(() => {
        if (!categoriesFilters.length) {
            setCategoriesFilters(['All'])
        }
    }, [categoriesFilters]);

    useEffect(() => {
        setStoresFromSidebar([]);
        if (productsList.find(item => (activeProducts.includes(item.productID)))) {
            searchStoresFromActiveProducts();
        }
    }, [activeProducts]);

    useEffect(() => {
        if (storesFromSidebar.length) {
            dispatch(setMapStores(storesFromSidebar));
            setStores(storesFromSidebar);
        }
    }, [storesFromSidebar]);

    useEffect(() => {
        setStores(mapStores);
        setDefaultStores(mapStores);
    }, [mapStores]);

    useEffect(() => {
        if (!categoriesFilters.includes('All')) {
            const setCardDataByCategory = (categoriesFilters) => {
                let provinceCount = categoriesFilters.length;
                let filteredStores = stores.filter(item => categoriesFilters.includes(item.province));
                let cityCount = new Set(filteredStores.map(item => item.city)).size;
                let storeCount = filteredStores.length;

                setCardsData(prev => {
                    return {
                        ...prev,
                        producer: {
                            ...prev.producer,
                            countOfProvince: provinceCount,
                            countOfCity: cityCount,
                            countOfStore: storeCount,
                        }
                    }
                });
            }
            setCardDataByCategory(categoriesFilters);
        } else {
            getCardData();
        }
    }, [categoriesFilters]);

    useEffect(() => {
        if (!mobileWidth || productsList.find(item => (item.productID === id))) {
            getCardData();
        }
    }, [product, activeProducts, id]);

    useEffect(() => {
        if (accessLogin && accessLogin.access) {
            searchProductList();
        }
    }, [location, mapStores, activeProducts, id]);

    useEffect(() => {
        setMapKey((prevState) => {
            return prevState + 1;
        })
    }, [fullscreen]);

    useEffect(() => {
        setFilteredStores(filteredProducts());
    }, [searchString, inStockFilter, categoriesFilters, stores]);

    useEffect(() => {
        getCardData(filteredStores);
    }, [filteredStores]);

    const calculatePercentage = (count, total) => {
        const percentage = (count / total) * 100;
        return percentage > 100 ? "Nil" : percentage.toFixed(2) + "%";
    };
    var count = 0
    var total = 0
    useEffect(() => {
        calculatePercentage(count, total)
    }, [appliedFilters])

    return (
        <div className={`dashboard-content flex-col align-items-start dashboard-content-product-page ${fullscreen ? 'map-fullscreen' : ''}`}>
            <div className="tph-cards-wrpr">
                {!fullscreen ? <div className="col-50">
                    <div className={`table-page-header-box w-100 ${headerBoxClose ? ' header-box-close' : ''} ${mobileWidth ? 'table-page-header-box-wrpr' : ''}`}>
                        <div className="relative">
                            <h1 className="b-0">
                                {activeProducts.length > 1 ? 'Multiple Product Selections' : product?.name || <Loader style={{ zoom: "0.3" }} />} {activeProducts.length > 1 ?
                                    null : <span>{stores[0]?.weight}{stores[0]?.weight && stores[0]?.brand ? ', ' : ''}{stores[0]?.brand}</span>
                                }
                            </h1>
                            <a
                                href="/neobi/#" className="table-page-header-box-toggler"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setHeaderBoxClose(!headerBoxClose);
                                }}
                            >
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.23002 12.4873C6.45002 13.2673 6.45002 14.5377 7.23002 15.3277L17.1602 25.2574L20 28.0977L22.8399 25.2574L32.7701 15.3277C33.5501 14.5477 33.5501 13.2773 32.7701 12.4873C31.9901 11.7073 30.7202 11.7073 29.9302 12.4873L20 22.4175L10.0699 12.4873C9.28986 11.7073 8.02002 11.7073 7.23002 12.4873Z" fill="#506D8B" />
                                </svg>
                            </a>
                        </div>
                        <div className="table-page-header-box map-container w-100 p-0 show-in-mob">
                            <div className="map-box" style={{
                                padding: "0",
                                margin: "0",
                                border: "0"
                            }}>
                                <Map activeProducts={activeProducts} setCurrentStore={setCurrentStore} fullscreen={fullscreen} />
                            </div>
                        </div>
                    </div>
                    <div className="show-in-mob w-100" style={{
                        padding: "10px 11px 6px",
                        background: "#081921",
                        marginTop: "2px",
                        marginBottom: "3px"
                    }}>
                        <SearchInput
                            value={searchString}
                            onChange={setSearchString}
                            placeholder="Search products at this location"
                        />
                    </div>
                    <div className="table-page-header-box w-50 card-product-info-wrpr pb-50">
                        <div className="card-product-info">
                            <div className="card-product-info-header">
                                {cardsData.producer?.countOfProvince ?
                                    <h3>{cardsData.producer.countOfProvince}</h3> : <Loader />}
                            </div>
                            <div className="card-product-info-body">
                                <h4>Provincial Distribution</h4>
                                <p>The total number of provinces the selected product is sold</p>
                            </div>
                        </div>
                    </div>
                    <div className="table-page-header-box w-50 card-product-info-wrpr pb-50">
                        <div className="card-product-info">
                            <div className="card-product-info-header">
                                {cardsData.producer?.countOfCity ?
                                    <h3>{cardsData.producer.countOfCity}</h3> : <Loader />}
                            </div>
                            <div className="card-product-info-body">
                                <h4>Municipal Distribution</h4>
                                <p>The total number of cities the selected product is sold</p>
                            </div>
                        </div>
                    </div>
                    <div className="table-page-header-box w-50 card-product-info-wrpr pb-50">
                        <div className="card-product-info">
                            <div className="card-product-info-header">
                                {cardsData.producer?.countOfStore ?
                                    <h3>{cardsData.producer.countOfStore}</h3> : <Loader />}
                            </div>
                            <div className="card-product-info-body">
                                <h4>Retail Distribution</h4>
                                <p>The total number of stores the selected product is sold</p>
                            </div>
                        </div>
                    </div>
                    <div className="table-page-header-box w-50 card-product-info-wrpr pb-50">
                        <div className="card-product-info">
                            <div className="card-product-info-header">
                                {cardsData.producer?.countOfStore ?
                                    <h3>{calculatePercentage(cardsData?.producer?.countOfStore, totalStores)}</h3> : <Loader />}
                            </div>
                            <div className="card-product-info-body">
                                <h4>Retail Penetration</h4>
                                <p>The total percentage of stores the selected product is sold</p>
                            </div>
                        </div>
                    </div>
                </div> : null}
                <div className="table-page-header-box map-container w-50 p-0 hide_on_mob">
                    <div className="map-box" key={mapKey}>
                        <NavLink
                            to={location.pathname.includes('/fullscreen') || mobileWidth ? (id ? `/dashboard/products/${id}` : '/dashboard') : (id ? `/dashboard/fullscreen/products/${id}` : '/dashboard/fullscreen')}
                            className="fullscreen-change-btn" style={{ right: fullscreen ? '34px' : '20px' }}
                        >
                            <svg height="20" style={{ enableBackground: "new 0 0 32 32" }} version="1.1" viewBox="0 0 32 32" width="20" xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <polygon points="29.414,26.586 22.828,20 20,22.828 26.586,29.414 24,32 32,32 32,24" fill="currentColor" />
                                    <polygon points="2.586,5.414 9.172,12 12,9.172 5.414,2.586 8,0 0,0 0,8" fill="currentColor" />
                                    <polygon points="26.586,2.586 20,9.172 22.828,12 29.414,5.414 32,8 32,0 24,0" fill="currentColor" />
                                    <polygon points="12,22.828 9.172,20 2.586,26.586 0,24 0,32 8,32 5.414,29.414" fill="currentColor" />
                                </g>
                            </svg>
                        </NavLink>
                        <Map activeProducts={activeProducts} setCurrentStore={setCurrentStore} fullscreen={fullscreen} />
                    </div>
                </div>
            </div>
            {!fullscreen ? <div className="table-page-tablebox">
                <div className="table-page-tablebox-header">
                    <h3 className="table-title">In Stock Stores</h3>
                    <div className="d-flex align-items-center justify-content-end flex-1 stores-page-dateinstock-wrpr">
                        <ToggleInput
                            label="In Stock"
                            checked={inStockFilter}
                            onChange={setInStock}
                        />
                        <div className="vertical-margin-line"></div>
                        <SearchInput value={searchString} onChange={setSearchString} placeholder="Search products at this location" />
                    </div>
                </div>
                <ul className="filter-btns-group items-min-w-140 mb-20">
                    {
                        [
                            'All',
                            ...provincesList
                        ].map((item, index) => <li key={index}>
                            <div
                                className={categoriesFilters.includes(item) ? 'active' : ''}
                                onClick={(e) => {
                                    e.preventDefault();
                                    changeCategoriesFilter(item);
                                }}
                            >{item}</div>
                        </li>)
                    }
                </ul>
                <Table
                    thead={storesThead}
                    setSort={setSort}
                >
                    {
                        !stores.length || !product?.name || !cardsData ?
                            <tr>
                                <td colSpan={mobileWidth ? '3' : '7'}>
                                    <div className="d-flex align-items-center justify-content-c min-h-50">
                                        <Loader />
                                    </div>
                                </td>
                            </tr> :
                            (
                                filteredStores.length < 100 ?
                                    filteredStores :
                                    filteredStores.slice(+productsCurrentPage === 1 ? 0 : +productsCurrentPage * 100 - 100, +productsCurrentPage * 100)
                            ).map((item, index) => {
                                const sku = SKUId[item.producerID] ? SKUId[item.producerID].map(s => s.substring(0, 4) + '...').join(', ') : 'N/A';
                                const fullSku = SKUId[item.producerID] ? SKUId[item.producerID].join(', ') : 'N/A';
                                return <tr key={index}>
                                    <td className="hide_on_mob">{item.province}</td>
                                    <td className="hide_on_mob">
                                        <a href="/neobi/#" className="text-underline" onClick={(e) => {
                                            e.preventDefault();
                                            searchBasicStoresByCity(item.city);
                                        }}>
                                            {item.city}
                                        </a>
                                    </td>
                                    <td style={{ color: "#00E0D1" }}>
                                        <NavLink to={`${dashboardStartLink()}/stores/${item.producerID}?search=${item.name}&searchString=${item.name}&feetBounds=false`} className="text-underline">
                                            {item.name}
                                        </NavLink>
                                    </td>
                                    <td>{item.brand ? item.brand : "N/A"}</td>
                                    <td className="hide_on_mob">
                                        <span className="tooltip">
                                            {sku}
                                            <span className="tooltiptext">{fullSku}</span>
                                        </span>
                                    </td>
                                    <td>{item.status}</td>
                                    <td>{formatPrice(item.price)}</td>
                                </tr>
                            })
                    }
                </Table>
                {filteredStores.length && filteredStores.length > 100 ?
                    <Pagination
                        change={(value) => {
                            setProductsCurrentPage(value);
                        }}
                        max={Math.ceil(filteredStores.length / 100)}
                    />
                    : null}
            </div> : null}
        </div>
    )
}

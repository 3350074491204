import {
    useMap,
    MapContainer,
    Marker,
    Popup,
    TileLayer,
    useMapEvents,
} from "react-leaflet";
import L from "leaflet";
import MarkerClusterGroup from "./MakeClusterGroup.ts";
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { _setCurrentStore } from "../../redux/dashboard/dashboard-slice";
import { createImmutableStateInvariantMiddleware, current } from "@reduxjs/toolkit";

// { points }
export const Map = ({ currentStore, activeProducts, setCurrentStore, fullscreen, loaderProcess}) => {

    

    // const points = useSelector((state) => state.map.apiProducts);
    // const currentStore = useSelector((state) => state.dashboard.currentStore);
    const navigate = useNavigate();
    const location = useLocation();
    // const points = useSelector((state) => state.dashboard.storeList);
    const points = useSelector((state) => state.dashboard.mapStores);
    // const storeList = useSelector((state) => state.dashboard.storeList);
    const popupRef = useRef([]);
    const mapRef = useRef(null);
    const markerRef = useRef(null);
    const dispatch = useDispatch();

    const [bounds, setBounds] = useState([[51.05011, -114.08529]]);
    const [mapDragging, setMapDragging] = useState(true);
    const [products, setProducts] = useState([]);
    const [filterAddress, setFilterAddress] = useState([]);
    const [map, setMap] = useState(null);
    const [position, setPosition] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState({});
    const { storeId } = useParams();
    // const [center, setCenter] = useState(points.find((store) =>  +store.producerID === +storeId) ? [
    //     +points.find((store) => +store.producerID === +storeId).latitude,
    //     +points.find((store) => +store.producerID === +storeId).longitude
    // ] : [0, 0]);

   
    const center = points.length && storeId 
        ? points.find(store => store.producerID === +storeId) 
            ? [points.find(store => store.producerID === +storeId).latitude, points.find(store => store.producerID === +storeId).longitude] 
            : [points[0].latitude, points[0].longitude] 
        : [0, 0];
   
    console.log('center:', center);
    
    const locationSearchFilter = window?.location?.search;
    const [currentMapZoom, setCurrentMapZoom] = useState(20);

    const [clicked, setClicked] = useState(null);

    const MapConsumer = () => {
        const _map = useMap();
        _map.locate();
        mapDragging ? _map.dragging.enable() : _map.dragging.disable();
        return null;
    };
    const handleMarkerPopup = (e) => {
        map.setView([currentStore.latitude, currentStore.longitude]);
    }

    useEffect(() => {
        if (currentStore && map) {
            console.log('currentStore:', currentStore);
            handleMarkerPopup();
        }
    }, [currentStore]);

    useEffect(() => {
        if(points?.length && map){        
            const boundsRes = L.latLngBounds(points.map(positionsCoords => { return [+positionsCoords.latitude, +positionsCoords.longitude] }));
            setBounds(boundsRes);
            if (boundsRes.isValid() && map) {
                if(!storeId || (
                    !(locationSearchFilter.length && new URL(window.location.href).searchParams.get('feetBounds') === "false") &&
                    (locationSearchFilter.length && new URL(window.location.href).searchParams.get('filter') === "all" || activeProducts?.length) || 
                    (locationSearchFilter.length && new URL(window.location.href).searchParams.get('feetBounds') === "true")
                )){
                    setTimeout(() => {
                        map.fitBounds(boundsRes); 
                    }, 100);
                }
                
                // map.setMaxZoom(18);
            }
        }
    }, [points, map, locationSearchFilter]);

    useEffect(() => {
        // resolve the marker display issue
        const L = require("leaflet");

        delete L.Icon.Default.prototype._getIconUrl;

        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
            iconUrl: require("leaflet/dist/images/marker-icon.png"),
            shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
        });

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setPosition({lat: latitude,lng: longitude});
                },
                (error) => {
                    console.error(
                        `Error getting geolocation: ${error.message}`
                    );
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }, []);

    useEffect(() => {
        if (map && storeId && points && (!window?.location?.search.length || (locationSearchFilter.length && new URL(window.location.href).searchParams.get('feetBounds') === "false"))) {
            const marker = points.find((store) => (+store.producerID === +storeId) || (+store.p_id === +storeId));
            if(marker){
                map.setView([+marker.latitude, +marker.longitude], 18);
            }
        }

        if(map){
            // L.tileLayer('https://tile.jawg.io/3327f913-f594-4681-b419-1cf25bc0a806/{z}/{x}/{y}{r}.png?access-token=YIp7T2YDh8i027pBRtSDMQ07QtfbMYTGreeaiGC2RZ9l2UzlxG3iK4oJcuOOJ0Nq', {
            //     attribution: '<a href=\"https://www.jawg.io\" target=\"_blank\">&copy; Jawg</a> - <a href=\"https://www.openstreetmap.org\" target=\"_blank\">&copy; OpenStreetMap</a>&nbsp;contributors',
            //     minZoom: 0,
            //     maxZoom: 22,
            //     accessToken: 'YIp7T2YDh8i027pBRtSDMQ07QtfbMYTGreeaiGC2RZ9l2UzlxG3iK4oJcuOOJ0Nq'
            // }).addTo(map);
            
            if(fullscreen){
                map.on('zoomend', function() {
                    setCurrentMapZoom(map._zoom);
                });
            }
        }
    }, [map, storeId, locationSearchFilter]);

    // useEffect(() => {
    //     showActiveMarkerPopup();
    // }, [map, storeId]);

    // useEffect(()=>{
    //     if(map){
            
    //     }
    // },[map, map._zoom])
     

    const showActiveMarkerPopup = () => {
        if (map && storeId && points && (!window?.location?.search.length || (locationSearchFilter.length && new URL(window.location.href).searchParams.get('feetBounds') === "false"))) {
            const marker = points.find((store) => (+store.producerID === +storeId) || (+store.p_id === +storeId));
            if(marker){
                let activeIndex = points.findIndex((store) => (+store.producerID === +storeId) || (+store.p_id === +storeId));
                //  && clicked !== activeIndex
                if(document.querySelector(`.marker-${activeIndex}`)){
                    console.log(`.marker-${activeIndex}:`, document.querySelector(`.marker-${activeIndex}`));
                    setTimeout(() => {
                        document.querySelector(`.marker-${activeIndex}`).click();
                    }, 3500);
                    // setClicked(activeIndex);
                }
            }
        }
    }

    const createClusterCustomIcon = function (cluster) {
        let mrkr = cluster.getAllChildMarkers();
        let clusterClass = "default-height";

        if (cluster.getChildCount() < 10) clusterClass = "single-figure";
        else if (cluster.getChildCount() >= 10 && cluster.getChildCount() < 100)
            clusterClass = "double-figure";
        else if (
            cluster.getChildCount() >= 100 &&
            cluster.getChildCount() < 1000
        )
            clusterClass = "triple-figure";
        else if (cluster.getChildCount() >= 1000) clusterClass = "four-figure";

        let productList = [...mrkr[0].options.data.products];
        let temp = [];
        mrkr.forEach((mr) => {
            if (
                checkAvailablilityHelper(mr.options.data.pos, productList) ===
                false
            ) {
                temp.push(mr.options.data.pos);
            }
        });
        return L.divIcon({
            html: `<span>${cluster.getChildCount()}</span>`,
            className:
                "marker-cluster-custom " +
                process.env.REACT_APP_STOCK +
                " " +
                clusterClass,
            iconSize: L.point(60, 60, true),
        });
    };

    const fitBounds = (lc, id) => {
        id === null ? setSelectedLocation(lc) : setSelectedLocation({});
        const latitude = +lc.latitude;
        const longitude = +lc.longitude;
        let group = L.featureGroup(
            lc.type && lc.type === "click"
                ? L.marker([latitude, longitude])
                : [L.marker([latitude, longitude])]
        );
        if (group.getBounds().isValid()) {
            map?.flyTo(
                [latitude, longitude],
                map.getZoom() >= 16 ? map.getZoom() : 16,
                {
                    ...{ padding: [20, 20] },
                }
            );
            map?.setMaxZoom(18);
        }
    };

    const testIco = (_icoSize, customClass="") => {
        return L.icon({
            iconUrl: require("../../assets/images/map-marker-48.png"),
            iconSize: [30, 30],
            className: customClass,
            popupAnchor: [20, -10], // point from which the popup should open relative to the iconAnchor
        });
    };

    const myPositionIco = () => {
        return L.icon({
            iconUrl: require("../../assets/images/my-position.png"),
            iconSize: [30, 30],
            popupAnchor: [20, -10],
        })
    }

    const createCustomIcon = L.divIcon({
        html: `<span></span>`,
        className: "marker-cluster-custom " + process.env.REACT_APP_STOCK,
        iconSize: new L.Point([60, 60]),
        iconAnchor: [10, 41],
        popupAnchor: [30, 0],
    });
    const createCustomIconPurple = L.divIcon({
        html: `<span></span>`,
        className: "marker-cluster-custom purple",
        iconSize: new L.Point([60, 60]),
        // iconAnchor: [10, 41],
        popupAnchor: [30, 0],
    });
    const selectedIconGrey = L.divIcon({
        html: `<span></span>`,
        className:
            "selected-marker purple" + process.env.REACT_APP_OUT_OF_STOCK,
        iconSize: new L.Point([60, 60]),
        // iconAnchor: [10, 41],
        popupAnchor: [30, 0],
    });

    const selectedIconGreen = L.divIcon({
        html: `<span></span>`,
        className: "selected-marker purple " + process.env.REACT_APP_STOCK,
        iconSize: new L.Point([60, 60]),
        // iconAnchor: [10, 41],
        popupAnchor: [30, 0],
    });
    // return location stlyes along with black centered circle
    const createCustomIconDisabled = L.divIcon({
        html: `<span></span>`,
        className:
            "marker-cluster-custom " + process.env.REACT_APP_OUT_OF_STOCK,
        iconSize: new L.Point([60, 60]),
    });

    // useEffect(() => {
    //     setFilterAddress(points);
    //     let markers = [];
    //     points.forEach((item) => {
    //         markers.push(L.marker([item.latitude, item.longitude]));
    //     });
    //     let markersGroup = new L.featureGroup(markers);
    //     console.log("markersGroup", markersGroup.getBounds());
    //     console.log("map = ", map.fitBounds(markersGroup.getBounds()));
    //     if (markersGroup.getBounds().isValid()) {
    //         map.fitBounds(markersGroup.getBounds());
    //         map.setMaxZoom(18);
    //     }
    // }, [points]);

    const CurrentLocationIcon = L.divIcon({
        html: `<span></span>`,
        className: process.env.REACT_APP_CURRENT_LOCATION,
        iconSize: [50, 50],
        iconAnchor: [10, 41],
        popupAnchor: [300, 200],
    });

    const checkAvailablilityHelper = (pos, prd = null) => {
        if (!(pos && pos.products)) return;
        let te = [];
        let productList = [];
        products.length === 0
            ? (productList = [...prd])
            : (productList = [...products]);
        productList.map((v) => {
            pos.products.map((pr) => {
                if (
                    v.name == pr.name &&
                    pr.instockstatus === "In Stock" &&
                    v.isActive === true
                ) {
                    te.push(pr);
                }
            });
        });
        return te.length <= 0;
    };

    const loadStoreData = (item) => {
        // let navigateUrl = `/dashboard/stores/${item.producerID || item.p_id}&forceSearch=true`;

        // if(location.pathname.includes('/products/')){
        //     navigateUrl = `/dashboard/stores/${item.producerID || item.p_id}?search=${item.name}&feetBounds=false&forceSearch=true`
        // }

        let navigateUrl = `/dashboard/stores/${item.producerID || item.p_id}?search=${item.name}&feetBounds=false&forceSearch=true`

        navigate(navigateUrl);

        dispatch(_setCurrentStore(item.producerID || item.p_id));
        setCurrentStore(item);
    }

    const setMapZoom = (value = 1, type = null) => {
        let settedValue = value;

        if(type !== null){
            settedValue = +currentMapZoom;
        }

        if(type === "minus"){
            if(+currentMapZoom !== 0){
                settedValue -= 1;
            } else {
                return;
            }
        }
        if(type === "plus"){
            if(+currentMapZoom !== 21){
                settedValue += 1;
            } else {
                return;
            }
        }

        map.setZoom(settedValue);
    }


    
    return (
        <>
            {/* <ClipLoader color={process.env.REACT_APP_ACTIVE} loading={loader || localLoader} css={override} size={50} /> */}
            <MapContainer
                center={center}
                zoom={15}
                maxZoom={18}
                minZoom={0}
                scrollWheelZoom={true}
                className="map-Div"
                ref={setMap}
                whenCreated={(map) => {
					mapRef.current = map
				}}
                // bounds={bounds}
            >
                { fullscreen ? <div className="map-zoom-block">
                    <a href="/#" onClick={(e)=>{
                        e.preventDefault();
                        setMapZoom(1, 'plus');
                    }}>+</a>
                    <ul>
                        { Array.from({length: 21}, (_, index) => index + 1).map((item, index)=>{
                            return <li key={index} 
                                className={`
                                    ${Math.floor(item) === currentMapZoom-2 ? 'active-prev-2 sub-active' : ''}
                                    ${Math.floor(item) === currentMapZoom-1 ? 'active-prev-1 sub-active' : ''}
                                    ${Math.floor(item) === currentMapZoom ? 'active' : ''}
                                    ${Math.floor(item) === currentMapZoom+1 ? 'active-next-1 sub-active' : ''}
                                    ${Math.floor(item) === currentMapZoom+2 ? 'active-next-2 sub-active' : ''}
                                `}
                                onClick={(e)=>{
                                    setMapZoom(item);
                                }}
                            ></li>
                        })}
                    </ul>
                    <a href="/#" onClick={(e)=>{
                        e.preventDefault();
                        setMapZoom(1, 'minus');
                    }}>-</a>
                </div> : null }
                <div className="povered-by-neobi">
                    Powered By
                    <svg viewBox="0 0 100 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.0193 10.106C4.93301 10.106 0 15.0066 0 21.053V32H5.5063V21.053C5.5063 18.0331 7.9728 15.5828 11.0126 15.5828C14.0524 15.5828 16.5189 18.0331 16.5189 21.053V32H22.0252V21.053C22.0252 15.0066 17.0922 10.106 11.0059 10.106H11.0193Z" fill="white"/>
                        <path d="M98.7271 10.106H93.2205V31.9934H98.7271V10.106Z" fill="white"/>
                        <path d="M80.9281 10.106C78.9216 10.106 77.0417 10.6424 75.4218 11.5695V0H69.9155V21.0464C69.9155 27.0927 74.8485 31.9934 80.9348 31.9934C87.0211 31.9934 91.9541 27.0927 91.9541 21.0464C91.9541 15 87.0211 10.0993 80.9348 10.0993L80.9281 10.106ZM80.9281 26.5232C77.8883 26.5232 75.4218 24.0728 75.4218 21.053C75.4218 18.0331 77.8883 15.5828 80.9281 15.5828C83.9679 15.5828 86.4344 18.0331 86.4344 21.053C86.4344 24.0728 83.9679 26.5232 80.9281 26.5232Z" fill="white"/>
                        <path d="M95.9737 8C98.1976 8 100 6.20914 100 4C100 1.79086 98.1976 0 95.9737 0C93.7498 0 91.947 1.79086 91.947 4C91.947 6.20914 93.7498 8 95.9737 8Z" fill="white"/>
                        <path d="M45.337 21.0466H39.8307C39.8307 22.4439 39.2907 23.8479 38.2174 24.9141C36.7175 26.4042 34.5577 26.8545 32.6645 26.2651L37.9175 21.0466L39.5774 19.3976L43.7037 15.2982C43.2638 14.5896 42.7305 13.9207 42.1172 13.3048C41.4972 12.689 40.8239 12.1658 40.1106 11.7287C35.8643 9.11942 30.218 9.64922 26.5382 13.3048C22.8518 16.9671 22.3252 22.5697 24.9517 26.7883C25.3916 27.4969 25.9249 28.1658 26.5382 28.7817C27.1582 29.3976 27.8315 29.9207 28.5448 30.3578C32.7911 32.9671 38.4374 32.4373 42.1172 28.7817C44.2704 26.6426 45.3436 23.8413 45.3436 21.0399L45.337 21.0466ZM30.4246 17.179C31.9245 15.689 34.0844 15.2386 35.9776 15.828L29.0647 22.6956C28.4648 20.8148 28.9181 18.6691 30.4246 17.179Z" fill="white"/>
                        <path d="M63.1157 20.2052H68.6553C68.242 14.8277 63.9223 10.5363 58.5027 10.1323V15.6356C60.8759 15.9932 62.7491 17.8476 63.1157 20.2052Z" fill="#00E0D1"/>
                        <path d="M57.6695 26.5232V31.9934C63.4691 31.9934 68.2221 27.543 68.6488 21.894H63.1091C62.7025 24.5166 60.4227 26.5232 57.6628 26.5232H57.6695Z" fill="#F5A800"/>
                        <path d="M52.163 21.0464H46.6567C46.6567 27.0927 51.5897 31.9934 57.676 31.9934V26.5232C54.6362 26.5232 52.1697 24.0729 52.1697 21.053L52.163 21.0464Z" fill="#FF6C0E"/>
                        <path d="M56.8028 15.6425V10.1392C51.1232 10.5762 46.6501 15.2915 46.6501 21.0464H52.1564C52.1564 18.318 54.1696 16.0531 56.8028 15.6425Z" fill="white"/>
                    </svg>
                </div>
                <MapConsumer />

                {/* <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url={`${process.env.REACT_APP_MAPBOX_URL}?access_token=${process.env.REACT_APP_MAPBOX_KEY}`}
                /> */}

                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url={`https://api.mapbox.com/styles/v1/bulli4ka/clhg8q8g501a701pngtr1eit4/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_KEY}`}
                />

                {points?.length ? <MarkerClusterGroup
                    key={Date.now()}
                    showCoverageOnHover={false}
                    spiderfyDistanceMultiplier={2}
                    iconCreateFunction={createClusterCustomIcon.bind(products)}
                >
                    { points.map((pos, index) => (
                        <Marker
                           ref={markerRef}
                            key={index}
                            position={[pos.latitude, pos.longitude]}
                            icon={testIco(30, `marker-${index}`)}
                            data={{ pos, products }}
                            isActive= {pos.producerID === currentStore?.producerID}
                            
                            eventHandlers={{
                                click: (e) => {
                                    // e.target.closePopup();
                                    const marker = e.target;
                                    const position = marker.getLatLng();
                                    // map.flyTo({lat: position.lat+0.004, lng: position.lng}, 13);
                                    map.setView([position.lat, position.lng]);
                                    // navigate(`/dashboard/stores/${pos.producerID}`)
                                    // e.target.click();
                                    // setLocalLoader(true);
                                    // dispatch(
                                    //     getSingleLocationData(
                                    //         `longlat=${currentLocation?.lng ?? 0},${
                                    //             currentLocation?.lat ?? 0
                                    //         }&location=${encodeURIComponent(
                                    //             pos.name
                                    //         )}`,
                                    //         "click"
                                    //     )
                                    // );
                                } ,
                                add : (e) => {
                                    if (pos.producerID === currentStore?.producerID) 
                                    e.target.openPopup();
                                }
                            }}
                            
                        >
                            {
                                <Popup
                                    ref={(el) =>
                                        (popupRef.current[index] = el)
                                    }
                                    className={
                                        "location-direction " +
                                        process.env.REACT_APP_MODE_CLASS
                                    }
                                    onClose={() => {
                                        if (
                                            pos.p_id !== selectedLocation.p_id
                                        ) {
                                            // if (locationsRef.current[pos.p_id])
                                            // locationsRef.current[pos.p_id].click();
                                            // setEnableClick(false);
                                            // setSelectedLocation({});
                                        }
                                    }}
                                >
                                    <div>
                                        <div className="address-section">
                                            <span className="bld-name">
                                                {pos.name}
                                            </span>
                                        </div>
                                        { pos.distance ? <div className="distance">
                                            <span>
                                                {(
                                                    parseFloat(pos.distance) /
                                                    1000
                                                )?.toFixed(1) + " KM"}
                                            </span>
                                        </div> : null }
                                        <div className="address-section">
                                            <span
                                                className="secondary-txt"
                                                style={{
                                                    color: process.env
                                                        .REACT_APP_LIGHT_FONT,
                                                }}
                                            >
                                                {pos?.address +
                                                    ", " +
                                                    pos?.city +
                                                    ", " +
                                                    pos?.province}
                                            </span>
                                        </div>
                                        <div
                                            className="outer-div-direction"
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between"
                                            }}
                                        >
                                            {/* {position ? ( */}
                                            {pos?.latitude ? (
                                                <>
                                                    <a
                                                        className="direction-popup-button"
                                                        target={"_blank"}
                                                        href={`https://www.google.com/maps/dir/${pos?.latitude},${pos?.longitude}/${pos?.latitude},${pos?.longitude}`}
                                                        style={{
                                                            background: process.env.REACT_APP_DARK_BACKGROUND,
                                                            color: process.env.REACT_APP_DARK_FONT,
                                                            border: "1px solid " + process.env.REACT_APP_LIGHT_FONT,
                                                        }}
                                                    >
                                                        Directions
                                                    </a>
                                                    <a
                                                        className="direction-popup-button"
                                                        href="/#"
                                                        style={{
                                                            background: process.env.REACT_APP_DARK_BACKGROUND,
                                                            color: process.env.REACT_APP_DARK_FONT,
                                                            border: "1px solid " + process.env.REACT_APP_LIGHT_FONT,
                                                            marginLeft: "10px"
                                                        }}
                                                        onClick={(e)=>{
                                                            e.preventDefault();
                                                            loadStoreData(pos);
                                                        }}
                                                    >
                                                        See Menu
                                                    </a>
                                                </>
                                            ) : (
                                                <button
                                                    onClick={() =>
                                                        // directionHandler(pos)
                                                        console.log("Clicked")
                                                    }
                                                    className="direction-popup-button"
                                                    style={{
                                                        background:
                                                            process.env
                                                                .REACT_APP_DARK_BACKGROUND,
                                                        color: process.env
                                                            .REACT_APP_DARK_FONT,
                                                        border:
                                                            "1px solid " +
                                                            process.env
                                                                .REACT_APP_LIGHT_FONT,
                                                    }}
                                                >
                                                    Directions
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </Popup>
                            }
                        </Marker>
                    ))}
                </MarkerClusterGroup> : null }
                {position && <Marker position={position} icon={myPositionIco()}>
                    <Popup>
                        My position
                    </Popup>
                </Marker>}
            </MapContainer>
        </>
    );
};

import React, { useState, useEffect } from 'react';
import ButtonComponent from '../../components/StartPagesComponents/ButtonComponent'
import { HomeLoginedHeader } from '../../components/StartPagesComponents/HomeLoginedHeader'
import HomeSearchComponent from '../../components/StartPagesComponents/HomeSearchComponent'
// import { ReactComponent as Logo } from "../../assets/images/Logo-homepage.svg";
import { ReactComponent as BetaLogo } from "../../assets/images/BetaLogo.svg";
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router';
import API from "../../api";

const HomeLogedIn = () => {
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState("");
    const [isSeacrchOpen, setIsSearchOpen] = useState(false);
    const mobileWidth = window.innerWidth < 992;
    const [disclaimer, setDisclaimer] = useState(null);

    const searchAction = () => {
        if(searchValue.length){
            navigate(`/dashboard?searchString=${searchValue}`)
        } else {
            // navigate(mobileWidth ? '/dashboard' : '/dashboard/fullscreen/stores')
            navigate(mobileWidth ? '/dashboard' : '/dashboard/fullscreen/stores?all=true')
        }
    }

    const getSettings = async() => {
        let result = await API.getSettings();
        if(result?.disclaimer){
            setDisclaimer(result.disclaimer);
        }
    }

    useEffect(() => {
        getSettings();
    }, []);

    return (
        <>
            <HomeLoginedHeader />
            <main className="main-container full-height-home">
                <div className="homelogin-container inner-container-width">
                    {/* <Logo/> */}
                    <div style={{ maxWidth: 'calc(158px * var(--scale))', width: '100%' }}>
                        <BetaLogo />
                    </div>
                    <HomeSearchComponent searchValue={searchValue} setIsSearchOpen={setIsSearchOpen} setSearchValue={setSearchValue}/>
                    {/* <div className='home-loggedin-button'>{(!isSeacrchOpen || window.innerWidth > 990) && <ButtonComponent text={"Search"}/>}</div> */}
                    { disclaimer ?
                        <p className="search-box-disclaimer">{disclaimer}</p> : null 
                    }
                    <div className='home-loggedin-button'>
                        {(!isSeacrchOpen || window.innerWidth > 990) && 
                            <a href="/#" className="btn btn-secondary" onClick={(e)=>{
                                e.preventDefault();
                                searchAction();
                            }}>Search</a>
                        }
                    </div>
                </div>
            </main>
        </>
    )
}

export default HomeLogedIn
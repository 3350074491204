import {useEffect } from "react";
import {Outlet} from "react-router-dom";
import {Sidebar} from "../../components/settings/Sidebar/Sidebar";

export const Settings = () => {

    return(
        <div className="settings-container">
            <Sidebar />
            <Outlet />
        </div>
    )
}
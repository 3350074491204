import React from 'react'
import Select from 'react-select';

const SelectBoxComponent = ({label, onChange, value, options}) => {
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#07161C',
            borderColor: state.menuIsOpen ? 'var(--secondary)' : '#324457',
            // zIndex: state.menuIsOpen ?  "20" : "10",
            borderRadius: '300px',
            // color: state.menuIsOpen ? 'var(--secondary)' : 'red',
            boxShadow: 'none',
            padding: 'calc(9px * var(--scale)) calc(20px * var(--scale))',
            '&:hover': {
                borderColor: 'var(--secondary)',
            },
            '&:focus': {
                borderColor: 'var(--secondary)',
            },
            minWidth: '100%',
            fontSize: 'calc(20px * var(--scale))',
            lineHeight: 'calc(24px * var(--scale))',
        }),
        option: (provided, state) => {
            return ({
                ...provided,
                backgroundColor: state.isSelected ? '#0B2028' : '#07161C',
                color: state.isSelected ? '#fff' : '#7C8DA0',
                padding: 'calc(13px * var(--scale)) calc(20px * var(--scale))',
                fontSize: 'calc(20px * var(--scale))',
                lineHeight: 'calc(24px * var(--scale))',
                '&:hover': {
                    backgroundColor: '#0B2028',
                    color: '#fff',
                },
                cursor: 'pointer',
            })
        
        },
        indicatorSeparator: () => ({ display: "none" }),
        menu: (provided, state) => ({
            ...provided,
            zIndex: "10",
            // borderRadius: '10px',
            marginTop: 0,
            marginBottom: 0,
            borderRadius: '0px 0px calc(30px * var(--scale)) calc(30px * var(--scale))',
            // top: 'calc(32px * var(--scale))',
            backgroundColor: '#07161C',
            boxShadow: 'none',
        }),
        menuList: (provided) => ({
            ...provided,
            paddingTop: 'calc(20px * var(--scale))',
            paddingBottom: 0,
            border: '1px solid var(--secondary)',
            borderRadius: '0px 0px 30px 30px',
            borderTop: 0,
        }),
        valueContainer: (provided) => ({
            ...provided,
            zIndex: 20,

            padding: 0, 
        }),
        singleValue: (provided, state) =>({
            ...provided,
            color: state.selectProps.menuIsOpen ? 'var(--secondary)' : '#7C8DA0'
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            zIndex: "20",
            padding: 0,
        }),
        clearIndicator: (provided, state) => ({
            ...provided,
            padding: 0,
        }),
    };
    const classNames = {
        option: (state) => {
            return state.data.isPremium ? 'option-selectbox premium-option' : 'option-selectbox'
        },
        menu: (state) => "menu-selectbox",
        control: (state) => "control-selectbox",
      }
    return (
        <div style={{ width: "100%", position: 'relative' }}>
            {label && <label className='input-label'>{label}</label>}
            <Select
                options={options}
                styles={customStyles}
                classNames={classNames}
                clearable={false}
             
                menuPosition="absolute"
                menuPlacement="bottom"
                isSearchable={false}
                onChange={onChange}
                value={value}
            />
        </div>
    )
}

export default SelectBoxComponent
import { ReactComponent as IconClose } from "../../assets/images/close-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { closeModalAction } from "../../redux/dashboard/dashboard-slice";
import { Link, useNavigate } from 'react-router-dom';

export const UnlockPremiumFeaturesModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const close = () => {
        dispatch(closeModalAction());
    }

    const sendMessage = () => {
        close();
        navigate("/settings/help-desk", { replace: true });
    }

    const contact = () => {
        if(window?.zE) window.zE.activate();
        close();
    }

    return(
        <div className="modal modal--show">
            <div className="modal__dialog modal__dialog--960">
                <div className="modal__content ">
                    <a href="/neobi/#" className="modal-close-btn" onClick={(e) => {
                        e.preventDefault();
                        close();
                    }}>
                        <IconClose />
                    </a>
                    <div className="modal-unlock-premium-body">
                        <h2 className="mb-20">Unlock Premium Features</h2>
                        <p>For access to this feature you’ll need a premium account. <br />
                        To learn more and get pricing please contact us </p>
                        {/* <a
                            href="/neobi/#" className="btn green-btn"
                            onClick={(e) => {
                                e.preventDefault();
                                sendMessage();
                            }}
                        >Send Message</a> */}
                        <a
                            // className="btn color-grey"
                            // href="mailto:support@neobitechnology.zendesk.com?subject=Inquiring on Premium features"
                            href="#" className="btn green-btn"
                            onClick={(e) => {
                                e.preventDefault();
                                contact();
                            }}
                        >Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
import { useState, useEffect } from "react";

export const Pagination = ({ change, max }) => {
    const [productsCurrentPage, setProductsCurrentPage] = useState(1);
    const productsTotalPage = max;
    const [productPagesArray, setProductPagesArray] = useState(Array.from(Array(productsTotalPage+1).keys()).splice(1));

    const setProductPaginationPage = (type) => {
        if(type === 'prev' && productsCurrentPage !== 1){
            setProductsCurrentPage(productsCurrentPage-1);
        }
        if(type === 'next' && productsCurrentPage !== productsTotalPage){
            setProductsCurrentPage(productsCurrentPage+1);
        }
    }
    
    useEffect(()=>{
        if(max > 9){
            let start = Array.from(Array(productsTotalPage+1).keys()).splice(1).slice(productsCurrentPage-1, productsCurrentPage+2);
            let end = Array.from(Array(productsTotalPage+1).keys()).splice(1).splice(-3);

            if(start[start.length-1] < end[0]){
                setProductPagesArray([...start, "...", ...end]);
            } else if(start[start.length-2] === end[0] || start[start.length-1] === end[0] ){
                setProductPagesArray([...Array.from(Array(productsTotalPage+1).keys()).splice(1).slice(productsCurrentPage-2, productsCurrentPage), "...", ...end]);
            } else {
                setProductPagesArray([1, "...", ...end]);
            }
        }

        // getProductsFromPage(productsCurrentPage);

        change(productsCurrentPage);
    },[productsCurrentPage])

    return(
        <ul className="sidebar-pagination">
            <li>
                <a href="/#" onClick={(e)=>{
                    e.preventDefault();
                    setProductPaginationPage('prev');
                }}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.0457 1.83007C12.6209 1.38757 11.9368 1.38757 11.5192 1.83007L6.16245 7.41L4.63597 9.00007L6.16245 10.5902L11.5192 16.1701C11.944 16.6126 12.6281 16.6126 13.0457 16.1701C13.4705 15.7276 13.4705 15.015 13.0457 14.58L7.68895 9.00007L13.0457 3.42015C13.4705 2.97765 13.4705 2.26507 13.0457 1.83007Z" fill="#506D8B"/>
                    </svg>
                </a>
            </li>
            {
                productPagesArray.map(item =>{
                    return <li key={ item }>
                        <a 
                            href="/#" className={`${productsCurrentPage === item ? 'active' : ''} ${item === '...' ? 'dots' : ''}`}
                            onClick={(e)=>{
                                e.preventDefault();
                                setProductsCurrentPage(item);
                            }}
                        >
                            {item}
                        </a>
                    </li>
                })
            }
            <li>
                <a href="/#" onClick={(e)=>{
                    e.preventDefault();
                    setProductPaginationPage('next');
                }}>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.95432 16.1699C5.37912 16.6124 6.06319 16.6124 6.48079 16.1699L11.8375 10.59L13.364 8.99992L11.8375 7.40983L6.48079 1.82987C6.05599 1.38737 5.37192 1.38736 4.95432 1.82987C4.52952 2.27237 4.52952 2.98495 4.95432 3.41995L10.311 8.99992L4.95432 14.5798C4.52952 15.0223 4.52952 15.7349 4.95432 16.1699Z" fill="#506D8B"/>
                    </svg>
                </a>
            </li>
        </ul>
    )

}
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	currentUser: {
		"username": "",
		"firstname": "",
		"lastname": "",
		"notifications": "",
		"role": "",
		"title": ""
	},
}

export const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		setCurrentUser: (state, action) => {
			state.currentUser = action.payload;
		},
	},
})

export const { setCurrentUser } = usersSlice.actions

export default usersSlice.reducer
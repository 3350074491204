import { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation  } from "react-router-dom";
import { useDispatch, useSelector, } from "react-redux";
import { openModalAction } from "../../../redux/dashboard/dashboard-slice";
import SelectBoxComponent from '../../../components/StartPagesComponents/SelectBoxComponent';
import API from "../../../api";

export const Sidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const openModal = () => {
        dispatch(openModalAction("UnlockPremiumFeatures"));
    }
    // const options = [
    //     { value: 'Cannabis Consumer', label: 'Cannabis Consumer' },
    //     { value: 'Retail Dispensary', label: 'Retail Dispensary' },
    //     { value: 'Licensed Producer', label: 'Licensed Producer' },
    //     { value: 'Data Lover', label: 'Data Lover' },
    // ]
    const options = [
        { value: '/settings/personal-profile', label: 'Personal Profile' },
        { value: '/settings/email-preferences', label: 'Email Preferences' },
        { value: '/settings/help-desk', label: 'Help Desk' },
        { value: 'Notifications', label: 'Notifications', isPremium: true },
        { value: 'Reports', label: 'Reports', isPremium: true },
        { value: 'Users', label: 'Users', isPremium: true },
        { value: '/settings/activation', label: 'Activation' },
        { value: '', label: 'LogOut', logOut: true },
    ]
    // let [activeLink, setActiveLink] = useState(options[0].value)

    const changeRoute = (value)=>{
        setTimeout(()=>{
            navigate(value)       
        }, 5)
    }
    const changeNavSelectBoxHandler = async(event) =>{
        if(event.isPremium){
            openModal();
        }else{
            changeRoute(event.value)
        }

        if(event.logOut){
            await logout();
        }
    }
    // accessLogin	{"access":"true","email":"nogopaw714@meidecn.com"}
    
    let activeLink = location.pathname
    let navSelectBoxValue = options.find(item=>item.value===activeLink)
    if(!navSelectBoxValue){
        changeRoute(options[0].value)
        navSelectBoxValue=options[0]
    }

    const logout = async() => {
        await API.logout();
    }

    return(
        <div className="settings-sidebar">
            <div className="settings-sidebar-header">Settings</div>
            <div className="settings-sidebar-nav-selectbox_wrapper">
                <SelectBoxComponent options={options} value={navSelectBoxValue} onChange={changeNavSelectBoxHandler}/>
            </div>
            <ul className="settings-sidebar-nav"> 
                <li>
                    <NavLink to="/settings/personal-profile">Personal Profile</NavLink>
                </li>
                <li>
                    <NavLink to="/settings/email-preferences">Email Preferences</NavLink>
                </li>
                {/* <li>
                    <NavLink to="/settings/help-desk">Help Desk</NavLink>
                </li> */}
                <li>
                    <a href="/neobi/#" className="disabled" onClick={(e)=>{
                        e.preventDefault();
                        openModal();
                    }}>
                        <div className="icon-wrpr">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.42969 22.61C5.00969 22.61 4.64986 22.46 4.35986 22.17C4.06986 21.88 3.91992 21.52 3.91992 21.1V10.13C3.91992 9.71 4.06986 9.35006 4.35986 9.06006C4.64986 8.77006 5.00969 8.62 5.42969 8.62H7.19971V6.18994C7.19971 4.85994 7.66961 3.72004 8.59961 2.79004C9.53961 1.85004 10.67 1.39001 12 1.39001C13.33 1.39001 14.4699 1.86004 15.3999 2.79004C16.3399 3.73004 16.7998 4.85994 16.7998 6.18994V8.62H18.5698C18.9898 8.62 19.3496 8.77006 19.6396 9.06006C19.9296 9.35006 20.0801 9.71 20.0801 10.13V21.1C20.0801 21.52 19.9296 21.88 19.6396 22.17C19.3496 22.46 18.9898 22.61 18.5698 22.61H5.41992H5.42969ZM5.42969 21.09H18.5801V10.12H5.42969V21.09ZM12 17.55C12.54 17.55 12.9999 17.36 13.3799 16.99C13.7599 16.62 13.9497 16.17 13.9497 15.65C13.9497 15.13 13.7599 14.69 13.3799 14.27C12.9999 13.85 12.54 13.65 12 13.65C11.46 13.65 10.9996 13.86 10.6196 14.27C10.2396 14.68 10.0498 15.14 10.0498 15.65C10.0498 16.16 10.2396 16.62 10.6196 16.99C10.9996 17.36 11.46 17.55 12 17.55ZM8.70996 8.59998H15.2798V6.17004C15.2798 5.26004 14.9598 4.47997 14.3198 3.83997C13.6798 3.19997 12.8997 2.88 11.9897 2.88C11.0797 2.88 10.2997 3.19997 9.65967 3.83997C9.01967 4.47997 8.69971 5.26004 8.69971 6.17004V8.59998H8.70996Z" fill="#324457"/>
                            </svg>
                        </div> Notifications
                    </a>
                </li>
                <li>
                    <a href="/neobi/#" className="disabled" onClick={(e)=>{
                        e.preventDefault();
                        openModal();
                    }}>
                        <div className="icon-wrpr">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.42969 22.61C5.00969 22.61 4.64986 22.46 4.35986 22.17C4.06986 21.88 3.91992 21.52 3.91992 21.1V10.13C3.91992 9.71 4.06986 9.35006 4.35986 9.06006C4.64986 8.77006 5.00969 8.62 5.42969 8.62H7.19971V6.18994C7.19971 4.85994 7.66961 3.72004 8.59961 2.79004C9.53961 1.85004 10.67 1.39001 12 1.39001C13.33 1.39001 14.4699 1.86004 15.3999 2.79004C16.3399 3.73004 16.7998 4.85994 16.7998 6.18994V8.62H18.5698C18.9898 8.62 19.3496 8.77006 19.6396 9.06006C19.9296 9.35006 20.0801 9.71 20.0801 10.13V21.1C20.0801 21.52 19.9296 21.88 19.6396 22.17C19.3496 22.46 18.9898 22.61 18.5698 22.61H5.41992H5.42969ZM5.42969 21.09H18.5801V10.12H5.42969V21.09ZM12 17.55C12.54 17.55 12.9999 17.36 13.3799 16.99C13.7599 16.62 13.9497 16.17 13.9497 15.65C13.9497 15.13 13.7599 14.69 13.3799 14.27C12.9999 13.85 12.54 13.65 12 13.65C11.46 13.65 10.9996 13.86 10.6196 14.27C10.2396 14.68 10.0498 15.14 10.0498 15.65C10.0498 16.16 10.2396 16.62 10.6196 16.99C10.9996 17.36 11.46 17.55 12 17.55ZM8.70996 8.59998H15.2798V6.17004C15.2798 5.26004 14.9598 4.47997 14.3198 3.83997C13.6798 3.19997 12.8997 2.88 11.9897 2.88C11.0797 2.88 10.2997 3.19997 9.65967 3.83997C9.01967 4.47997 8.69971 5.26004 8.69971 6.17004V8.59998H8.70996Z" fill="#324457"/>
                            </svg>
                        </div> Reports
                    </a>
                </li>
                <li>
                    <a href="/neobi/#" className="disabled" onClick={(e)=>{
                        e.preventDefault();
                        openModal();
                    }}>
                        <div className="icon-wrpr">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.42969 22.61C5.00969 22.61 4.64986 22.46 4.35986 22.17C4.06986 21.88 3.91992 21.52 3.91992 21.1V10.13C3.91992 9.71 4.06986 9.35006 4.35986 9.06006C4.64986 8.77006 5.00969 8.62 5.42969 8.62H7.19971V6.18994C7.19971 4.85994 7.66961 3.72004 8.59961 2.79004C9.53961 1.85004 10.67 1.39001 12 1.39001C13.33 1.39001 14.4699 1.86004 15.3999 2.79004C16.3399 3.73004 16.7998 4.85994 16.7998 6.18994V8.62H18.5698C18.9898 8.62 19.3496 8.77006 19.6396 9.06006C19.9296 9.35006 20.0801 9.71 20.0801 10.13V21.1C20.0801 21.52 19.9296 21.88 19.6396 22.17C19.3496 22.46 18.9898 22.61 18.5698 22.61H5.41992H5.42969ZM5.42969 21.09H18.5801V10.12H5.42969V21.09ZM12 17.55C12.54 17.55 12.9999 17.36 13.3799 16.99C13.7599 16.62 13.9497 16.17 13.9497 15.65C13.9497 15.13 13.7599 14.69 13.3799 14.27C12.9999 13.85 12.54 13.65 12 13.65C11.46 13.65 10.9996 13.86 10.6196 14.27C10.2396 14.68 10.0498 15.14 10.0498 15.65C10.0498 16.16 10.2396 16.62 10.6196 16.99C10.9996 17.36 11.46 17.55 12 17.55ZM8.70996 8.59998H15.2798V6.17004C15.2798 5.26004 14.9598 4.47997 14.3198 3.83997C13.6798 3.19997 12.8997 2.88 11.9897 2.88C11.0797 2.88 10.2997 3.19997 9.65967 3.83997C9.01967 4.47997 8.69971 5.26004 8.69971 6.17004V8.59998H8.70996Z" fill="#324457"/>
                            </svg>
                        </div> Users
                    </a>
                </li>
                <li>
                    <NavLink to="/settings/activation">Activation</NavLink>
                </li>
                <li>
                    <a href="/#" onClick={(e) => { e.preventDefault(); logout(); }} title="LogOut">
                        LogOut
                    </a>
                </li>
            </ul>
        </div>
    )
}
import { useEffect } from "react";
import NeuroWorldImage from "../../assets/images/neuro-world-image-bg.jpg"

// https://stackoverflow.com/questions/37950024/add-dots-to-vertices-in-three-js
export const DashboardMainScreen = () => {
    const mobileWidth = window.innerWidth < 992;

    const renderAnimation = () => {
        let THREE = window.THREE;
        // Extra geometry
        THREE.IcosahedronGeometry = function(radius, detail) {
            var t = (1 + Math.sqrt(5)) / 2;
            var vertices = [-1, t, 0, 1, t, 0, -1, -t, 0, 1, -t, 0,
                0, -1, t, 0, 1, t, 0, -1, -t, 0, 1, -t,
                t, 0, -1, t, 0, 1, -t, 0, -1, -t, 0, 1
            ];
            var indices = [
                0, 11, 5, 0, 5, 1, 0, 1, 7, 0, 7, 10, 0, 10, 11,
                1, 5, 9, 5, 11, 4, 11, 10, 2, 10, 7, 6, 7, 1, 8,
                3, 9, 4, 3, 4, 2, 3, 2, 6, 3, 6, 8, 3, 8, 9,
                4, 9, 5, 2, 4, 11, 6, 2, 10, 8, 6, 7, 9, 8, 1
            ];
            THREE.PolyhedronGeometry.call(this, vertices, indices, radius, detail);
            this.type = 'IcosahedronGeometry';
            this.parameters = {
                radius: radius,
                detail: detail
            };
        };
        
        THREE.IcosahedronGeometry.prototype = Object.create(THREE.PolyhedronGeometry.prototype);
        THREE.IcosahedronGeometry.prototype.constructor = THREE.IcosahedronGeometry;
        
        // Scene
        var scene = new THREE.Scene();
        var camera = new THREE.PerspectiveCamera(75, 350 / 350, 0.1, 1000);
        
        var renderer = new THREE.WebGLRenderer({
            alpha: true,
            antialias: 1
        });
        
        // renderer.setClearColor('#07161C');
        renderer.setSize(350, 350);
        // document.body.appendChild(renderer.domElement);
        document.querySelector('.main-neuro-world-image').appendChild(renderer.domElement);
        
        scene.fog = new THREE.Fog('#324457', 8, 20);
        
        // Create vertex points
        var mesh = new THREE.IcosahedronGeometry(10, 1); // radius, detail
        var vertices = mesh.vertices;
        var positions = new Float32Array(vertices.length * 3);
        for (var i = 0, l = vertices.length; i < l; i++) {
            vertices[i].toArray(positions, i * 3);
        }
        
        var geometry = new THREE.BufferGeometry();

        geometry.addAttribute('position', new THREE.BufferAttribute(positions, 3));


        
        var material = new THREE.PointsMaterial({
            size: 0.4,
            vertexColors: false,
            color: '#00E0D1'
        });
        var points = new THREE.Points(geometry, material);
        
        var object = new THREE.Object3D();
        
        object.add(points);
        
        
        
        object.add(new THREE.Mesh(
            mesh,
            new THREE.MeshPhongMaterial({
                color: 0x616161,
                emissive: '#324457',
                wireframe: true,
                fog: 1
            })
        
        ));
        
        scene.add(object);
        
        camera.position.z = 20;
        
        var render = function() {
            requestAnimationFrame(render);
        
            object.rotation.x += 0.01;
            object.rotation.y += 0.01;
        
            renderer.render(scene, camera);
        };

        
        render();
    }

    const parallax = function(event) {
        this.querySelectorAll(".neuro-world-image-bg").forEach((shift) => {
            const position = shift.getAttribute("value");
            const x = (window.innerWidth - event.pageX * position) / 90;
            const y = (window.innerHeight - event.pageY * position) / 90;

            shift.style.transform = `translateX(${x}px) translateY(${y}px)`;
        });
    }

    useEffect(()=>{
        if(!mobileWidth){
            renderAnimation()
            document.addEventListener("mousemove", parallax);
        }
    },[]);

    return(
        <div className="dashboard-content align-items-center dashboard-main-screen relative">
            { !mobileWidth ?
                <img src={NeuroWorldImage} alt="" className="neuro-world-image-bg" value="5" /> :
                null
            }
            <div className="main-neuro-world-image"></div>
        </div>
    )

}
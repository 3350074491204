import React from 'react';
import { ReactComponent as Logo } from "../../assets/images/Logo.svg";
import { Link } from 'react-router-dom';
import { ReactComponent as BetaLogo } from "../../assets/images/BetaLogo.svg";

const HomeHeaderComponent = () => {
    return (
        <header className='home-header'>
            <div className='img-container'>
                {/* <Logo style={{width: '67%', height: '67%' }}/> */}
                <BetaLogo style={{ maxWidth: 'calc(100px * var(--scale))', width: '100%' }} />
            </div>
            <div>
                <div className='sign-buttons'>
                    <Link className='signin-link' to={"/sign-in"}>Sign In</Link>
                    <Link className='signup-link' to={"/sign-up"}>Join</Link>
                </div>
                <div className='page-menu'>
                    <Link className='page-menu_item' to="https://neobi.io/">Home</Link>
                    <Link className='page-menu_item' to="https://neobi.io/">What we do</Link>
                    <Link className='page-menu_item' to="https://neobi.io/">Solution</Link>
                    <Link className='page-menu_item' to="https://neobi.io/">Products</Link>
                    <Link className='page-menu_item' to="https://neobi.io/">Contact</Link>
                </div>
            </div>
        </header>
    )
}

export default HomeHeaderComponent
import {Layout} from "../layout/Layout";
import {Dashboard} from "./Dashboard/Dashboard";
import {DashboardMainScreen} from "./Dashboard/DashboardMainScreen";
import { Products } from "./Dashboard/Products";
import { Stores } from "./Dashboard/Stores";
import {Settings} from "./Settings/Settings";
import {Activation} from "./Settings/Activation";
import {EmailPreferences} from "./Settings/EmailPreferences";
import {HelpDesk} from "./Settings/HelpDesk";
import {PersonalProfile} from "./Settings/PersonalProfile";
import SignIn from "./Sing-in/Signin";
import ForgotPassword from "./Sing-in/ForgotPassword";
import Signup from "./Sign-up/Signup";
import Verification from "./Sign-up/Verification";
import Home from "./Home/Home";
import HomeLogedIn from "./Home/HomeLogedIn";

export const Pages = {
    Layout,
    Dashboard,
    DashboardMainScreen,
    Products,
    Stores,
    Settings,
    SignIn,
    ForgotPassword,
    Signup,
    Verification,
    Home,
    Activation,
    EmailPreferences,
    HelpDesk,
    PersonalProfile,
    HomeLogedIn,
}
import {useEffect,useState} from "react";

export const SidebarAccordion = ({ title, children, initActive }) => {
    const [active, setActive] = useState(false);

    useEffect(() => {
        if(initActive) setActive(initActive)
    }, []);

    return(
        <div className={`sidebar-accordion ${active ? 'active' : ''}`}>
            <div className="sidebar-accordion-header" onClick={()=>{ setActive(!active) }}>
                <h4>{ title }</h4>
                <div className="arrow-icon">
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.44006 6.88098C1.85006 7.47098 1.85006 8.42109 2.44006 9.00109L9.88 16.441L12.0001 18.5612L14.1202 16.441L21.5602 9.00109C22.1502 8.41109 22.1502 7.46098 21.5602 6.88098C20.9702 6.29098 20.0201 6.29098 19.4401 6.88098L12.0001 14.3209L4.56018 6.88098C3.97018 6.29098 3.02006 6.29098 2.44006 6.88098Z" fill="#506D8B"/>
                    </svg>
                </div>
            </div>
            <div className="sidebar-accordion-body">
                { children }
            </div>
        </div>
    )

}
import React, { useState } from 'react'
import { Link, useNavigate , useLocation } from 'react-router-dom';
import InputComponent from '../../components/StartPagesComponents/InputComponent';
import SpacerСomponent from '../../components/StartPagesComponents/SpacerComponent';
import ButtonComponent from '../../components/StartPagesComponents/ButtonComponent';
import AuthLayoutComponent from '../../components/StartPagesComponents/AuthLayoutComponent';
import { ReactComponent as GoogleIco } from "../../assets/images/google-ico.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/arrow-right.svg";
import API from "../../api";

import { useDispatch } from "react-redux";
import { setCurrentUser } from "../../redux/users/users-slice";

import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useGoogleLogin } from '@react-oauth/google';
import Loader from '../../components/UI/Loader';

const SignIn = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    console.log(location , "location");

    // store pathname and concatinate search if its exist in a variable
    const { from } = location.state || { from: { pathname: "/home" } };

    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
    });
    const [googleLogined, setGoogleLogined] = useState(false);
    const [loading, setLoading] = useState(false);
    const login = async() => {
        setLoading(true);
        let result = await API.login(loginData.email, loginData.password);
        if(result){
            localStorage.setItem("accessLogin", JSON.stringify({ access: result, email: loginData.email }));

            let userData = await API.getUser(loginData.email);
            dispatch(setCurrentUser(userData));
            navigate( from , { replace: true });
        } else {
            NotificationManager.error('Error');
        }
        setLoading(false);
    }

    const googleLogin = useGoogleLogin({
        onSuccess: async(tokenResponse) => {
            let userInfoData = await fetch(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`, {
                method: 'GET',
                redirect: 'follow'
            }).then(response => response.json())
                .then(result => { return result })
                .catch(error => console.log('error', error));

            if(userInfoData?.email){
                let userData = await API.getUser(userInfoData.email);
                if(userData?.email || userData?.username){
                    setLoginData((prevState)=>{
                        return { ...prevState, email: userInfoData.email || userData?.username }
                    });
                    setGoogleLogined(true);
                } else {
                    navigate(`/sign-up?googleLogined=true&firstName=${userInfoData.given_name}&lastName=${userInfoData.family_name}&email=${userInfoData.email}`, { replace: true });
                }
            } else {
                return;
            }
        },
        onError: tokenResponse => console.log("onError:", tokenResponse),
    });

    return (
        <>
            <AuthLayoutComponent topLink={{text: "Sign Up", to: "/sign-up"}}>
                <h2 className='heading mb-10'>Sign-in</h2>
                <p className='subheading'>to continue to Neobi Dashboard</p>
                <SpacerСomponent />
                { !googleLogined ? <>
                    <InputComponent
                        label="Email" type="text" className="mb-16"
                        value={ loginData.email } onChange={(e) => {
                            setLoginData((prevState)=>{
                                return { ...prevState, email: e.target.value }
                            })
                        }}
                    />
                    <InputComponent
                        label="Password" type="password"
                        value={ loginData.password } onChange={(e) => {
                            setLoginData((prevState)=>{
                                return { ...prevState, password: e.target.value }
                            })
                        }}
                    />

                    <div className="forgot-password-link-wrpr">
                        <Link to="/forgot-password">Forgot Password?</Link>
                    </div>
                    
                    <SpacerСomponent name="or" />
                    <button className='google-signin_btn mb-30' onClick={(e)=>{
                        e.preventDefault();
                        googleLogin();
                    }}>
                        <span className='center-flex'><GoogleIco style={{width: '67%', height: '67%' }}/></span>
                        <span>Sign in with Google</span>
                        <span className='center-flex'><ArrowRight style={{width: '67%', height: '67%' }}/></span>
                    </button>
                    
                    <p className='agreement-paragpaph mb-30'>
                        By continuing, you agree to<br/>
                        Neobi’s <span><a href="https://neobistorage.blob.core.windows.net/pubdash/Terms%20of%20Use.pdf" target="_blank" className='link'>User Agreement</a></span> and <span><a href="https://neobistorage.blob.core.windows.net/pubdash/Terms%20of%20Use.pdf" target="_blank" className='link'>Privacy Policy</a></span>. 
                    </p>
                    <ButtonComponent className="mb-30" disabled={loading || !loginData.email | !loginData.password ? true : false} onClick={login} text={ loading ? <Loader style={{zoom : ".3"}} /> : "Continue" }/>
                </> : <>
                    <InputComponent
                        label="Password" type="password" className="mb-30"
                        value={ loginData.password } onChange={(e) => {
                            setLoginData((prevState)=>{
                                return { ...prevState, password: e.target.value }
                            })
                        }}
                    />
                    <ButtonComponent className="mb-30" onClick={login}  text={loading ? <Loader style={{zoom : ".3"}} /> : "Continue"}/>
                </> }

                
                <Link to={"/sign-up"} className="subheading hide-on-mob">Create Account</Link>

                <NotificationContainer/>
            </AuthLayoutComponent>
        </>
    )
}

export default SignIn;
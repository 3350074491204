import React from 'react'
import LeftSingImage from '../../assets/images/neuro-world-image.jpg'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from "../../assets/images/Logo-homepage.svg";
import {DashboardMainScreen} from "../../routes/Dashboard/DashboardMainScreen";

const AuthLayoutComponent = ({children, topLink}) => {
    return (
        <div className='sign-layout_container'>
                <div className='signIn-left_container'>
                    {/* <img style={{ maxHeight: "716px" }} src={LeftSingImage} alt="" /> */}
                    <DashboardMainScreen />
                </div>
                <div className='signIn-right_container'>
                    <p className='auth-top_link'>
                        { topLink ? <Link className='mobile-hide' to={topLink.to}>{topLink.text}</Link> : null }
                        <Link className='mobile-show' to={"/"}>
                            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17 34C26.3888 34 34 26.3888 34 17C34 7.61116 26.3888 0 17 0C7.61114 0 0 7.61116 0 17C0 26.3888 7.61114 34 17 34Z" fill="#07161C"/>
                                <path d="M11.4945 24.38C11.2084 24.38 10.9124 24.2715 10.6953 24.0446L9.94544 23.2947C9.72838 23.0777 9.60999 22.7915 9.60999 22.4955C9.60999 22.1995 9.72838 21.9035 9.94544 21.6963L14.6419 16.99L9.94544 12.2936C9.72838 12.0765 9.60999 11.7904 9.60999 11.4944C9.60999 11.1984 9.72838 10.9024 9.94544 10.6952L10.6854 9.95519C10.9025 9.73813 11.1985 9.61973 11.4846 9.61973C11.7707 9.61973 12.0667 9.72826 12.2838 9.95519L16.9901 14.6517L21.6965 9.94532C21.9135 9.72826 22.2095 9.60986 22.4956 9.60986C22.7818 9.60986 23.0778 9.7184 23.2948 9.94532L24.0447 10.6952C24.4887 11.1392 24.4887 11.8594 24.0447 12.2936L19.3384 16.9999L24.0447 21.7062C24.2618 21.9233 24.3802 22.2094 24.3802 22.5054C24.3802 22.8014 24.2618 23.0974 24.0447 23.3046L23.2948 24.0544C23.0778 24.2715 22.7818 24.3899 22.4956 24.3899C22.2095 24.3899 21.9135 24.2814 21.6965 24.0544L16.9901 19.3481L12.2838 24.0544C12.0667 24.2715 11.7707 24.3899 11.4846 24.3899L11.4945 24.38Z" fill="#BFD0D8"/>
                            </svg>
                        </Link>
                    </p>
                    <div className='signin-form_container'>
                        {children}
                    </div>
                    <div className='sign-footer_mobile'>
                        { topLink ? <Link className='footer-mobile_link' to={topLink.to}>{topLink.text}</Link> : null }
                        <Logo style={{height: "41px", width: "130px"}}/>
                    </div>
                </div>
        </div>
        )

}

export default AuthLayoutComponent
import React from 'react';
import { useNavigate } from 'react-router';
import ButtonComponent from '../../components/StartPagesComponents/ButtonComponent';
import HomeHeaderComponent from '../../components/StartPagesComponents/HomeHeaderComponent';
const Home = () => {
    const navigate = useNavigate();

    return (
        <>
            <HomeHeaderComponent />
            <div className='home-container'>
                <div className='home-main_content'>
                    <h1 className='maincontent-title'>CANADIAN CANNABIS<br className='mobile-hide'/> RETAIL DISTRIBUTION DATA;<br className='mobile-hide' /> <span className='text-highlighted'>BROAD, CENTRAL & FREE.</span></h1>
                    <div className='maicontent-separator'></div>
                    <p className='maincontent-subtitle mb-40'>Sign up today for free access to Canada's largest and most accurate source for product distribution data. <span className='text-highlighted'>LEARN MORE</span></p>
                    <div className='maincontent-btn_container'>
                        <ButtonComponent onClick={()=>{ navigate("/sign-up") }} text={"Sign up"}/>
                        <ButtonComponent type="not-filled" onClick={()=>{ navigate("/sign-in") }} className={"mobile-show"} text={"Log in"}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;

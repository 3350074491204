import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Header } from "../components/Header";
import { LeftNav } from "../components/LeftNav";
import { UnlockPremiumFeaturesModal } from "../components/modals/UnlockPremiumFeaturesModal"
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import API from "../api";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../redux/users/users-slice";

export const Layout = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const modalOpened = useSelector((state) => state.dashboard.modalOpened)
    const [openNav, toggleNav] = useState(false);

    const checkLoginAccess = async() => {
        const accessLogin = localStorage.getItem("accessLogin") ? JSON.parse(localStorage.getItem("accessLogin")) : null;
        if(!accessLogin || !accessLogin?.access || !accessLogin?.email){
            navigate("/sign-in", { replace: true , state: { from: location }});
        } else if(accessLogin?.email){
            let userData = await API.getUser(accessLogin.email);
            dispatch(setCurrentUser(userData));
        }
    }

    useEffect(()=>{
        checkLoginAccess();
        toggleNav(false);
    }, [location])

    return (
        <>
            <Header toggleNav={toggleNav} openNav={openNav} />
            <main className="main-container">
                <LeftNav openNav={openNav} />
                <Outlet />

                {
                    modalOpened === "UnlockPremiumFeatures" ?
                        <UnlockPremiumFeaturesModal />
                    : null
                }
            </main>
        </>
    )
}
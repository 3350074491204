import React from 'react';

function SpacerСomponent({ color, name }) {
    return (
        <div className='d-flex spacer-wrapper'>
            <div style={{ borderTop: `2px solid ${color || "#324457"}`, marginTop: '30px', marginBottom: '30px', width: "100%" }} />
            {name &&<> <p className='spacer-label'> {name} </p><div style={{ borderTop: `2px solid ${color || "#324457"}`, marginTop: '30px', marginBottom: '30px', width: "100%" }} /> </>}
        </div>
    );
}

export default SpacerСomponent;
import React from "react";
import {createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";

import { Pages } from "./index.js";

export const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/" element={<Pages.Home/>}/>
            <Route path="/home" element={<Pages.HomeLogedIn/>}/>
            <Route element={<Pages.Layout />}>
                <Route path="/dashboard" element={<Pages.Dashboard />}>
                    <Route path="/dashboard" element={<Pages.DashboardMainScreen />} />
                    <Route path="/dashboard/products" element={<Pages.Products />} />
                    <Route path="/dashboard/products/:id" element={<Pages.Products />} />
                    <Route path="/dashboard/stores" element={<Pages.Stores />} />
                    <Route path="/dashboard/stores/:storeId" element={<Pages.Stores />} />
                </Route>

                <Route path="/dashboard/fullscreen" element={<Pages.Dashboard fullscreen={true} />}>
                    <Route path="/dashboard/fullscreen" element={<Pages.DashboardMainScreen fullscreen={true} />} />
                    <Route path="/dashboard/fullscreen/products" element={<Pages.Products fullscreen={true} />} />
                    <Route path="/dashboard/fullscreen/products/:id" element={<Pages.Products fullscreen={true} />} />
                    <Route path="/dashboard/fullscreen/stores" element={<Pages.Stores fullscreen={true} />} />
                    <Route path="/dashboard/fullscreen/stores/:storeId" element={<Pages.Stores fullscreen={true} />} />
                </Route>

                <Route path="/settings" element={<Pages.Settings />}>
                    <Route path="/settings/personal-profile" element={<Pages.PersonalProfile />} />
                    <Route path="/settings/email-preferences" element={<Pages.EmailPreferences />} />
                    {/* <Route path="/settings/help-desk" element={<Pages.HelpDesk />} /> */}
                    <Route path="/settings/activation" element={<Pages.Activation />} />
                </Route>
            </Route>
            <Route path="/sign-in" element={<Pages.SignIn/>}/>
            <Route path="/sign-up" element={<Pages.Signup/>}/>
            <Route path="/verification" element={<Pages.Verification/>}/>
            <Route path="/forgot-password" element={<Pages.ForgotPassword/>}/>
            <Route path="/resetpassword" element={<Pages.ForgotPassword/>}/>
        </>
    ), { basename: "/" }
);
import React from 'react'

const SearchItemComponent = ({name, subText, ico, onClick}) => {
    return (
        <li className="search-item_block">
            <a className="" href="/neobi/#" onClick={(e)=>{
                e.preventDefault();
                onClick(e);
            }}>
                <div className="icon-wrpr">
                    {ico}
                </div>
                <div className="text-content">
                    <h4>{name}</h4>
                    { subText ? <div className="location">{subText}</div> : null }
                </div>
            </a>
        </li>
    )
}

export default SearchItemComponent
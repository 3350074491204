import { ReactComponent as IconClose } from "../../assets/images/close-icon.svg"
import NeobiAvatar from "../../assets/images/help-desk-neobi-avatar.png"
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";

export const HelpDesk = () => {
    const currentUser = useSelector((state) => state.users.currentUser);

    const [messages, setMessages] = useState([
        {
            id: '1',
            user_name: 'Neobi Support',
            avatar: NeobiAvatar,
            type: 'from',
            text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in',
        },
        {
            id: '2',
            user_name: 'User Name',
            avatar: null,
            type: 'to',
            text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in',
        },
    ]);
    const [writenMessage, setWritenMessage] = useState('');

    const sendMessage = () => {
        setMessages((prevState) => {
            return [...prevState, {
                id: messages.length+1,
                user_name: `${currentUser.firstname} ${currentUser.lastname}`,
                avatar: null,
                type: 'to',
                text: writenMessage,
            },]
        });

        setWritenMessage('');

        setTimeout(() => {
            var messagesBox = document.querySelector(".help-desk-body");
            messagesBox.scrollTop = messagesBox.scrollHeight; 
        }, 100);
    }

    const handleKeyPress = (target) => {
        if(target.ctrlKey && target.charCode === 13){
            sendMessage();
        }
    }

    return(
        <div className="settings-sidebar help-desk-sidebar">
            <div className="settings-sidebar-header">
                Help Desk
                <NavLink to="/dashboard" className="close-btn">
                    <IconClose />
                </NavLink>
            </div>
            <iframe src = "https://neobi.desk365.io/app/wf" title="Desk365 Web Form" style={{
                width:"100%",
                height:"100%",
                borderRadius:"4px",
                border: "1px solid #3F51B5"
            }}></iframe>

            {/* <div className="help-desk-body">
                {
                    messages.map((message, index)=>(
                        <div className={message.type === 'from' ? "messages-left" : "messages-right"} key={index}>
                            { index === 0 || (messages[index-1] && !((messages[index-1].user_name === message.user_name) && (messages[index-1].type === message.type))) ?
                            <div className="message-user-info">
                                <div className="avatar-wrpr">
                                    { message.avatar ? <img src={message.avatar} alt="" /> : null }
                                </div>
                                <h3>{ message.type === 'to' ? `${currentUser.firstname} ${currentUser.lastname}` : message.user_name }</h3>
                            </div> : null }
                            <div className="message-text mb-10">
                                { message.text }
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="help-desk-footer">
                <div className="input-wrapper mb-20">
                    <label className="input-label">Enter Message</label>
                    <div className="relative">
                        <textarea
                            name="" id="" className="input" placeholder="Please enter your message..."
                            value={writenMessage}
                            onChange={(e) => {
                                setWritenMessage(e.target.value);
                            }}
                            onKeyPress={handleKeyPress}
                        ></textarea>
                    </div>
                </div>
                <div className="d-flex justify-content-end send-btn-wrpr">
                    <a href="/neobi/#" className="btn green-btn" onClick={(e)=>{
                        e.preventDefault();
                        sendMessage();
                    }}>Send Message</a>
                </div>
            </div> */}
        </div>
    )
}
import {useEffect,useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { SidebarAccordion } from "../../UI/SidebarAccordion";
import { applyFilters } from "../../../redux/dashboard/dashboard-slice";
import SearchInput from "../../UI/SearchInput";

export const SidebarFilters = ({ mainFilter, setFiltersSidebarActive }) => {
    const dispatch = useDispatch();
    const [searchString, setSearchString] = useState('');

    const appliedFilters = useSelector((state) => state.dashboard.appliedFilters);
    const allAppliedFilters = [
        ...appliedFilters.categories.map(item => {
            return {
                name: "categories",
                filter: item,
            }
        }),
        ...appliedFilters.cities.map(item => {
            return {
                name: "cities",
                filter: item,
            }
        }),
        ...appliedFilters.provinces.map(item => {
            return {
                name: "provinces",
                filter: item,
            }
        })
    ];
    const mapDetails = useSelector((state) => state.map.mapDetails)

    const [localAppliedFilters, setLocalAppliedFilters] = useState({
        categories: [],
        cities: [],
        provinces: []
    });

    const changeSelectedFilter = (name, filter) => {
        let copiedFilters = {
            categories: [...localAppliedFilters.categories],
            cities: [...localAppliedFilters.cities],
            provinces: [...localAppliedFilters.provinces],
        };

        if(!copiedFilters[name].includes(filter)){
            copiedFilters[name].push(filter)
        } else {
            copiedFilters[name] = copiedFilters[name].filter(itemF => itemF !== filter);
        }

        setLocalAppliedFilters(copiedFilters)
        dispatch(applyFilters(copiedFilters));
    }

    const clearFilters = () => {
        dispatch(applyFilters({
            categories: [],
            cities: [],
            provinces: [],
        }));
    }

    useEffect(() => {
        setLocalAppliedFilters(appliedFilters);
    }, [appliedFilters]);

    return(
        <>
            <div className="sm-wrpr-box dashboard-sidebar-header d-flex align-items-center">
                <a
                    href="/neobi/#" className="sidebar-back-btn"
                    onClick={(e) => {
                        e.preventDefault();
                        setFiltersSidebarActive(false);
                    }}
                >
                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M33.348 11.6151C32.4786 10.795 31.0694 10.795 30.19 11.6151L19.1577 22.0215L16 25.0002L19.1577 27.9785L30.19 38.3849C31.0594 39.205 32.4686 39.205 33.348 38.3849C34.2173 37.5649 34.2173 36.2361 33.348 35.4066L22.3156 25.0002L33.348 14.5938C34.2173 13.7738 34.2173 12.4445 33.348 11.6151Z" fill="#BFD0D8"/>
                    </svg>
                </a>
                <h4 className="mx-auto">Refine Results</h4>
                <div className="amount_circle_result">{ allAppliedFilters.length }</div>
            </div>
            <div className="sm-wrpr-box dashboard-sidebar-header">
                <SearchInput placeholder="Search filters" value={searchString} onChange={setSearchString} />
            </div>


            <SidebarAccordion title="Provincial" initActive>
                <ul className="filter_checkboxes_list">
                    { mapDetails.provinces.filter(itemF => itemF.prov.toLowerCase().includes(searchString.toLowerCase())).map((item, index)=>(
                        <li key={index}>
                            <label>
                                <input
                                    type="checkbox" name="" id="" value={item.prov} 
                                    checked={localAppliedFilters.provinces.includes(item.prov)}
                                    onChange={(e) => {
                                        changeSelectedFilter('provinces', item.prov);
                                    }}
                                />
                                <span className="checkbox_circle"></span>
                                { item.prov }
                            </label>
                        </li>
                    )) }
                </ul>
            </SidebarAccordion>
            
            <SidebarAccordion title="City Filters">
                <ul className="filter_checkboxes_list">
                    { 
                        mapDetails.cities.filter(
                            itemF => (localAppliedFilters.provinces.length ? localAppliedFilters.provinces.includes(itemF.prov) : true) && itemF.city.toLowerCase().includes(searchString.toLowerCase())
                        ).map((item, index) => (
                        <li key={index}>
                            <label>
                                <input
                                    type="checkbox" name="" id="" value={item.city} 
                                    checked={localAppliedFilters.cities.includes(item.city)}
                                    onChange={(e) => {
                                        changeSelectedFilter('cities', item.city);
                                    }}
                                />
                                <span className="checkbox_circle"></span>
                                { item.city }, { item.prov }
                            </label>
                        </li>
                    )) }
                </ul>
            </SidebarAccordion>

            <SidebarAccordion title="Category Filter">
                <ul className="filter_checkboxes_list">
                    { mapDetails.categories
                        .filter(itemF => 
                            itemF.category.toLowerCase().includes(searchString.toLowerCase()) && 
                            itemF.category.toLowerCase() !== 'nan'
                        )
                        .map((item, index) => (
                            <li key={index}>
                                <label>
                                    <input
                                        type="checkbox" name="" id="" value={item.category} 
                                        checked={localAppliedFilters.categories.includes(item.category)}
                                        onChange={(e) => {
                                            changeSelectedFilter('categories', item.category);
                                        }}
                                    />
                                    <span className="checkbox_circle"></span>
                                    { item.category }
                                </label>
                            </li>
                        )) }
                </ul>
            </SidebarAccordion>

            <div className="px-10 pb-10 mt-20">
                <a
                    href="/neobi/#" className="btn green-rounded-btn"
                    onClick={(e) => {
                        e.preventDefault();
                        setFiltersSidebarActive(false);
                    }}
                >Apply Filters</a>
                <a
                    href="/neobi/#" className="btn green-rounded-btn grey-btn mt-20"
                    onClick={(e) => {
                        e.preventDefault();
                        clearFilters();
                    }}
                >Clear all Filters</a>
            </div>
        </>
    )

}
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { store } from './redux/store'
import { Provider } from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google';

import 'react-notifications/lib/notifications.css';
import "./assets/css/reset.css"
import "./assets/css/fonts/ProximaNova.css"
import "./assets/css/global.css"
import "./assets/css/components/components.css"
import "./assets/css/main-alpha.css"
import "./assets/css/main-bravo.css"
import "./assets/css/main-charlie.css"
import "./assets/css/media-alpha.css"
import "./assets/css/media-bravo.css"
import "./assets/css/media-charlie.css"
//testing rem
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId="869299138962-j1s4qm9c1u7nur56rk3od37aj66phuga.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </Provider>
);
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    alerts: {
        message: null,
        type: null,
    },
    loader: false,
    action: { status: null, item: null },
    apiResponse: [],
    apiProducts: [
            {
                "name": "Atlantic Cannabis - 200 Water Street",
                "p_id": "10783",
                "city": "St. John's",
                "province": "Newfoundland and Labrador",
                "address": "200 Water Street",
                "longtitude": "-52.707254",
                "latitude": "47.56419",
                "hours": "Open",
                "distance": "6146253.713586666",
                "stockStatus": "In Stock"
            },
            {
                "name": "Atlantic Cannabis - 355 Main Road",
                "p_id": "10778",
                "city": "St. John's",
                "province": "Newfoundland and Labrador",
                "address": "355 Main Road",
                "longtitude": "-52.7704067",
                "latitude": "47.4633937",
                "hours": "Open",
                "distance": "6156706.46243088",
                "stockStatus": "In Stock"
            },
            {
                "name": "Atlantic Cannabis - 79 Broadway",
                "p_id": "10786",
                "city": "Corner Brook",
                "province": "Newfoundland and Labrador",
                "address": "79 Broadway",
                "longtitude": "-57.95574",
                "latitude": "48.9521606",
                "hours": "Open",
                "distance": "6363206.678689949",
                "stockStatus": "In Stock"
            },
            {
                "name": "Cannabis NB - 3524 rue Principale",
                "p_id": "12065",
                "city": "Tracadie",
                "province": "New Brunswick",
                "address": "3524 rue Principale",
                "longtitude": "-64.9108098",
                "latitude": "47.5162588",
                "hours": "Open",
                "distance": "6860153.120994331",
                "stockStatus": "Out of Stock"
            },
            {
                "name": "Cannabis NB - 640 Street",
                "p_id": "12046",
                "city": "Bathurst",
                "province": "New Brunswick",
                "address": "640 Street",
                "longtitude": "-65.6656728",
                "latitude": "47.6311533",
                "hours": "Open",
                "distance": "6893499.645448308",
                "stockStatus": "Out of Stock"
            },
            {
                "name": "Cannabis NB - 157 Water Street",
                "p_id": "12047",
                "city": "Campbellton",
                "province": "New Brunswick",
                "address": "157 Water Street",
                "longtitude": "-66.676179",
                "latitude": "48.0074554",
                "hours": "Open",
                "distance": "6920758.526181116",
                "stockStatus": "Out of Stock"
            },
            {
                "name": "Bluebird Cannabis - 507 Saint Lawrence Street",
                "p_id": "10993",
                "city": "Winchester",
                "province": "Ontario",
                "address": "507 Saint Lawrence Street",
                "longtitude": "-75.35334639999999",
                "latitude": "45.0926735",
                "hours": "Open",
                "distance": "7616828.516317951",
                "stockStatus": "In Stock"
            },
            {
                "name": "Cannaverse - 3098 Carling Avenue",
                "p_id": "13322",
                "city": "Ottawa",
                "province": "Ontario",
                "address": "3098 Carling Avenue",
                "longtitude": "-75.8060833",
                "latitude": "45.3541125",
                "hours": "Open",
                "distance": "7618851.681841287",
                "stockStatus": "In Stock"
            },
            {
                "name": "Dream Cannabis - 250 Greenbank Road",
                "p_id": "13558",
                "city": "Nepean",
                "province": "Ontario",
                "address": "250 Greenbank Road",
                "longtitude": "-75.779842",
                "latitude": "45.324711",
                "hours": "Open",
                "distance": "7619921.871006291",
                "stockStatus": "In Stock"
            },
            {
                "name": "Blue Haven Cannabis - 990 River Rd",
                "p_id": "13943",
                "city": "Manotick",
                "province": "Ontario",
                "address": "990 River Rd",
                "longtitude": "-75.67744339263986",
                "latitude": "45.234093536155555",
                "hours": "Open",
                "distance": "7622089.77962933",
                "stockStatus": "In Stock"
            },
            {
                "name": "Tokyo Smoke - 80 Marketplace Avenue",
                "p_id": "11853",
                "city": "Ottawa",
                "province": "Ontario",
                "address": "80 Marketplace Avenue",
                "longtitude": "-75.74237680000002",
                "latitude": "45.27038049999999",
                "hours": "Open",
                "distance": "7622471.833731075",
                "stockStatus": "In Stock"
            },
            {
                "name": "Shiny Bud - 12467 County Road 2",
                "p_id": "13824",
                "city": "Morrisburg",
                "province": "Ontario",
                "address": "12467 County Road 2",
                "longtitude": "-75.1765277374656",
                "latitude": "44.904576568890384",
                "hours": "Open",
                "distance": "7623079.464952772",
                "stockStatus": "In Stock"
            },
            {
                "name": "Natural Mystic Cannabis & CBD - 5556 Manotick Main Street",
                "p_id": "12732",
                "city": "Ottawa",
                "province": "Ontario",
                "address": "5556 Manotick Main Street",
                "longtitude": "-75.6835864",
                "latitude": "45.2241607",
                "hours": "Open",
                "distance": "7623233.613140099",
                "stockStatus": "In Stock"
            },
            {
                "name": "Spiritleaf - 2150 Roberts Road",
                "p_id": "11690",
                "city": "Ottawa",
                "province": "Ontario",
                "address": "2150 Roberts Road",
                "longtitude": "-75.8374097",
                "latitude": "45.31997519999999",
                "hours": "Open",
                "distance": "7623313.664472112",
                "stockStatus": "In Stock"
            },
            {
                "name": "The Oz Store - 147 Main Street",
                "p_id": "13769",
                "city": "Morrisburg",
                "province": "Ontario",
                "address": "147 Main Street",
                "longtitude": "-75.1822756545665",
                "latitude": "44.901119198194586",
                "hours": "Open",
                "distance": "7623668.068284179",
                "stockStatus": "In Stock"
            },
            {
                "name": "Go Green Cannabis - 3812 Greenbank Road",
                "p_id": "11397",
                "city": "Ottawa",
                "province": "Ontario",
                "address": "3812 Greenbank Road",
                "longtitude": "-75.73016",
                "latitude": "45.24263999999999",
                "hours": "Open",
                "distance": "7624133.672259212",
                "stockStatus": "In Stock"
            },
            {
                "name": "Canna Cabana - 836 March Road",
                "p_id": "12935",
                "city": "Kanata",
                "province": "Ontario",
                "address": "836 March Road",
                "longtitude": "-75.93338813085786",
                "latitude": "45.357470880419214",
                "hours": "Open",
                "distance": "7625198.652265456",
                "stockStatus": "In Stock"
            },
            {
                "name": "Unplug Cannabis - 4055 Carling Avenue",
                "p_id": "13914",
                "city": "Kanata",
                "province": "Ontario",
                "address": "4055 Carling Avenue",
                "longtitude": "-75.9075128",
                "latitude": "45.338568",
                "hours": "Open",
                "distance": "7625420.990505274",
                "stockStatus": "In Stock"
            },
            {
                "name": "Athena Cannabis - 782 Eagleson Rd",
                "p_id": "13263",
                "city": "Ottawa",
                "province": "Ontario",
                "address": "782 Eagleson Rd",
                "longtitude": "-75.8672884",
                "latitude": "45.2815213",
                "hours": "Open",
                "distance": "7628059.634061853",
                "stockStatus": "In Stock"
            },
            {
                "name": "Tokyo Smoke - 300 Earl Grey Drive",
                "p_id": "11854",
                "city": "Ottawa",
                "province": "Ontario",
                "address": "300 Earl Grey Drive",
                "longtitude": "-75.9158296",
                "latitude": "45.311713",
                "hours": "Open",
                "distance": "7628082.161993515",
                "stockStatus": "In Stock"
            },
            {
                "name": "Bluebird Cannabis - 499 Terry Fox Drive",
                "p_id": "11055",
                "city": "Ottawa",
                "province": "Ontario",
                "address": "499 Terry Fox Drive",
                "longtitude": "-75.91996859999999",
                "latitude": "45.3108116",
                "hours": "Open",
                "distance": "7628372.394984582",
                "stockStatus": "In Stock"
            },
            {
                "name": "ROLL’D Cannabis - 500 Hazeldean Road",
                "p_id": "13591",
                "city": "Kanata",
                "province": "Ontario",
                "address": "500 Hazeldean Road",
                "longtitude": "-75.89371570627384",
                "latitude": "45.293422622360055",
                "hours": "Open",
                "distance": "7628448.619356478",
                "stockStatus": "In Stock"
            },
            {
                "name": "Sessions - 425 Algonquin Boulevard",
                "p_id": "11245",
                "city": "Timmins",
                "province": "Ontario",
                "address": "425 Algonquin Boulevard",
                "longtitude": "-81.322965",
                "latitude": "48.4755785",
                "hours": "Open",
                "distance": "7629591.680314346",
                "stockStatus": "In Stock"
            },
            {
                "name": "Casabliss Cannabis - 82 3rd Road",
                "p_id": "11194",
                "city": "Timmins",
                "province": "Ontario",
                "address": "82 3rd Road",
                "longtitude": "-81.33314159999999",
                "latitude": "48.4750759",
                "hours": "Open",
                "distance": "7630113.69817631",
                "stockStatus": "In Stock"
            },
            {
                "name": "Pop's Cannabis - 3303 Highway 65",
                "p_id": "11542",
                "city": "Temiskaming Shores",
                "province": "Ontario",
                "address": "3303 Highway 65",
                "longtitude": "-79.67224639999999",
                "latitude": "47.5242628",
                "hours": "Open",
                "distance": "7631634.183450402",
                "stockStatus": "In Stock"
            },
            {
                "name": "Spiritleaf - 1250 Street",
                "p_id": "11692",
                "city": "Ottawa",
                "province": "Ontario",
                "address": "1250 Street",
                "longtitude": "-75.9311153",
                "latitude": "45.2683251",
                "hours": "Open",
                "distance": "7632478.966808312",
                "stockStatus": "In Stock"
            },
            {
                "name": "T Cannabis - 9 Armstrong Street",
                "p_id": "11363",
                "city": "Temiskaming Shores",
                "province": "Ontario",
                "address": "9 Armstrong Street",
                "longtitude": "-79.67109529999999",
                "latitude": "47.5087728",
                "hours": "Open",
                "distance": "7632891.079604168",
                "stockStatus": "In Stock"
            },
            {
                "name": "Cannabis Jacks - 1869 Riverside Drive",
                "p_id": "10676",
                "city": "Timmins",
                "province": "Ontario",
                "address": "1869 Riverside Drive",
                "longtitude": "-81.3929551",
                "latitude": "48.4720127",
                "hours": "Open",
                "distance": "7633190.234554913",
                "stockStatus": "In Stock"
            },
            {
                "name": "Canna Cabana - 58 Cree Road",
                "p_id": "12949",
                "city": "Thompson",
                "province": "Manitoba",
                "address": "58 Cree Road",
                "longtitude": "-97.85927038988991",
                "latitude": "55.744164176975495",
                "hours": "Open",
                "distance": "7634155.933922029",
                "stockStatus": "Out of Stock"
            },
            {
                "name": "King of Queens - 462 Fergus Avenue",
                "p_id": "10837",
                "city": "Temiskaming Shores",
                "province": "Ontario",
                "address": "462 Fergus Avenue",
                "longtitude": "-79.63336149999999",
                "latitude": "47.4486488",
                "hours": "Open",
                "distance": "7636151.1349658705",
                "stockStatus": "In Stock"
            },
            {
                "name": "Spiritleaf - 304 Colnade Drive",
                "p_id": "11681",
                "city": "Kemptville",
                "province": "Ontario",
                "address": "304 Colnade Drive",
                "longtitude": "-75.63211849999999",
                "latitude": "45.0296388",
                "hours": "Open",
                "distance": "7636663.344435463",
                "stockStatus": "In Stock"
            },
            {
                "name": "Dream Cannabis - 3000 Wellington Road",
                "p_id": "13559",
                "city": "Kemptville",
                "province": "Ontario",
                "address": "3000 Wellington Road",
                "longtitude": "-75.64456",
                "latitude": "45.01565",
                "hours": "Open",
                "distance": "7638474.715195909",
                "stockStatus": "In Stock"
            },
            {
                "name": "Shiny Bud - 2600 County Road",
                "p_id": "11238",
                "city": "Kemptville",
                "province": "Ontario",
                "address": "2600 County Road",
                "longtitude": "-75.6565067",
                "latitude": "45.018657",
                "hours": "Open",
                "distance": "7638851.142830524",
                "stockStatus": "In Stock"
            },
            {
                "name": "Arnprior Cannabis - 8 Elgin street west",
                "p_id": "13267",
                "city": "Arnprior",
                "province": "Ontario",
                "address": "8 Elgin street west",
                "longtitude": "-76.353684",
                "latitude": "45.43287",
                "hours": "Open",
                "distance": "7640736.582343956",
                "stockStatus": "In Stock"
            },
            {
                "name": "The Olde Barber Cannabis Co. - 33 Silver Street",
                "p_id": "13997",
                "city": "Cobalt",
                "province": "Ontario",
                "address": "33 Silver Street",
                "longtitude": "-79.68551870190068",
                "latitude": "47.39587240721495",
                "hours": "Open",
                "distance": "7643164.4780640295",
                "stockStatus": "In Stock"
            },
            {
                "name": "Deep River Cannabis - 33155 Highway 17",
                "p_id": "13272",
                "city": "Deep River",
                "province": "Ontario",
                "address": "33155 Highway 17",
                "longtitude": "-77.493551",
                "latitude": "46.0972261",
                "hours": "Open",
                "distance": "7643662.890552937",
                "stockStatus": "In Stock"
            },
            {
                "name": "OVCS - 1035 Pembroke Street",
                "p_id": "11039",
                "city": "Pembroke",
                "province": "Ontario",
                "address": "1035 Pembroke Street",
                "longtitude": "-77.0839061",
                "latitude": "45.8236144",
                "hours": "Open",
                "distance": "7645700.086054585",
                "stockStatus": "In Stock"
            },
            {
                "name": "Bluebird Cannabis - 2545 County Road",
                "p_id": "11385",
                "city": "Pakenham",
                "province": "Ontario",
                "address": "2545 County Road",
                "longtitude": "-76.2907901",
                "latitude": "45.3328687",
                "hours": "Open",
                "distance": "7645801.754683211",
                "stockStatus": "In Stock"
            },
            {
                "name": "Valley Cannabis - 820 Pembroke Street",
                "p_id": "11383",
                "city": "Pembroke",
                "province": "Ontario",
                "address": "820 Pembroke Street",
                "longtitude": "-77.0923923",
                "latitude": "45.8258433",
                "hours": "Open",
                "distance": "7645947.484673861",
                "stockStatus": "In Stock"
            },
            {
                "name": "Kushys - 100 Pembroke Street",
                "p_id": "13713",
                "city": "Pembroke",
                "province": "Ontario",
                "address": "100 Pembroke Street",
                "longtitude": "-77.110911",
                "latitude": "45.826528",
                "hours": "Open",
                "distance": "7646836.513712985",
                "stockStatus": "In Stock"
            },
            {
                "name": "Collective Growers - 79 Pembroke Street",
                "p_id": "10852",
                "city": "Pembroke",
                "province": "Ontario",
                "address": "79 Pembroke Street",
                "longtitude": "-77.1146487",
                "latitude": "45.8260166",
                "hours": "Open",
                "distance": "7647070.231220639",
                "stockStatus": "In Stock"
            },
            {
                "name": "Pop's Cannabis - 401 Ottawa Street",
                "p_id": "10843",
                "city": "Mississippi Mills",
                "province": "Ontario",
                "address": "401 Ottawa Street",
                "longtitude": "-76.1820982",
                "latitude": "45.23403889999999",
                "hours": "Open",
                "distance": "7648383.257221391",
                "stockStatus": "In Stock"
            },
            {
                "name": "Kushys - 3025 Petawawa Boulevard",
                "p_id": "13714",
                "city": "Petawawa",
                "province": "Ontario",
                "address": "3025 Petawawa Boulevard",
                "longtitude": "-77.26122967202691",
                "latitude": "45.88857673614497",
                "hours": "Open",
                "distance": "7649318.270149871",
                "stockStatus": "In Stock"
            },
            {
                "name": "Petawawa Cannabis Store - 3241 Petawawa Boulevard",
                "p_id": "13772",
                "city": "Petawawa",
                "province": "Ontario",
                "address": "3241 Petawawa Boulevard",
                "longtitude": "-77.272384873261",
                "latitude": "45.893772524319274",
                "hours": "Open",
                "distance": "7649452.066497744",
                "stockStatus": "In Stock"
            },
            {
                "name": "The Cannabis Shop - 545 McNeely Avenue",
                "p_id": "10909",
                "city": "Carleton Place",
                "province": "Ontario",
                "address": "545 McNeely Avenue",
                "longtitude": "-76.11852619999999",
                "latitude": "45.1313132",
                "hours": "Open",
                "distance": "7653621.419085688",
                "stockStatus": "In Stock"
            },
            {
                "name": "Olympia Cannabis - 101 Bridge Street",
                "p_id": "11889",
                "city": "Carleton Place",
                "province": "Ontario",
                "address": "101 Bridge Street",
                "longtitude": "-76.1445935",
                "latitude": "45.139084",
                "hours": "Open",
                "distance": "7654331.904799796",
                "stockStatus": "In Stock"
            },
            {
                "name": "Cannabis 228 - 28 Raglan Street",
                "p_id": "10873",
                "city": "Renfrew",
                "province": "Ontario",
                "address": "28 Raglan Street",
                "longtitude": "-76.6885666",
                "latitude": "45.47504439999999",
                "hours": "Open",
                "distance": "7654516.731001183",
                "stockStatus": "In Stock"
            },
            {
                "name": "T Cannabis - 164 Raglan Street",
                "p_id": "10951",
                "city": "Renfrew",
                "province": "Ontario",
                "address": "164 Raglan Street",
                "longtitude": "-76.6848018",
                "latitude": "45.4723124",
                "hours": "Open",
                "distance": "7654550.614857324",
                "stockStatus": "In Stock"
            },
            {
                "name": "T Cannabis - 4 Russell Street",
                "p_id": "11094",
                "city": "Smiths Falls",
                "province": "Ontario",
                "address": "4 Russell Street",
                "longtitude": "-76.0209706",
                "latitude": "44.90150089999999",
                "hours": "Open",
                "distance": "7667649.077352299",
                "stockStatus": "In Stock"
            },
            {
                "name": "Big River Cannabis - 4983 Calabogie Road",
                "p_id": "13420",
                "city": "Calabogie",
                "province": "Ontario",
                "address": "4983 Calabogie Road",
                "longtitude": "-76.73044678840981",
                "latitude": "45.29949551328539",
                "hours": "Open",
                "distance": "7671331.547837348",
                "stockStatus": "In Stock"
            },
            {
                "name": "One Plant - 1958 Parkedale Avenue",
                "p_id": "12151",
                "city": "Brockville",
                "province": "Ontario",
                "address": "1958 Parkedale Avenue",
                "longtitude": "-75.686690",
                "latitude": "44.613640",
                "hours": "Open",
                "distance": "7674048.501451666",
                "stockStatus": "Call to Confirm"
            },
            {
                "name": "Shiny Bud - 2211 Parkedale Avenue",
                "p_id": "11551",
                "city": "Brockville",
                "province": "Ontario",
                "address": "2211 Parkedale Avenue",
                "longtitude": "-75.69263149999999",
                "latitude": "44.6083891",
                "hours": "Open",
                "distance": "7674797.338289812",
                "stockStatus": "In Stock"
            },
            {
                "name": "Sessions - 2441 Parkedale Avenue",
                "p_id": "10904",
                "city": "Brockville",
                "province": "Ontario",
                "address": "2441 Parkedale Avenue",
                "longtitude": "-75.7003775",
                "latitude": "44.6030926",
                "hours": "Open",
                "distance": "7675644.947074939",
                "stockStatus": "In Stock"
            },
            {
                "name": "Shiny Bud - 182 King Street",
                "p_id": "11552",
                "city": "Brockville",
                "province": "Ontario",
                "address": "182 King Street",
                "longtitude": "-75.68753160000001",
                "latitude": "44.587876",
                "hours": "Open",
                "distance": "7676233.072619486",
                "stockStatus": "In Stock"
            },
            {
                "name": "Perth Cannabis - 23 Wilson St W",
                "p_id": "13293",
                "city": "Perth",
                "province": "Ontario",
                "address": "23 Wilson St W",
                "longtitude": "-76.25562578446129",
                "latitude": "44.90139167909837",
                "hours": "Open",
                "distance": "7679907.453458506",
                "stockStatus": "In Stock"
            },
            {
                "name": "Shiny Bud - 80 Dufferin Street",
                "p_id": "10976",
                "city": "Perth",
                "province": "Ontario",
                "address": "80 Dufferin Street",
                "longtitude": "-76.2689608",
                "latitude": "44.9064511",
                "hours": "Open",
                "distance": "7680181.007912357",
                "stockStatus": "In Stock"
            },
            {
                "name": "Village Bud - 1136 Prince Street",
                "p_id": "13918",
                "city": "Lansdowne",
                "province": "Ontario",
                "address": "1136 Prince Street",
                "longtitude": "-76.01829613170347",
                "latitude": "44.406571140363795",
                "hours": "Open",
                "distance": "7708721.020136365",
                "stockStatus": "In Stock"
            },
            {
                "name": "Bent Bud - 39268 Combermere Road",
                "p_id": "13415",
                "city": "Combermere",
                "province": "Ontario",
                "address": "39268 Combermere Road",
                "longtitude": "-77.6246731",
                "latitude": "45.36365614188438",
                "hours": "Open",
                "distance": "7711901.262953287",
                "stockStatus": "Call to Confirm"
            },
            {
                "name": "Cannabis Bazaar - 210 King Street",
                "p_id": "13460",
                "city": "Gananoque",
                "province": "Ontario",
                "address": "210 King Street",
                "longtitude": "-76.160667",
                "latitude": "44.331137",
                "hours": "Open",
                "distance": "7722496.875974686",
                "stockStatus": "In Stock"
            },
            {
                "name": "One Plant - 1899 Algonquin Avenue",
                "p_id": "12141",
                "city": "Bay",
                "province": "Ontario",
                "address": "1899 Algonquin Avenue",
                "longtitude": "-79.468330",
                "latitude": "46.331880",
                "hours": "Open",
                "distance": "7722829.07493044",
                "stockStatus": "Call to Confirm"
            },
            {
                "name": "Budssmoke - 1236 Algonquin Avenue",
                "p_id": "11001",
                "city": "North Bay",
                "province": "Ontario",
                "address": "1236 Algonquin Avenue",
                "longtitude": "-79.4657258",
                "latitude": "46.3269617",
                "hours": "Open",
                "distance": "7723118.126168588",
                "stockStatus": "In Stock"
            },
            {
                "name": "Kana Leaf - 2 Osprey Miikan",
                "p_id": "11072",
                "city": "North Bay",
                "province": "Ontario",
                "address": "2 Osprey Miikan",
                "longtitude": "-79.56998879999999",
                "latitude": "46.3384014",
                "hours": "Open",
                "distance": "7727304.766654397",
                "stockStatus": "In Stock"
            },
            {
                "name": "Northern Zen Cannabis - 76 Osprey Miikan",
                "p_id": "11461",
                "city": "North Bay",
                "province": "Ontario",
                "address": "76 Osprey Miikan",
                "longtitude": "-79.57380049999999",
                "latitude": "46.3394203",
                "hours": "Open",
                "distance": "7727406.593516737",
                "stockStatus": "In Stock"
            },
            {
                "name": "Pop's Cannabis - 12006 Highway 17",
                "p_id": "11389",
                "city": "West Nipissing",
                "province": "Ontario",
                "address": "12006 Highway 17",
                "longtitude": "-79.912516",
                "latitude": "46.365471",
                "hours": "Open",
                "distance": "7741903.129572312",
                "stockStatus": "In Stock"
            },
            {
                "name": "Kings of Queens - 218 Main Street",
                "p_id": "11086",
                "city": "West Nipissing",
                "province": "Ontario",
                "address": "218 Main Street",
                "longtitude": "-79.9278174",
                "latitude": "46.3673619",
                "hours": "Open",
                "distance": "7742495.379727371",
                "stockStatus": "In Stock"
            },
            {
                "name": "Cannabis Bazaar - 1126 Sydenham Road",
                "p_id": "13461",
                "city": "Kingston",
                "province": "Ontario",
                "address": "1126 Sydenham Road",
                "longtitude": "-76.544041",
                "latitude": "44.272977",
                "hours": "Open",
                "distance": "7747476.191523734",
                "stockStatus": "In Stock"
            },
            {
                "name": "Shiny Bud - 83 Aberfoyle Rd",
                "p_id": "13825",
                "city": "Kingston",
                "province": "Ontario",
                "address": "83 Aberfoyle Rd",
                "longtitude": "-76.52189015773297",
                "latitude": "44.25855697404069",
                "hours": "Open",
                "distance": "7747522.654517354",
                "stockStatus": "In Stock"
            },
            {
                "name": "The Underground - 1 Fairway",
                "p_id": "13879",
                "city": "Bancroft",
                "province": "Ontario",
                "address": "1 Fairway",
                "longtitude": "-77.862393",
                "latitude": "45.084835",
                "hours": "Open",
                "distance": "7747538.140410713",
                "stockStatus": "In Stock"
            },
            {
                "name": "Spiritleaf - 27 Princess Street",
                "p_id": "11678",
                "city": "Kingston",
                "province": "Ontario",
                "address": "27 Princess Street",
                "longtitude": "-76.4796873",
                "latitude": "44.2315191",
                "hours": "Open",
                "distance": "7747573.044247217",
                "stockStatus": "In Stock"
            },
            {
                "name": "Tokyo Smoke - 1011 Princess Street",
                "p_id": "11802",
                "city": "Kingston",
                "province": "Ontario",
                "address": "1011 Princess Street",
                "longtitude": "-76.5142772",
                "latitude": "44.2436526",
                "hours": "Open",
                "distance": "7748371.306466598",
                "stockStatus": "In Stock"
            },
            {
                "name": "Calyx & Trichomes - 1105 Midland Avenue",
                "p_id": "13456",
                "city": "Kingston",
                "province": "Ontario",
                "address": "1105 Midland Avenue",
                "longtitude": "-76.57546",
                "latitude": "44.26328",
                "hours": "Open",
                "distance": "7749934.181752975",
                "stockStatus": "In Stock"
            },
            {
                "name": "710 Kingston - 1057 Midland Avenue",
                "p_id": "11489",
                "city": "Kingston",
                "province": "Ontario",
                "address": "1057 Midland Avenue",
                "longtitude": "-76.5745635",
                "latitude": "44.2610331",
                "hours": "Open",
                "distance": "7750075.393821578",
                "stockStatus": "In Stock"
            },
            {
                "name": "Bancroft Cannabis - 7 station street",
                "p_id": "13264",
                "city": "Bancroft",
                "province": "Ontario",
                "address": "7 station street",
                "longtitude": "-77.890887",
                "latitude": "45.059481",
                "hours": "Open",
                "distance": "7751134.668379002",
                "stockStatus": "In Stock"
            },
            {
                "name": "Enroute Cannabis - 9154 Highway 17",
                "p_id": "13961",
                "city": "Warren",
                "province": "Ontario",
                "address": "9154 Highway 17",
                "longtitude": "-80.29185903281203",
                "latitude": "46.43833309498542",
                "hours": "Open",
                "distance": "7754315.850721574",
                "stockStatus": "In Stock"
            },
            {
                "name": "Star Buds - 4507 Bath Road",
                "p_id": "11500",
                "city": "Loyalist",
                "province": "Ontario",
                "address": "4507 Bath Road",
                "longtitude": "-76.6448072",
                "latitude": "44.2187105",
                "hours": "Open",
                "distance": "7757300.466558569",
                "stockStatus": "In Stock"
            },
            {
                "name": "Pop's Cannabis - 5118 Old Highway 69",
                "p_id": "11426",
                "city": "Greater Sudbury",
                "province": "Ontario",
                "address": "5118 Old Highway 69",
                "longtitude": "-80.9181679",
                "latitude": "46.6679872",
                "hours": "Open",
                "distance": "7765173.203327039",
                "stockStatus": "In Stock"
            },
            {
                "name": "Bathbudz N Boutique - 220 Main Street",
                "p_id": "13317",
                "city": "Bath",
                "province": "Ontario",
                "address": "220 Main Street",
                "longtitude": "-76.7678051",
                "latitude": "44.1849421",
                "hours": "Open",
                "distance": "7766571.4097921625",
                "stockStatus": "In Stock"
            },
            {
                "name": "Olympia Cannabis - 233 Victoria Street",
                "p_id": "10800",
                "city": "Tweed",
                "province": "Ontario",
                "address": "233 Victoria Street",
                "longtitude": "-77.312394",
                "latitude": "44.4771608",
                "hours": "Open",
                "distance": "7770389.6014498",
                "stockStatus": "In Stock"
            },
            {
                "name": "Miss Jones - 3140 Old Highway 69 N",
                "p_id": "10349",
                "city": "Greater Sudbury",
                "province": "Ontario",
                "address": "3140 Old Highway 69 N",
                "longtitude": "-81.006299",
                "latitude": "46.613056",
                "hours": "Open",
                "distance": "7774164.56554458",
                "stockStatus": "In Stock"
            },
            {
                "name": "Cannabis Jacks - 3020 Old Highway 69",
                "p_id": "10677",
                "city": "Greater Sudbury",
                "province": "Ontario",
                "address": "3020 Old Highway 69",
                "longtitude": "-81.0068726",
                "latitude": "46.6111839",
                "hours": "Open",
                "distance": "7774353.221524471",
                "stockStatus": "In Stock"
            },
            {
                "name": "Olympia Cannabis - 15 Durham Street South",
                "p_id": "10802",
                "city": "Madv",
                "province": "Ontario",
                "address": "15 Durham Street South",
                "longtitude": "-77.47236",
                "latitude": "44.50512",
                "hours": "Open",
                "distance": "7776323.237739509",
                "stockStatus": "In Stock"
            },
            {
                "name": "Bananas Cannabis - 1855 Lasalle Boulevard",
                "p_id": "11452",
                "city": "Greater Sudbury",
                "province": "Ontario",
                "address": "1855 Lasalle Boulevard",
                "longtitude": "-80.93005889999999",
                "latitude": "46.5212741",
                "hours": "Open",
                "distance": "7778348.170891298",
                "stockStatus": "In Stock"
            },
            {
                "name": "Grassroots - 1113 Lasalle Boulevard",
                "p_id": "11275",
                "city": "Greater Sudbury",
                "province": "Ontario",
                "address": "1113 Lasalle Boulevard",
                "longtitude": "-80.953913",
                "latitude": "46.5217702",
                "hours": "Open",
                "distance": "7779464.503967506",
                "stockStatus": "In Stock"
            },
            {
                "name": "Sessions - 900 Lasalle Boulevard",
                "p_id": "10994",
                "city": "Greater Sudbury",
                "province": "Ontario",
                "address": "900 Lasalle Boulevard",
                "longtitude": "-80.9599417",
                "latitude": "46.52296949999999",
                "hours": "Open",
                "distance": "7779654.324630069",
                "stockStatus": "In Stock"
            },
            {
                "name": "Cannaverse - 110 Donna Dr",
                "p_id": "14131",
                "city": "Greater Sudbury",
                "province": "Ontario",
                "address": "110 Donna Dr",
                "longtitude": "-80.9397883",
                "latitude": "46.503117",
                "hours": "Open",
                "distance": "7780380.608067326",
                "stockStatus": "In Stock"
            },
            {
                "name": "Pop's Cannabis - 1276 Kingsway",
                "p_id": "11902",
                "city": "Greater Sudbury",
                "province": "Ontario",
                "address": "1276 Kingsway",
                "longtitude": "-80.954895",
                "latitude": "46.5029169",
                "hours": "Open",
                "distance": "7781131.893477803",
                "stockStatus": "In Stock"
            },
            {
                "name": "Green Apple Cannabis - 51 Commercial Drive",
                "p_id": "12438",
                "city": "Burk's Falls",
                "province": "Ontario",
                "address": "51 Commercial Drive",
                "longtitude": "-79.40357999999999",
                "latitude": "45.6082046",
                "hours": "Open",
                "distance": "7781172.894249508",
                "stockStatus": "In Stock"
            },
            {
                "name": "Pop's Cannabis - 450 Notre Dame Avenue",
                "p_id": "11598",
                "city": "Greater Sudbury",
                "province": "Ontario",
                "address": "450 Notre Dame Avenue",
                "longtitude": "-80.98621159999999",
                "latitude": "46.5032322",
                "hours": "Open",
                "distance": "7782626.201454965",
                "stockStatus": "In Stock"
            },
            {
                "name": "Lost Lake - 42 Lorne Street",
                "p_id": "11131",
                "city": "Greater Sudbury",
                "province": "Ontario",
                "address": "42 Lorne Street",
                "longtitude": "-81.00004919999999",
                "latitude": "46.4918155",
                "hours": "Open",
                "distance": "7784279.470329644",
                "stockStatus": "In Stock"
            },
            {
                "name": "Happy Life - 3702 Ontario 144",
                "p_id": "10709",
                "city": "Greater Sudbury",
                "province": "Ontario",
                "address": "3702 Ontario 144",
                "longtitude": "-81.1885471",
                "latitude": "46.5692965",
                "hours": "Open",
                "distance": "7786757.66607684",
                "stockStatus": "In Stock"
            },
            {
                "name": "Canna Cabana - 2019 Long Lake Road",
                "p_id": "12910",
                "city": "Sudbury",
                "province": "Ontario",
                "address": "2019 Long Lake Road",
                "longtitude": "-81.00471",
                "latitude": "46.45093",
                "hours": "Open",
                "distance": "7788020.13211708",
                "stockStatus": "In Stock"
            },
            {
                "name": "The Underground - 10020 Highway 28",
                "p_id": "13881",
                "city": "Apsley",
                "province": "Ontario",
                "address": "10020 Highway 28",
                "longtitude": "-78.09742946639773",
                "latitude": "44.747645794546415",
                "hours": "Open",
                "distance": "7788052.68014912",
                "stockStatus": "In Stock"
            },
            {
                "name": "Muskoka Cannabis - 92 King William Street",
                "p_id": "13743",
                "city": "Huntsville",
                "province": "Ontario",
                "address": "92 King William Street",
                "longtitude": "-79.20480280467123",
                "latitude": "45.33612523910614",
                "hours": "Open",
                "distance": "7794366.001868829",
                "stockStatus": "In Stock"
            },
            {
                "name": "Kasa Kana - 6 King William Street",
                "p_id": "11493",
                "city": "Huntsville",
                "province": "Ontario",
                "address": "6 King William Street",
                "longtitude": "-79.2116034",
                "latitude": "45.3295791",
                "hours": "Open",
                "distance": "7795265.246687867",
                "stockStatus": "In Stock"
            },
            {
                "name": "Puffins Cannabis - 96 Main Street",
                "p_id": "11017",
                "city": "Huntsville",
                "province": "Ontario",
                "address": "96 Main Street",
                "longtitude": "-79.21431690000001",
                "latitude": "45.3287802",
                "hours": "Open",
                "distance": "7795469.738681468",
                "stockStatus": "In Stock"
            },
            {
                "name": "Golden Tree Cannabis - 1 Mill Street",
                "p_id": "13598",
                "city": "Stirling",
                "province": "Ontario",
                "address": "1 Mill Street",
                "longtitude": "-77.54739071188027",
                "latitude": "44.30213543900351",
                "hours": "Open",
                "distance": "7797321.718147737",
                "stockStatus": "In Stock"
            },
            {
                "name": "Stirling Cannabis - 28 Mill Street",
                "p_id": "13300",
                "city": "Stirling",
                "province": "Ontario",
                "address": "28 Mill Street",
                "longtitude": "-77.547007",
                "latitude": "44.297055",
                "hours": "Open",
                "distance": "7797730.383631593",
                "stockStatus": "In Stock"
            },
            {
                "name": "Shiny Bud - 444 Dundas Street",
                "p_id": "13822",
                "city": "Belleville",
                "province": "Ontario",
                "address": "444 Dundas Street",
                "longtitude": "-77.35514",
                "latitude": "44.167359",
                "hours": "Open",
                "distance": "7798699.393563906",
                "stockStatus": "In Stock"
            },
            {
                "name": "Yield Cannabis - 110 N Front Street",
                "p_id": "11874",
                "city": "Belleville",
                "province": "Ontario",
                "address": "110 N Front Street",
                "longtitude": "-77.3910988",
                "latitude": "44.1761734",
                "hours": "Open",
                "distance": "7799827.168503528",
                "stockStatus": "In Stock"
            },
            {
                "name": "Sativa Bliss - 200 Front Street",
                "p_id": "11177",
                "city": "Belleville",
                "province": "Ontario",
                "address": "200 Front Street",
                "longtitude": "-77.3841751",
                "latitude": "44.16349150000001",
                "hours": "Open",
                "distance": "7800536.012550855",
                "stockStatus": "In Stock"
            },
            {
                "name": "Shiny Bud - 48 Dundas Street",
                "p_id": "13823",
                "city": "Belleville",
                "province": "Ontario",
                "address": "48 Dundas Street",
                "longtitude": "-77.388809",
                "latitude": "44.159291",
                "hours": "Open",
                "distance": "7801131.157029375",
                "stockStatus": "In Stock"
            },
            {
                "name": "ERBN Green - 126 Main Street",
                "p_id": "11211",
                "city": "Prince Edward",
                "province": "Ontario",
                "address": "126 Main Street",
                "longtitude": "-77.1441629",
                "latitude": "44.00601409999999",
                "hours": "Open",
                "distance": "7801288.758925683",
                "stockStatus": "In Stock"
            },
            {
                "name": "Cannaverse - 23 George Wright Blvd",
                "p_id": "13319",
                "city": "Prince Edward",
                "province": "Ontario",
                "address": "23 George Wright Blvd",
                "longtitude": "-77.1614809",
                "latitude": "44.0005201",
                "hours": "Open",
                "distance": "7802656.107490962",
                "stockStatus": "In Stock"
            },
            {
                "name": "Capturing Eden - 129 King Street",
                "p_id": "13483",
                "city": "Minden",
                "province": "Ontario",
                "address": "129 King Street",
                "longtitude": "-78.711148",
                "latitude": "44.938926",
                "hours": "Open",
                "distance": "7803171.996620585",
                "stockStatus": "In Stock"
            },
            {
                "name": "Sunfish - 30 Ontario Street",
                "p_id": "13846",
                "city": "Havelock",
                "province": "Ontario",
                "address": "30 Ontario Street",
                "longtitude": "-77.88283156519064",
                "latitude": "44.4334783765422",
                "hours": "Open",
                "distance": "7803579.052822547",
                "stockStatus": "In Stock"
            },
            {
                "name": "Buzzed Buds - 5584 Ontario 28",
                "p_id": "11030",
                "city": "Woodview",
                "province": "Ontario",
                "address": "5584 Ontario 28",
                "longtitude": "-78.1777029",
                "latitude": "44.5790091",
                "hours": "Open",
                "distance": "7806441.637852907",
                "stockStatus": "In Stock"
            },
            {
                "name": "Spiritleaf - 15 Bridge Street West",
                "p_id": "11698",
                "city": "Campbellford",
                "province": "Ontario",
                "address": "15 Bridge Street West",
                "longtitude": "-77.8004648",
                "latitude": "44.3083348",
                "hours": "Open",
                "distance": "7809902.20334064",
                "stockStatus": "In Stock"
            },
            {
                "name": "Trenton Cannabis - 255 Glen Miller road",
                "p_id": "13313",
                "city": "Trenton",
                "province": "Ontario",
                "address": "255 Glen Miller road",
                "longtitude": "-77.580188",
                "latitude": "44.12695",
                "hours": "Open",
                "distance": "7813807.775274976",
                "stockStatus": "In Stock"
            },
            {
                "name": "Your Local Cannabis - 266 Dundas Street",
                "p_id": "13933",
                "city": "Trenton",
                "province": "Ontario",
                "address": "266 Dundas Street",
                "longtitude": "-77.55777740206979",
                "latitude": "44.10528595472558",
                "hours": "Open",
                "distance": "7814472.394295089",
                "stockStatus": "In Stock"
            },
            {
                "name": "Island Smoke - 220 Dundas Street",
                "p_id": "11503",
                "city": "Quinte West",
                "province": "Ontario",
                "address": "220 Dundas Street",
                "longtitude": "-77.5617852",
                "latitude": "44.10479489999999",
                "hours": "Open",
                "distance": "7814722.119942488",
                "stockStatus": "In Stock"
            },
            {
                "name": "Sunfish - 112 Queen Street",
                "p_id": "13845",
                "city": "Lakefield",
                "province": "Ontario",
                "address": "112 Queen Street",
                "longtitude": "-78.26964119959773",
                "latitude": "44.42769909157747",
                "hours": "Open",
                "distance": "7823983.380764806",
                "stockStatus": "In Stock"
            },
            {
                "name": "Canna Cabana - 77 Manitoba St",
                "p_id": "12970",
                "city": "Bracebridge",
                "province": "Ontario",
                "address": "77 Manitoba St",
                "longtitude": "-79.31151192883517",
                "latitude": "45.04198890361917",
                "hours": "Open",
                "distance": "7824797.38456734",
                "stockStatus": "In Stock"
            },
            {
                "name": "Canna Cabana - 125 Muskoka District Road",
                "p_id": "12969",
                "city": "Bracebridge",
                "province": "Ontario",
                "address": "125 Muskoka District Road",
                "longtitude": "-79.32685468650556",
                "latitude": "45.04490653662907",
                "hours": "Open",
                "distance": "7825322.291484492",
                "stockStatus": "In Stock"
            },
            {
                "name": "Rokeby Cannabis - 48 Main Street",
                "p_id": "13806",
                "city": "Bobcaygeon",
                "province": "Ontario",
                "address": "48 Main Street",
                "longtitude": "-78.54657766414509",
                "latitude": "44.54086419022122",
                "hours": "Open",
                "distance": "7828581.255144767",
                "stockStatus": "In Stock"
            },
            {
                "name": "Giggle Leaf - 1 Horseshoe Lake Road",
                "p_id": "11480",
                "city": "Parry Sound",
                "province": "Ontario",
                "address": "1 Horseshoe Lake Road",
                "longtitude": "-79.8841212",
                "latitude": "45.3035539",
                "hours": "Open",
                "distance": "7831197.920376747",
                "stockStatus": "In Stock"
            },
            {
                "name": "Alpine Smoke - 6666 Highway 35",
                "p_id": "13413",
                "city": "Coboconk",
                "province": "Ontario",
                "address": "6666 Highway 35",
                "longtitude": "-78.79751013786156",
                "latitude": "44.659124093575734",
                "hours": "Open",
                "distance": "7831343.623244849",
                "stockStatus": "In Stock"
            },
            {
                "name": "Sparkle Cannabis - 6666 ON-35",
                "p_id": "12853",
                "city": "Kawartha Lakes",
                "province": "Ontario",
                "address": "6666 ON-35",
                "longtitude": "-78.7975058",
                "latitude": "44.6590472",
                "hours": "Open",
                "distance": "7831349.941649368",
                "stockStatus": "In Stock"
            },
            {
                "name": "Flower Town Cannabis - 874 Ward Street",
                "p_id": "13276",
                "city": "Bridgenorth",
                "province": "Ontario",
                "address": "874 Ward Street",
                "longtitude": "-78.3865447",
                "latitude": "44.3831756",
                "hours": "Open",
                "distance": "7833764.843358527",
                "stockStatus": "In Stock"
            },
            {
                "name": "Dutch Love - 1 Pine Drive",
                "p_id": "11348",
                "city": "Parry Sound",
                "province": "Ontario",
                "address": "1 Pine Drive",
                "longtitude": "-80.01174689999999",
                "latitude": "45.3460594",
                "hours": "Open",
                "distance": "7833928.936330954",
                "stockStatus": "In Stock"
            },
            {
                "name": "Windswept Cannabis - 78 Church Street",
                "p_id": "11321",
                "city": "Parry Sound",
                "province": "Ontario",
                "address": "78 Church Street",
                "longtitude": "-80.03653059999999",
                "latitude": "45.3514813",
                "hours": "Open",
                "distance": "7834700.054325602",
                "stockStatus": "In Stock"
            },
            {
                "name": "Sparq - 809 Chemg Road",
                "p_id": "11454",
                "city": "Peterborough",
                "province": "Ontario",
                "address": "809 Chemg Road",
                "longtitude": "-78.3308562",
                "latitude": "44.3172743",
                "hours": "Open",
                "distance": "7836495.309807257",
                "stockStatus": "In Stock"
            },
            {
                "name": "The Peace Pipe - 408 George Street",
                "p_id": "11179",
                "city": "Peterborough",
                "province": "Ontario",
                "address": "408 George Street",
                "longtitude": "-78.31987649999999",
                "latitude": "44.3061678",
                "hours": "Open",
                "distance": "7836872.977537121",
                "stockStatus": "In Stock"
            },
            {
                "name": "Sparq - 340 Charlotte Street",
                "p_id": "11098",
                "city": "Peterborough",
                "province": "Ontario",
                "address": "340 Charlotte Street",
                "longtitude": "-78.3281471",
                "latitude": "44.3032633",
                "hours": "Open",
                "distance": "7837544.796114604",
                "stockStatus": "In Stock"
            },
            {
                "name": "Buds - 255 Muskoka Rd N",
                "p_id": "13959",
                "city": "Gravenhurst",
                "province": "Ontario",
                "address": "255 Muskoka Rd N",
                "longtitude": "-79.37480140205174",
                "latitude": "44.92114830572694",
                "hours": "Open",
                "distance": "7838298.478395576",
                "stockStatus": "In Stock"
            },
            {
                "name": "High Supply Cannabis Co. - 21 Francis Street",
                "p_id": "13659",
                "city": "Fenelon Falls",
                "province": "Ontario",
                "address": "21 Francis Street",
                "longtitude": "-78.7380024079525",
                "latitude": "44.53744604919827",
                "hours": "Open",
                "distance": "7838656.166264951",
                "stockStatus": "In Stock"
            },
            {
                "name": "Sessions - 550 Lansdowne Street",
                "p_id": "11189",
                "city": "Peterborough",
                "province": "Ontario",
                "address": "550 Lansdowne Street",
                "longtitude": "-78.326124",
                "latitude": "44.28737719999999",
                "hours": "Open",
                "distance": "7838788.682443484",
                "stockStatus": "In Stock"
            },
            {
                "name": "High Society - 13 Stevens Avenue",
                "p_id": "14189",
                "city": "Marathon",
                "province": "Ontario",
                "address": "13 Stevens Avenue",
                "longtitude": "-86.382747",
                "latitude": "48.718154",
                "hours": "Open",
                "distance": "7839086.448448764",
                "stockStatus": "In Stock"
            },
            {
                "name": "Muskoka Cannabis - 351 Talisman Drive",
                "p_id": "13744",
                "city": "Gravenhurst",
                "province": "Ontario",
                "address": "351 Talisman Drive",
                "longtitude": "-79.36881024329256",
                "latitude": "44.906077943999435",
                "hours": "Open",
                "distance": "7839282.128607097",
                "stockStatus": "In Stock"
            },
            {
                "name": "Sparq - 861 Lansdowne Street",
                "p_id": "11113",
                "city": "Peterborough",
                "province": "Ontario",
                "address": "861 Lansdowne Street",
                "longtitude": "-78.3388695",
                "latitude": "44.2828024",
                "hours": "Open",
                "distance": "7839832.528593963",
                "stockStatus": "In Stock"
            },
            {
                "name": "Speakeasy - 33 King Street",
                "p_id": "11895",
                "city": "Colborne",
                "province": "Ontario",
                "address": "33 King Street",
                "longtitude": "-77.8869168",
                "latitude": "44.0056523",
                "hours": "Open",
                "distance": "7839981.291022558",
                "stockStatus": "In Stock"
            },
            {
                "name": "Happy Life - 387 Centre Street",
                "p_id": "10707",
                "city": "Espanola",
                "province": "Ontario",
                "address": "387 Centre Street",
                "longtitude": "-81.7647234",
                "latitude": "46.2574847",
                "hours": "Open",
                "distance": "7841524.687400227",
                "stockStatus": "In Stock"
            },
            {
                "name": "Bananas Cannabis - 800 Centre Street",
                "p_id": "11294",
                "city": "Espanola",
                "province": "Ontario",
                "address": "800 Centre Street",
                "longtitude": "-81.7574339",
                "latitude": "46.248427",
                "hours": "Open",
                "distance": "7841956.108307532",
                "stockStatus": "In Stock"
            },
            {
                "name": "Sweet Seven Cannabis Co. - 721 Centre Street",
                "p_id": "13302",
                "city": "Espanola",
                "province": "Ontario",
                "address": "721 Centre Street",
                "longtitude": "-81.7615251",
                "latitude": "46.2483149",
                "hours": "Open",
                "distance": "7842163.4584697625",
                "stockStatus": "In Stock"
            },
            {
                "name": "Kasa Kana - 1840 Lansdowne Street",
                "p_id": "11126",
                "city": "Peterborough",
                "province": "Ontario",
                "address": "1840 Lansdowne Street",
                "longtitude": "-78.37411",
                "latitude": "44.275429",
                "hours": "Open",
                "distance": "7842270.826976147",
                "stockStatus": "In Stock"
            },
            {
                "name": "Lindsay Cannabis - 17 Kent Street",
                "p_id": "13287",
                "city": "Lindsay",
                "province": "Ontario",
                "address": "17 Kent Street",
                "longtitude": "-78.735155",
                "latitude": "44.355292",
                "hours": "Open",
                "distance": "7854005.935465419",
                "stockStatus": "In Stock"
            },
            {
                "name": "Greenleaf Cannabis - 351 Kent Street",
                "p_id": "13612",
                "city": "Lindsay",
                "province": "Ontario",
                "address": "351 Kent Street",
                "longtitude": "-78.7557666172227",
                "latitude": "44.35077031744863",
                "hours": "Open",
                "distance": "7855445.55501555",
                "stockStatus": "In Stock"
            },
            {
                "name": "The 420 Store - 370 Kent Street",
                "p_id": "11205",
                "city": "Kawartha Lakes",
                "province": "Ontario",
                "address": "370 Kent Street",
                "longtitude": "-78.7578932",
                "latitude": "44.3513799",
                "hours": "Open",
                "distance": "7855502.495644494",
                "stockStatus": "In Stock"
            },
            {
                "name": "The Peace Pipe - 1 King Street",
                "p_id": "10923",
                "city": "Cobourg",
                "province": "Ontario",
                "address": "1 King Street",
                "longtitude": "-78.16508809999999",
                "latitude": "43.9596436",
                "hours": "Open",
                "distance": "7858288.625286542",
                "stockStatus": "In Stock"
            },
            {
                "name": "Mary J's Cannabis - 38 King Street West",
                "p_id": "10277",
                "city": "Cobourg",
                "province": "Ontario",
                "address": "38 King Street West",
                "longtitude": "-78.167113",
                "latitude": "43.9599442",
                "hours": "Open",
                "distance": "7858367.879659611",
                "stockStatus": "In Stock"
            },
            {
                "name": "Circa 1818 - 8 Street",
                "p_id": "11062",
                "city": "Cobourg",
                "province": "Ontario",
                "address": "8 Street",
                "longtitude": "-78.1982056",
                "latitude": "43.9736255",
                "hours": "Open",
                "distance": "7858815.145854136",
                "stockStatus": "In Stock"
            },
            {
                "name": "Inspired Cannabis - 1111 Elgin Street West",
                "p_id": "10301",
                "city": "Cobourg",
                "province": "Ontario",
                "address": "1111 Elgin Street West",
                "longtitude": "-78.1997406",
                "latitude": "43.9701555",
                "hours": "Open",
                "distance": "7859188.993287414",
                "stockStatus": "In Stock"
            },
    ],
    mapDetails: {
        provinces: [],
        cities: [],
        categories: [],
    },
    sortedApiResponse: {
        province: [],
        city: [],
        product: [],
        brand: [],
        category: [],
    },
    currentLocation: {},
    tab: { id: null, detail: null },
    theme: {},

    outOfStockForm: [
        {
            name: "firstName",
            placeholder: "*First Name",
            value: "",
            type: "text",
            required: true,
        },
        {
            name: "lastName",
            placeholder: "*Last Name",
            value: "",
            type: "text",
            required: true,
        },
        {
            name: "email",
            placeholder: "*Email",
            value: "",
            type: "email",
            required: true,
        },
        {
            name: "message",
            placeholder: "Message",
            value: "",
            type: "textarea",
            required: false,
        },
    ],
};

export const mapSlice = createSlice({
    name: "map",
    initialState,
    reducers: {
		setMapDetails: (state, action) => {
			state.mapDetails = action.payload;
		},
	},
});

export const {
    setMapDetails,
} = mapSlice.actions;

export default mapSlice.reducer;

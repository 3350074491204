import React from 'react';
import { Outlet, useLocation, useParams, useNavigate } from "react-router-dom";
import { Sidebar } from "../../components/dashboard/Sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { applyFilters, removeAppliedFilter, toggleMobileContentOpen, setStoreList, setMapStores } from "../../redux/dashboard/dashboard-slice";
import { setMapDetails } from "../../redux/map/map-slice";
import { useEffect, useState } from "react";
import API from "../../api";

export const Dashboard = ({ fullscreen = false }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const appliedFilters = useSelector((state) => state.dashboard.appliedFilters);
    const productList = useSelector((state) => state.dashboard.productsList);
    const mobileContentOpen = useSelector((state) => state.dashboard.mobileContentOpen);
    const location = useLocation();
    
    const [canToggleMobileContentOpen, setCanToggleMobileContentOpen] = useState(true);
    const [currentStore, setCurrentStore] = useState(null);
    const [product, setProduct] = useState(null);
    const [activeProducts, setActiveProducts] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [storesList, setStoresList] = useState([]);
    const [totalStoresDetails, setTotalStoresDetails] = useState([]);
    const [loading, setLoading] = useState(true); 
    
    const storeProductsList = useSelector((state) => state.dashboard.productsList);
    const storeMapStores = useSelector((state) => state.dashboard.mapStores);
    const [storeServerPagination, setStoreServerPagination] = useState(false);
    
    const totalStoreCount = async () => {
        try {
            const res = await API.searchBasicProducts('', 100000, 1);
            setTotalStoresDetails(res);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false); // Ensure loading state is reset in case of error
        }
    };

    useEffect(() => {
        totalStoreCount();
    }, []);
    
    const { id } = useParams();
    
    const clearFilters = () => {
        dispatch(applyFilters({
            categories: [],
            cities: [],
            provinces: [],
        }));
        setActiveProducts([]);
    };
    
    const removeFilter = (filterItem) => {
        if (filterItem.name === "activeProducts") {
            setActiveProducts((prevState) => prevState.filter(item => item !== filterItem.displayName));
        } else {
            dispatch(removeAppliedFilter(filterItem));
        }
    };
    
    const mobileWidth = window.innerWidth < 992;
    
    useEffect(() => {
        if (!activeProducts.length && id) {
            setActiveProducts([id]);
        }
    
        if (window.innerWidth < 992) {
            if (location.pathname.includes('/products/') || location.pathname.includes('/stores/')) {
                if (storeProductsList.length && storeMapStores.length && canToggleMobileContentOpen) {
                    if (location.pathname.includes('/stores/') && !currentStore) {
                        return;
                    }
    
                    if (location.pathname.includes('/products/') && (!activeProducts.length || product === null) && storeMapStores.length) {
                        return;
                    }
    
                    setCanToggleMobileContentOpen(false);
                    dispatch(toggleMobileContentOpen(true));
                }
            } else {
                dispatch(toggleMobileContentOpen(false));
            }
        }
    }, [location, storeProductsList, storeMapStores]);
    
    const setStoresFromProducts = async () => {
        let localData = [];
    
        for (let index = 0; index < productsList.length; index++) {
            const _productStore = await API.getStoreByProductId(productsList[index].productID);
            const storesSearchResult = await API.searchBasicStores(_productStore[0].producer.name);
            setStoresList(storesSearchResult);
    
            if (localData.filter(item => item.name === storesSearchResult[0].name).length === 0) {
                localData.push({
                    ...storesSearchResult[0],
                    status: _productStore[0].instockstatus,
                    brand: _productStore[0].brand,
                    weight: _productStore[0].weight,
                });
            }
        }
    
        dispatch(setMapStores(localData));
        return localData;
    };
    
    const getData = async () => {
        if (mobileWidth && mobileContentOpen) {
            let storesSearchResult = await API.searchBasicStores('');
            setStoreServerPagination(true);
            let productsSearchResult = await API.searchBasicProducts('');
    
            if (storesSearchResult.length) {
                setStoresList(storesSearchResult);
                dispatch(setStoreList(storesSearchResult));
                dispatch(setMapStores(storesSearchResult));
            } else {
                setStoresList([]);
            }
    
            if (productsSearchResult.length) {
                setProductsList(productsSearchResult);
            } else {
                setProductsList([]);
            }
    
            if (location.pathname.includes('/stores/') && storesSearchResult.length) {
                if (storesSearchResult.find(item => String(item.producerID) === String(location.pathname.split("/")[3]))) {
                    setCurrentStore(storesSearchResult.find(item => String(item.producerID) === String(location.pathname.split("/")[3])));
                }
    
                dispatch(setMapStores(storesSearchResult));
            }
            if (location.pathname.includes('/products/')) {
                const _curProduct = productsList.find(item => String(item.productID) == String(location.pathname.split("/")[3]));
                if (productsList.find(item => String(item.productID) == String(location.pathname.split("/")[3]))) {
                    setProduct((prev) => _curProduct);
                }
    
                setStoresFromProducts();
            }
        }
    };
    
    const getMapDetails = async () => {
        let result = await API.mapDetails();
        result.categories = result.categories.sort((a, b) => (a.category > b.category) ? 1 : ((b.category > a.category) ? -1 : 0));
        result.provinces = result.provinces.sort((a, b) => (a.prov > b.prov) ? 1 : ((b.prov > a.prov) ? -1 : 0));
        result.cities = result.cities.sort((a, b) => (a.city > b.city) ? 1 : ((b.city > a.city) ? -1 : 0));
    
        dispatch(setMapDetails(result));
    };
    
    useEffect(() => {
        if (fullscreen && mobileWidth) {
            navigate("/dashboard");
        }
    
        if (!storesList.length) {
            getData();
        }
    }, [location, mobileWidth, mobileContentOpen, productsList]);
    
    useEffect(() => {
        getMapDetails();
    }, []);
    
    const allFilters = [
        ...appliedFilters.categories.map(item => ({
            name: "categories",
            filter: item,
        })),
        ...appliedFilters.cities.map(item => ({
            name: "cities",
            filter: item,
        })),
        ...appliedFilters.provinces.map(item => ({
            name: "provinces",
            filter: item,
        })),
        ...activeProducts.map(item => {
            const product = totalStoresDetails.find(p => p.productID === item);
            return {
                name: "activeProducts",
                filter: product ? product.name : item, // display name
                displayName: item, // retain productID for removal
            };
        })
    ];
    
    return (
        <div className="dashboard-container">
            {
                (mobileWidth && !mobileContentOpen) || !mobileWidth ?
                    <Sidebar
                        fullscreen={fullscreen}
                        currentStore={currentStore}
                        setCurrentStore={setCurrentStore}
                        product={product}
                        setProduct={setProduct}
                        activeProducts={activeProducts}
                        setActiveProducts={setActiveProducts}
                        setCanToggleMobileContentOpen={setCanToggleMobileContentOpen}
                        storeServerPagination={storeServerPagination}
                        setStoreServerPagination={setStoreServerPagination}
                    />
                    : null
            }
            {
                (mobileWidth && mobileContentOpen) || !mobileWidth ?
                    <div className="dashboard-main flex-col">
                        <div className="dashboard-header d-flex align-items-center">
                            {allFilters.length ? <ul
                                className="filter-btns-group w-33"
                                style={{
                                    marginLeft: "auto",
                                    flexWrap: "initial",
                                    whiteSpace: "nowrap"
                                }}
                            >
                                {loading ? (
                                    <>
                                            <div className="skeleton-loader skeleton-capsule" style={{ width: "initial" }}></div>
                                        <li className="d-flex align-items-center" style={{ margin: '0' }}>
                                            <div style={{
                                                width: "initial",
                                                minWidth: "initial",
                                                padding: '0 calc(10px * var(--scale))',
                                                background: 'transparent'
                                            }}>
                                                <svg width="3" height="17" viewBox="0 0 3 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.49994 3.70386C2.32839 3.70386 3 2.98665 3 2.10193C3 1.21721 2.32839 0.5 1.49994 0.5C0.671504 0.5 0 1.21721 0 2.10193C0 2.98665 0.671504 3.70386 1.49994 3.70386Z" fill="#506D8B" />
                                                    <path d="M1.49994 10.1018C2.32839 10.1018 3 9.3846 3 8.49988C3 7.61516 2.32839 6.89795 1.49994 6.89795C0.671504 6.89795 0 7.61516 0 8.49988C0 9.3846 0.671504 10.1018 1.49994 10.1018Z" fill="#506D8B" />
                                                    <path d="M1.49994 16.5C2.32839 16.5 3 15.7828 3 14.8981C3 14.0133 2.32839 13.2961 1.49994 13.2961C0.671504 13.2961 0 14.0133 0 14.8981C0 15.7828 0.671504 16.5 1.49994 16.5Z" fill="#506D8B" />
                                                </svg>
                                            </div>
                                        </li>
                                        <li style={{ width: "initial" }}>
                                            <a
                                                href="/neobi/#" className="active"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    clearFilters();
                                                }}
                                            >
                                                Clear Filters
                                            </a>
                                        </li>
                                    </>
                                ) : (
                                    <>
                                        {allFilters.map((item, index) => (
                                            
                                            <li key={index} style={{ width: "initial" }}>
                                                <div style={{ width: "initial" }}>
                                                    <div style={{ marginRight: "3px" }}>{item.filter}</div>
                                                    <a
                                                        href="/neobi/#" className="remove-circle-btn"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            removeFilter({ name: item.name, filter: item.filter, displayName: item?.displayName });
                                                        }}
                                                    >
                                                        <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.06982 7.25977C0.939824 7.25977 0.810215 7.20986 0.720215 7.10986L0.390137 6.77979C0.300137 6.68979 0.240234 6.56018 0.240234 6.43018C0.240234 6.30018 0.290137 6.17008 0.390137 6.08008L2.45996 4.00977L0.390137 1.93994C0.300137 1.84994 0.240234 1.71984 0.240234 1.58984C0.240234 1.45984 0.290137 1.33023 0.390137 1.24023L0.720215 0.910156C0.820215 0.810156 0.949824 0.759766 1.06982 0.759766C1.18982 0.759766 1.32992 0.810156 1.41992 0.910156L3.49023 2.97998L5.56982 0.899902C5.66982 0.799902 5.79992 0.75 5.91992 0.75C6.03992 0.75 6.18002 0.799902 6.27002 0.899902L6.6001 1.22998C6.8001 1.42998 6.8001 1.73994 6.6001 1.93994L4.52002 4.02002L6.6001 6.08984C6.6901 6.17984 6.75 6.30994 6.75 6.43994C6.75 6.56994 6.7001 6.70004 6.6001 6.79004L6.27002 7.12012C6.17002 7.22012 6.03992 7.27002 5.91992 7.27002C5.79992 7.27002 5.65982 7.22012 5.56982 7.12012L3.49023 5.0498L1.41992 7.12012C1.31992 7.22012 1.18982 7.27002 1.06982 7.27002V7.25977Z" fill="#BFD0D8" />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </li>
                                        ))}
                                        <li className="d-flex align-items-center" style={{ margin: '0' }}>
                                            <div style={{
                                                width: "initial",
                                                minWidth: "initial",
                                                padding: '0 calc(10px * var(--scale))',
                                                background: 'transparent'
                                            }}>
                                                <svg width="3" height="17" viewBox="0 0 3 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.49994 3.70386C2.32839 3.70386 3 2.98665 3 2.10193C3 1.21721 2.32839 0.5 1.49994 0.5C0.671504 0.5 0 1.21721 0 2.10193C0 2.98665 0.671504 3.70386 1.49994 3.70386Z" fill="#506D8B" />
                                                    <path d="M1.49994 10.1018C2.32839 10.1018 3 9.3846 3 8.49988C3 7.61516 2.32839 6.89795 1.49994 6.89795C0.671504 6.89795 0 7.61516 0 8.49988C0 9.3846 0.671504 10.1018 1.49994 10.1018Z" fill="#506D8B" />
                                                    <path d="M1.49994 16.5C2.32839 16.5 3 15.7828 3 14.8981C3 14.0133 2.32839 13.2961 1.49994 13.2961C0.671504 13.2961 0 14.0133 0 14.8981C0 15.7828 0.671504 16.5 1.49994 16.5Z" fill="#506D8B" />
                                                </svg>
                                            </div>
                                        </li>
                                        <li style={{ width: "initial" }}>
                                            <a
                                                href="/neobi/#" className="active"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    clearFilters();
                                                }}
                                            >
                                                Clear Filters
                                            </a>
                                        </li>
                                    </>
                                )}
                            </ul> : null}
                        </div>
    
                        <Outlet context={{ currentStore, product, setProduct, activeProducts, setCurrentStore }} />
                    </div> : null
            }
        </div>
    )
}

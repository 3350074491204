var myHeaders = new Headers();
myHeaders.append("Ocp-Apim-Subscription-Key", "24f727b829964082b1dc3c4a530fed55");

const optionsDefault = {
    "headers": myHeaders,
    "method": "GET",
}

// const optionsDefault = {
//     "Accept": "application/json",
//     "Content-Type": "application/json"
// }

class API {
    constructor() {
        // this.API_URL = "https://dev.api.neobi.io/api"
        this.API_URL = "https://neobi-io.azure-api.net/dev-public-dash/v3/api"
         //this.API_URL = "http://localhost:50946/api"
    }

    callFetch = async(url, options = optionsDefault) => {
        try {
            return await fetch(
                url,
                options
            ).then(result => {
                if(result){
                    return result.json();
                } else {
                    return null;
                }
            })
        } catch(err) {
            console.log({
                error: true,
                message: err
            })
            return err
        }
    }

    login = async(email, password) => {
        return await this.callFetch(
            `${this.API_URL}/Authentication/AuthenticationUser?email=${email}&pwd=${password}`
        )
    }

    signUp = async(firstName, lastName, email, password, role) => {
        console.log(password , "password")
        return await this.callFetch(
            `${this.API_URL}/Authentication/RegisterUser?email=${email}&pwd=${encodeURIComponent(password)}&role=${role}&firstName=${firstName}&lastName=${lastName}`
        )
    }

    registerUserSkipVerification = async(firstName, lastName, email, password, role) => {
        return await this.callFetch(
            `${this.API_URL}/Authentication/RegisterUserSkipVerification?email=${email}&pwd=${password}&role=${role}&firstName=${firstName}&lastName=${lastName}`,
            {
                ...optionsDefault,
                "method": "POST"
            }
        )
    }

    userEmailVerification = async(uuid) => {
        return await this.callFetch(
            `${this.API_URL}/Authentication/UserEmailVerification?userGuid=${uuid}`,
            {
                ...optionsDefault,
                "method": "PUT"
            }
        )
    }

    resetPassword = async(email) => {
        return await this.callFetch(
            `${this.API_URL}/Authentication/ResetPassword?email=${email}`
        )
    }

    resetPasswordUpdate = async(userGuid, email, password) => {
        return await this.callFetch(
            `${this.API_URL}/Authentication/ResetPasswordUpdate?userGuid=${userGuid}&email=${email}&password=${password}`,
            {
                ...optionsDefault,
                "method": "PUT"
            }
        )
    }

    logout = async() => {
        const accessLogin = localStorage.getItem("accessLogin") ? JSON.parse(localStorage.getItem("accessLogin")) : null;
        if(!accessLogin || !accessLogin?.email){
            return false;
        }

        let result = await this.callFetch(
            `${this.API_URL}/Authentication/SignOutUser?email=${accessLogin.email}`,
            {
                ...optionsDefault,
                "method": "PUT"
            }
        )

        localStorage.setItem("accessLogin", "");
        
        window.location.replace('https://neobi.io/')

        return result;
    }

    getUser = async(email) => {
        return await this.callFetch(
            `${this.API_URL}/Authentication/GetUser?email=${email}`
        )
    }

    modifyUser = async(email, body) => {
        // password, firstName, lastName
        if(!email){ return; }
        let url = `${this.API_URL}/Authentication/ModifyUser?email=${email}`;

        if(body?.password?.length){ url += `&pwd=${body.password}` }
        if(body?.firstName?.length){ url += `&fname=${body.firstName}` }
        if(body?.lastName?.length){ url += `&lname=${body.lastName}` }
        if(body?.notifications){ url += `&notifications=${body.notifications}` }

        return await this.callFetch(
            url
        )
    }

    //MAP
    mapData = async(
        brand = 'spirit', 
        longlat = '0,0'
    ) => {
        return await this.callFetch(
            `${this.API_URL}/MapData?brand=${brand}&longlat=${longlat}`
        )
    }

    mapDetails = async() => {
        return await this.callFetch(
            `${this.API_URL}/MapDetails`
        )
    }

    getAllLocations = async(
        brand = 'Ghost Drops', 
        longlat = '0,0'
    ) => {
        return await this.callFetch(
            `${this.API_URL}/MapLocations/GetAllLocations?brand=${brand}&longlat=${longlat}`
        )
    }

    getAllLocationsBasic = async(
        longlat = '0,0'
    ) => {
        return await this.callFetch(
            `${this.API_URL}/MapPublicDashboard/GetAllLocationsBasic?longlat=${longlat}`
        )
    }

    getLocationsWithFilters = async(
        longlat = '0,0', 
        province_id,
        category_id,
        brand_id = 'Ghost Drops',
        city_id,
        product_id
    ) => {
        return await this.callFetch(
            `${this.API_URL}/MapLocations/GetLocationsWithFilters?longlat=${longlat}&category_id=${category_id}&brand_id=${brand_id}`
        )
    }

    getLocationsDetails = async(
        longlat = '0,0',
        location,
        brand_id
    ) => {}

    mapProducts = async(
        brand = 'spirit'
    ) => {
        return await this.callFetch(
            `${this.API_URL}/MapProducts?brand=${brand}`
        )
    }

    //SEARCH
    searchBasicStores = async(
        keyword = '',
        count = 10,
        page = 1,
        getPageInfo=false
    ) => {
        return await this.callFetch(
            `${this.API_URL}/Search/SearchBasicStores?keyword=${keyword}&count=${count}&page=${page}&getPageInfo=${getPageInfo}`
        )
    }

    searchBasicProducts = async(
        keyword = '',
        count = 10,
        page = 1,
        getPageInfo=false
    ) => {
        return await this.callFetch(
            `${this.API_URL}/Search/SearchBasicProducts?keyword=${keyword}&count=${count}&page=${page}&getPageInfo=${getPageInfo}`
        )
    }

    singleStoreSelectGetProducts = async(
        producerID,
        page = "1",
        count = "100"
    ) => {
        return await this.callFetch(
            `${this.API_URL}/Search/SingleStoreSelectGetProducts?producerID=${producerID}&page=${page}&count=${count}`
        )
    }

    getStoreByProductId = async(
        productId
    )=>{
        return await this.callFetch(`${this.API_URL}/Search/GetStorebyProductID?productID=${productId}`)
    }

    getTotalCardsByProductName = async(productName)=>{
        return await this.callFetch(`${this.API_URL}/Search/GetTotalCardsByProductName?productName=${productName.replace(' & ', '%20%26%20')}`)
    }

    searchStoresByProductName = async(productName = "", page = "1", count = "5000")=>{
        return await this.callFetch(`${this.API_URL}/Search/SearchStoresByProductName?productName=${productName.replace(' & ', '%20%26%20')}&page=${page}&count=${count}`)
    }

    searchBasicStoresByCity = async(keyword = "") => {
        return await this.callFetch(`${this.API_URL}/Search/SearchBasicStoresByCity?keyword=${keyword}`)
    }

    getSettings = async() => {
        return await this.callFetch(`${this.API_URL}/Settings/GetSettings`)
    }

}

const api = new API()

export default api;
import React, { useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import InputComponent from '../../components/StartPagesComponents/InputComponent';
import SpacerСomponent from '../../components/StartPagesComponents/SpacerComponent';
import ButtonComponent from '../../components/StartPagesComponents/ButtonComponent';
import AuthLayoutComponent from '../../components/StartPagesComponents/AuthLayoutComponent';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import API from "../../api";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let id = new URLSearchParams(location.search).get("id");
    console.log('id:', id);
    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
    });

    const [checkEmail, setCheckEmail] = useState(false);

    const submit = async() => {
        let result = await API.resetPassword(loginData.email);
        if(result){
            setCheckEmail(true);
        } else {
            NotificationManager.error('Error');
        }
    }

    const saveNewPassword = async() => {
        if(!loginData.email.length || !loginData.password.length){
            NotificationManager.error('All fields is required');
        }
        if(!id){
            NotificationManager.error('Not found ID params in url');
        }

        let result = await API.resetPasswordUpdate(id, loginData.email, loginData.password);
        if(result){
            NotificationManager.success('Password changed');
            setTimeout(() => {
                navigate('/sign-in')
            }, 1500);
        } else {
            NotificationManager.error('Error');
        }
    }

    return (
        <>
        {/* yosit78543@in2reach.com */}
            <AuthLayoutComponent topLink={{text: "Sign In", to: "/sign-in"}}>
                {   location.pathname === '/resetpassword' ?
                    <>
                        <h2 className="heading mb-10" style={{ textAlign: "center" }}>
                            Enter your Email and New Password
                        </h2>

                        <SpacerСomponent />
                        <InputComponent
                            label="Email" type="text" className="mb-16"
                            value={ loginData.email } onChange={(e) => {
                                setLoginData((prevState)=>{
                                    return { ...prevState, email: e.target.value }
                                })
                            }}
                        />
                        <InputComponent
                            label="Password" type="text" subType="password" className="mb-16"
                            value={ loginData.password } onChange={(e) => {
                                setLoginData((prevState)=>{
                                    return { ...prevState, password: e.target.value }
                                })
                            }}
                        />
                        <ButtonComponent className="mb-30" onClick={saveNewPassword} text={"Submit"}/>
                    </> :
                    checkEmail ? <>
                        <svg fill="#00E0D1" height="80" width="80" version="1.1" id="Layer_1" viewBox="0 0 64 64" enable-background="new 0 0 64 64">
                            <path id="Mail" d="M58.0034485,8H5.9965506c-3.3136795,0-5.9999995,2.6862001-5.9999995,6v36c0,3.3137016,2.6863203,6,5.9999995,6  h52.006897c3.3137016,0,6-2.6862984,6-6V14C64.0034485,10.6862001,61.3171501,8,58.0034485,8z M62.0034485,49.1108017  L43.084549,30.1919994l18.9188995-12.0555992V49.1108017z M5.9965506,10h52.006897c2.2056007,0,4,1.7943001,4,4v1.7664003  L34.4677505,33.3134003c-1.4902,0.9492989-3.3935013,0.9199982-4.8495998-0.0703011L1.9965508,14.4694996V14  C1.9965508,11.7943001,3.7910507,10,5.9965506,10z M1.9965508,16.8852005L21.182251,29.9251003L1.9965508,49.1108017V16.8852005z   M58.0034485,54H5.9965506c-1.6473999,0-3.0638998-1.0021019-3.6760998-2.4278984l20.5199013-20.5200024l5.6547985,3.843401  c1.0859013,0.7383003,2.3418007,1.1083984,3.5995998,1.1083984c1.1953011,0,2.3925018-0.3339996,3.4463005-1.0048981  l5.8423996-3.7230015l20.2961006,20.2961025C61.0673485,52.9978981,59.6508713,54,58.0034485,54z" />
                        </svg>
        
        
                        <h4 style={{ textAlign: "center", marginTop: "15px" }}>Check your email and click the reset password link</h4>
                    </> :
                    <>
                        <h2 className='heading mb-10'>Forgot Password</h2>
                        <SpacerСomponent />
                        <InputComponent
                            label="Email" type="text" className="mb-16"
                            value={ loginData.email } onChange={(e) => {
                                setLoginData((prevState)=>{
                                    return { ...prevState, email: e.target.value }
                                })
                            }}
                        />
                        <ButtonComponent className="mb-30" onClick={submit} text={"Continue"}/>

                        <SpacerСomponent />
                        
                        <Link to={"/sign-in"} className="subheading hide-on-mob">Sign In</Link>
                    </>
                }


                <NotificationContainer/>
            </AuthLayoutComponent>
        </>
    )
}

export default ForgotPassword;
import React, {useState} from 'react'

const ToggleInput = ({ label, checked, onChange }) => {
    return (
        <label className="toggle-input">
            { label ? <div className="toggle-input-label">{ label }</div> : null }
            <input
                type="checkbox" name="" id=""
                checked={checked} onChange={(e)=>{
                    onChange(e.target.checked)
                }}
            />
            <div className="toggle-input-box">
                <div className="toggle-input-circle"></div>
            </div>            
        </label>
    );
};

export default ToggleInput
import React from "react";
import { NavLink } from "react-router-dom";
import {ReactComponent as DashboardIcon} from "../assets/images/left-nav-dashboard.svg";
import {ReactComponent as LockIcon} from "../assets/images/left-nav-lock.svg";
import {ReactComponent as SettingsIcon} from "../assets/images/left-nav-settings.svg";
import {ReactComponent as MessagesIcon} from "../assets/images/left-nav-messages.svg";
import { useDispatch } from "react-redux";

import { openModalAction } from "../redux/dashboard/dashboard-slice";
import API from "../api";

export const LeftNav = ({ openNav }) => {
    const dispatch = useDispatch();

    const openModal = () => {
        dispatch(openModalAction("UnlockPremiumFeatures"));
    }

    const logout = async() => {
        await API.logout();
    }

    return (
        <nav className={`left-nav ${openNav ? 'open': ''}`}>
            <ul>
                <li>
                    <NavLink to='/home'>
                        <DashboardIcon />
                    </NavLink>
                </li>
                {/* <li>
                    <a href="/#" onClick={(e)=>{ e.preventDefault(); openModal(); }}>
                        <LockIcon />
                    </a>
                </li> */}
                {/* <li>
                    <NavLink to='/settings/help-desk'>
                        <MessagesIcon />
                    </NavLink>
                </li> */}
                <li>
                    <NavLink to='/settings/personal-profile'>
                        <SettingsIcon />
                    </NavLink>
                </li>
                <li>
                    <a href="https://neobi-blog.ghost.io/" target="_blank">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <circle cx="10.0007" cy="9.99968" r="7.91667" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M10.9375 12.9167H10V9.375H9.0625" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9.72652 6.72787L9.72689 6.7275C9.80814 6.64646 9.93969 6.64658 10.0208 6.72778C10.1019 6.80897 10.1018 6.94052 10.0207 7.02166C9.93954 7.10281 9.80799 7.10285 9.7268 7.02176C9.6456 6.94066 9.64548 6.80911 9.72652 6.72787" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                        </svg>
                    </a>
                </li>
                <li>
                    <a href="/#" onClick={(e)=>{ e.preventDefault(); logout(); }} title="LogOut">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" height="20" width="20" version="1.1" id="Capa_1" viewBox="0 0 490.3 490.3">
                            <g>
                                <g>
                                    <path d="M0,121.05v248.2c0,34.2,27.9,62.1,62.1,62.1h200.6c34.2,0,62.1-27.9,62.1-62.1v-40.2c0-6.8-5.5-12.3-12.3-12.3    s-12.3,5.5-12.3,12.3v40.2c0,20.7-16.9,37.6-37.6,37.6H62.1c-20.7,0-37.6-16.9-37.6-37.6v-248.2c0-20.7,16.9-37.6,37.6-37.6h200.6    c20.7,0,37.6,16.9,37.6,37.6v40.2c0,6.8,5.5,12.3,12.3,12.3s12.3-5.5,12.3-12.3v-40.2c0-34.2-27.9-62.1-62.1-62.1H62.1    C27.9,58.95,0,86.75,0,121.05z" fill="currentColor" />
                                    <path d="M385.4,337.65c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6l83.9-83.9c4.8-4.8,4.8-12.5,0-17.3l-83.9-83.9    c-4.8-4.8-12.5-4.8-17.3,0s-4.8,12.5,0,17.3l63,63H218.6c-6.8,0-12.3,5.5-12.3,12.3c0,6.8,5.5,12.3,12.3,12.3h229.8l-63,63    C380.6,325.15,380.6,332.95,385.4,337.65z" fill="currentColor" />
                                </g>
                            </g>
                        </svg>
                    </a>
                </li>
            </ul>
        </nav>
    )
}
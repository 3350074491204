import { useState, useEffect } from "react";
import { ReactComponent as IconClose } from "../../assets/images/close-icon.svg"
import InputComponent from '../../components/StartPagesComponents/InputComponent';
import { NavLink } from "react-router-dom";
import API from "../../api";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "../../redux/users/users-slice";

export const PersonalProfile = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.users.currentUser);
    const [localData, setLocalData] = useState(null);
    const [additionalData, setAdditionalData] = useState({
        confirmEmailAddress: "",
    });
    const [canSave, setCanSave] = useState(false);

    const setDefaults = () => {
        setLocalData({
            ...currentUser,
            password: ""
        });
        setAdditionalData({
            confirmEmailAddress: currentUser.username
        })
    }

    const saveChanges = async() => {
        if(additionalData.confirmEmailAddress !== currentUser.username){
            NotificationManager.error(`"Confirm Email Address" not equal "Change Email Address"`);
            return false;
        }

        if(!localData.password?.length){
            NotificationManager.error('"Password" is required field');
            return false;
        } 

        let updatedBody = {
            password: localData.password
        };

        // if(localData.firstname !== currentUser.firstname){
            updatedBody.firstName = localData.firstname;
        // }

        // if(localData.lastname !== currentUser.lastname){
            updatedBody.lastName = localData.lastname;
        // }

        let result = await API.modifyUser(currentUser.username, updatedBody);

        if(result){
            NotificationManager.success("Settings updated");

            let userData = await API.getUser(currentUser.username);
            dispatch(setCurrentUser({
                userData,

            }));
        }

        setCanSave(false);
    }

    useEffect(()=>{
        setLocalData({
            ...currentUser,
            password: ""
        });
    }, [currentUser]);

    return(
        <>
        <div className="settings-sidebar">
            <div className="settings-sidebar-header">
                Personal Profile
                <NavLink to="/dashboard" className="close-btn">
                    <IconClose />
                </NavLink>
            </div>
            <div className="settings-form-group">
                <div className="settings-form-group-header bt-0">User Name</div>
                <div className="settings-form-input-wrpr">
                    <div className="input-wrapper mb-16">
                        <label className="input-label">First Name</label>
                        <div className="relative">
                            <input type="text" className="input" value={ localData?.firstname } onInput={(e) => {
                                setCanSave(true);
                                setLocalData((prevState) => {
                                    return {
                                        ...prevState,
                                        firstname: e.target.value
                                    }
                                })
                            }} />
                            <div className="input-success-check">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.96484 12.55L4.90479 9.49005L3.89502 10.5099L6.94482 13.56L7.96484 14.58L8.98486 13.56L16.105 6.44L15.085 5.41998L7.96484 12.55Z" fill="#00E0D1"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="input-wrapper mb-16">
                        <label className="input-label">Last Name</label>
                        <div className="relative">
                            <input type="text" className="input" value={ localData?.lastname } onInput={(e) => {
                                setCanSave(true);
                                setLocalData((prevState) => {
                                    return {
                                        ...prevState,
                                        lastname: e.target.value
                                    }
                                })
                            }} />
                            <div className="input-success-check">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.96484 12.55L4.90479 9.49005L3.89502 10.5099L6.94482 13.56L7.96484 14.58L8.98486 13.56L16.105 6.44L15.085 5.41998L7.96484 12.55Z" fill="#00E0D1"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="settings-form-group">
                <div className="settings-form-group-header">Email Account</div>
                <div className="settings-form-input-wrpr">
                    <div className="input-wrapper mb-16">
                        <label className="input-label">Change Email Address</label>
                        <div className="relative">
                            <input type="text" className="input" value={ localData?.username } />
                            <div className="input-success-check">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.96484 12.55L4.90479 9.49005L3.89502 10.5099L6.94482 13.56L7.96484 14.58L8.98486 13.56L16.105 6.44L15.085 5.41998L7.96484 12.55Z" fill="#00E0D1"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className="input-wrapper mb-16">
                        <label className="input-label">Confirm Email Address</label>
                        <div className="relative">
                            <input name="confirm-email-address" type="text" className="input" value={ additionalData.confirmEmailAddress }
                                autoComplete="off"
                                onInput={(e)=>{
                                    setCanSave(true);
                                    setAdditionalData((prevState)=>{
                                        return {
                                            ...prevState,
                                            confirmEmailAddress: e.target.value,
                                        }
                                    })
                                }}
                            />
                            { localData?.username === additionalData.confirmEmailAddress ?
                            <div className="input-success-check">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.96484 12.55L4.90479 9.49005L3.89502 10.5099L6.94482 13.56L7.96484 14.58L8.98486 13.56L16.105 6.44L15.085 5.41998L7.96484 12.55Z" fill="#00E0D1"/>
                                </svg>
                            </div> : null }
                        </div>
                    </div>
                </div>
            </div>
            <div className="settings-form-group">
                <div className="settings-form-group-header">Password</div>
                <div className="settings-form-input-wrpr">
                    <InputComponent
                        type="password" name="new-password" className="mb-16" 
                        value={ localData?.password }
                        onChange={(e) => {
                            setCanSave(true);
                            setLocalData((prevState) => {
                                return {
                                    ...prevState,
                                    password: e.target.value
                                }
                            })
                        }}
                    />
                    {/* <div className="input-wrapper mb-16">
                        <label className="input-label">New Password</label>
                        <div className="relative">
                            <input type="password" className="input" />
                        </div>
                    </div>
                    <div className="input-wrapper">
                        <label className="input-label">Re-enter New Password</label>
                        <div className="relative">
                            <input type="password" className="input" />
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="settings-sidebar-btns-group">
                <a
                    href="#" className="btn green-btn" 
                    onClick={(e) => {
                        e.preventDefault();
                        saveChanges();
                    }}
                    style={{ opacity: canSave ? '1' : '0.7', pointerEvents: canSave ? "initial" : "none" }}
                >Save Changes</a>
                <a href="#" className="btn grey-btn" onClick={(e) => {
                    e.preventDefault();
                    setDefaults();
                }}>Discard</a>
            </div>
        </div>
        <NotificationContainer/>
        </>
    )
}
const monthList = ['January','February','March','April','May','June','July','August','September','October','November','December'];

export const formatPrice = (price) => {
    // to USD
    let currentPrice = typeof price === "number" ? price : Number(price)

    if(!Number.isFinite(currentPrice)) currentPrice = 0;

    return (currentPrice).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });
}

export const formatDate = (dateString) => {
    console.log("DATE STRING", dateString);

    let [datePart, timePart] = dateString.split(" ");
    
    let dateParts = datePart.split("/");
    
    let date = new Date(`${dateParts[2]}-${dateParts[0]}-${dateParts[1]}T${timePart}`);

    const day = date.getDate();
    const month = date.getMonth(); 
    const year = date.getFullYear();

    const monthList = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const monthString = monthList[month];

    return `${monthString} ${day}, ${year}`;
};

import { ReactComponent as IconClose } from "../../assets/images/close-icon.svg"
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import API from "../../api";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../../redux/users/users-slice";
import InputComponent from '../../components/StartPagesComponents/InputComponent';

export const EmailPreferences = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.users.currentUser);
    const [selectedNotifications, setSelectedNotifications] = useState([]);
    const [localData, setLocalData] = useState({
        password: ""
    });
    const [canSave, setCanSave] = useState(false);

    const changeSelected = (value) => {
        let copiedNotifSelected = [...selectedNotifications];
        if(copiedNotifSelected.includes(value)){
            setSelectedNotifications(copiedNotifSelected.filter(item => item !== value));
        } else {
            copiedNotifSelected.push(value);
            setSelectedNotifications(copiedNotifSelected);
        }
    }

    const discard = () => {
        setSelectedNotifications(currentUser.notifications.split(';'))
    }

    const saveChanges = async() => {
        if(!localData.password?.length){
            NotificationManager.error('"Password" is required field');
            return false;
        }

        let result = await API.modifyUser(currentUser.username, {
            password: localData.password,
            notifications: selectedNotifications.filter(item => item !== '').length ? selectedNotifications.filter(item => item !== '').join(';') : "",
        });

        if(result){
            setCanSave(false);
            NotificationManager.success("Settings updated");

            setTimeout(async() => {
                let userData = await API.getUser(currentUser.username);
                dispatch(setCurrentUser(userData)); 
            }, 3000);
        }
    }

    useEffect(() => {
        setSelectedNotifications(currentUser.notifications.split(';'))
    },[currentUser]);

    return(
        <>
        <div className="settings-sidebar">
            <div className="settings-sidebar-header">
                Email Preferences
                <NavLink to="/dashboard" className="close-btn">
                    <IconClose />
                </NavLink>
            </div>
            <div className="settings-form-group">
                <div className="settings-form-group-header bt-0">User Name</div>
                <div className="settings-form-input-wrpr">
                    <div className="input-wrapper mb-16">
                        {/* <label className="input-label">First Name</label> */}
                        <div className="relative">
                            <input type="text" className="input" disabled value={ currentUser.username } />
                            <div className="input-success-check">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.96484 12.55L4.90479 9.49005L3.89502 10.5099L6.94482 13.56L7.96484 14.58L8.98486 13.56L16.105 6.44L15.085 5.41998L7.96484 12.55Z" fill="#00E0D1"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="settings-form-group">
                <div className="settings-form-group-header">Notifications</div>
                <div className="settings-form-input-wrpr">
                    <ul className="filter_checkboxes_list">
                        {
                            [
                                'Special Offers',
                                'New Product Release',
                                'Monthly Newsletter',
                                'Webinars and Events',
                                'Unsubscribe From All Neobi Emails'
                            ].map((item, index)=>(
                                <li key={index}>
                                    <label>
                                        <input
                                            type="checkbox" name="radio-1" id=""
                                            value={item} 
                                            checked={selectedNotifications.includes(item)}
                                            onChange={(e) => {
                                                changeSelected(item);
                                                setCanSave(true);
                                            }}
                                        />
                                        <span className="checkbox_circle middle"></span>
                                        { item }
                                    </label>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
            <div className="settings-form-group">
                <div className="settings-form-group-header">Password</div>
                <div className="settings-form-input-wrpr">
                    <InputComponent
                        type="password" className="mb-16" name="new-password"
                        value={ localData?.password }
                        onChange={(e) => {
                            setLocalData((prevState) => {
                                return {
                                    ...prevState,
                                    password: e.target.value
                                }
                            })
                        }}
                    />
               </div>
            </div>

            <div className="settings-sidebar-btns-group">
                <a
                    href="#" className="btn green-btn" 
                    onClick={(e)=>{
                        e.preventDefault();
                        saveChanges();
                    }}
                    style={{ opacity: canSave ? '1' : '0.7', pointerEvents: canSave ? "initial" : "none" }}
                >Save Changes</a>
                <a href="#" className="btn grey-btn" onClick={(e)=>{
                    e.preventDefault();
                    discard();
                }}>Discard</a>
            </div>
        </div>
        <NotificationContainer/>
        </>
    )
}